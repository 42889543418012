import { createSlice, current } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store";
import map from "assets/images/map/tiles/default.png";
import satelite from "assets/images/map/tiles/satelite.png";
import { api as axios } from "utils/axios";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import subDays from "date-fns/subDays";
import { IGeojsonFeatures, IAnalysisDetail, ISelectedParishArea } from "types/interfaces";
import { IMapPoint, MapType } from "@alb/live-lib";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { getSelectedModule } from "utils/modules/modules";
import { TUser } from "types/types";
import { AxiosError } from "axios";

export interface IFilterDateRangeMap {
  startDate: Date;
  endDate: Date;
}
export interface MapState {
  markers: IMapPoint[];
  markersInCluster: number[];
  markerDetails: any;
  layers: any[];
  activeLayer: string;
  drawOptions: any[];
  drawingMap: boolean;
  openList: boolean;
  drawnCoords: { type: string; coordinates: any; iconPostion?: any } | null; //armazena temporariamente as coordenadas do desenho feito pelo utilizador para selecionar a área de um evento, na criação de um evento.
  detailsLoading: boolean;
  detailsError: {
    isError: boolean;
    description: AxiosError<any, any> | Event | null;
  }
  filtersTabOpen: boolean;
  filterMenuMarkers: string;
  filterDateRange: IFilterDateRangeMap;
  bounds: number[][];
  shapeBounds: number[][];
  selectedMarkerType: string;
  selectedParishArea: ISelectedParishArea;
  selectedMapType: MapType | null;
  geojsonType: "parishes" | "simple_upload" | "custom_upload" | "analysis";
  geojsonFilesUpload: IGeojsonFeatures[] | null;
  highlightedListMarker: IMapPoint | null;
  sidebarIsOpen: boolean;
  selectedAnalysis: string; //save analysis id
  selectedAnalysisDetail: IAnalysisDetail | undefined;
  fitBounds: boolean;
}

// map default tile layer
const defaultLayer =
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png";

const initialState: MapState = {
  markers: [],
  markersInCluster: [],
  markerDetails: null,
  layers: [
    // {
    //   name: 'toggle',
    //   thumbnail: toggle,
    //   url: '',
    // },
    {
      name: "default",
      thumbnail: map,
      url: defaultLayer,
    },
    {
      name: "satelite",
      thumbnail: satelite,
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    },
  ],
  activeLayer: defaultLayer,
  drawOptions: [
    {
      type: "draw",
      mode: "marker",
      extra: true,
    },
    {
      type: "draw",
      mode: "polyline",
      extra: true,
    },
    {
      type: "draw",
      mode: "rectangle",
    },
    {
      type: "draw",
      mode: "circle",
    },
    {
      type: "draw",
      mode: "polygon",
      extra: true,
    },
    {
      type: "edit",
      mode: "edit",
    },
    {
      type: "edit",
      mode: "remove",
    },
  ],
  drawingMap: false,
  openList: false,
  drawnCoords: null,
  detailsLoading: true,
  detailsError: {
    isError: false,
    description: null,
  },
  filtersTabOpen: false,
  filterMenuMarkers: "",
  filterDateRange: {
    startDate: new Date(subDays(new Date(), 5).setHours(0, 0, 0, 0)),
    endDate: new Date(subDays(new Date(), 1).setHours(0, 0, 0, 0)),
  },
  bounds: [],
  shapeBounds: [],
  selectedMarkerType: "all",
  selectedParishArea: {
    name: "",
    id: "",
  },
  selectedMapType: null,
  geojsonType: "parishes",
  geojsonFilesUpload: null,
  highlightedListMarker: null,
  sidebarIsOpen: false,
  selectedAnalysis: "",
  selectedAnalysisDetail: undefined,
  fitBounds: false,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setLayer: (state, action: PayloadAction<string>) => {
      // add adapter to globalState
      state.activeLayer = action.payload;
    },
    setMarkers: (state, action) => {
      state.markers = action.payload;
    },
    setDrawingMap: (state, action: PayloadAction<boolean>) => {
      state.drawingMap = action.payload;
    },
    setDrawnCoords: (state, action: PayloadAction<any>) => {
      //store drawn coordinates
      state.drawnCoords = action.payload;
    },
    toggleList: (state, action: PayloadAction<boolean>) => {
      state.openList = action.payload;
    },
    setMarkersInCluster: (state, action) => {
      state.markersInCluster = action.payload;
    },
    setMarkerDetails: (state, { payload }) => {
      state.detailsLoading = payload.loading;
      state.detailsError = payload.error;
      state.markerDetails = payload.data;
    },
    clearDetails: (state, { payload }) => {
      state.detailsLoading = true;
      state.markerDetails = payload;
    },
    setFiltersTabOpen: (state, action: PayloadAction<boolean>) => {
      state.filtersTabOpen = action.payload;
    },
    setFilterMenuMarkers: (state, action) => {
      state.filterMenuMarkers = action.payload;
    },
    setFilterDateRange: (state, action: PayloadAction<IFilterDateRangeMap>) => {
      state.filterDateRange = action.payload;
    },
    setBounds(state, action) {
      var sameValue =
        current(state.bounds).length === action.payload.length &&
        current(state.bounds).every(
          (o: any, i) =>
            Object.keys(o).length === Object.keys(action.payload[i]).length &&
            Object.keys(o).every((k) => o[k] === action.payload[i][k])
        );
      //se os bounds novos forem diferentes dos antigos, atualiza o estado dos bounds
      if (!sameValue) {
        state.bounds = action.payload;
      }
    },
    setShapeBounds(state, action) {
      state.shapeBounds = action.payload;
    },
    setSelectedMarkerType(state, action) {
      state.selectedMarkerType = action.payload;
    },
    setSelectedParishArea(state, action) {
      state.selectedParishArea = action.payload;
    },
    setSelectedMapType(state, action) {
      state.selectedMapType = action.payload;
    },
    setGeojsonType(state, action) {
      state.geojsonType = action.payload;
    },
    setGeojsonFilesUpload(state, action) {
      state.geojsonFilesUpload = action.payload;
    },
    setHighlightedListMarker(state, action) {
      state.highlightedListMarker = action.payload;
    },
    setSidebarIsOpen(state, action) {
      state.sidebarIsOpen = action.payload;
    },
    setSelectedAnalysis: (state, action: PayloadAction<string>) => {
      state.selectedAnalysis = action.payload;
    },
    setSelectedAnalysisDetail: (state, action: PayloadAction<IAnalysisDetail | undefined>) => {
      state.selectedAnalysisDetail = action.payload;
    },
    setFitBounds(state, action) {
      state.fitBounds = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLayer,
  setMarkers,
  setMarkersInCluster,
  setDrawingMap,
  setDrawnCoords,
  toggleList,
  setMarkerDetails,
  clearDetails,
  setFiltersTabOpen,
  setFilterMenuMarkers,
  setFilterDateRange,
  setBounds,
  setShapeBounds,
  setSelectedMarkerType,
  setSelectedParishArea,
  setSelectedMapType,
  setGeojsonType,
  setGeojsonFilesUpload,
  setHighlightedListMarker,
  setSidebarIsOpen,
  setSelectedAnalysis,
  setSelectedAnalysisDetail,
  setFitBounds,
} = mapSlice.actions;

// api async requests
export enum TypeMarkerInfo {
  device = "devices",
  event = "events",
  occurrence = "occurrences",
}
export const getMarkerDetails =
  (
    id: string,
    type: TypeMarkerInfo,
    loggedUser: TUser | undefined,
    publicAPI: any = { public: false, client: null }
  ): any =>
  async (dispatch: any) => {
    dispatch(clearDetails(null));
    try {
      const valueofType =
      Object.values(TypeMarkerInfo)[
        Object.keys(TypeMarkerInfo).indexOf(type)
      ];
      let res;
      if (!publicAPI.public) {
        const moduleKey = MODULES_KEYS[valueofType.toLocaleUpperCase()]
        const markersIDModule = getSelectedModule(loggedUser, moduleKey);
        const config = {
          headers: {
            ...markersIDModule && {"MODULE-ID": markersIDModule},
          },
        };
        // private API
        const url = `${ServiceApiUrl[valueofType]}/${id}`;
        res = await axios.get(url, config);
      } else {
        // public API
        const config = {
          headers: {
            "Client-ID": publicAPI.client.id,
          },
        };
        const url = `/${publicAPI.client.city}/${valueofType}/${id}`;
        res = await axios.get(url, config);
      }
      if (res && res.status === 200) {
        dispatch(
          setMarkerDetails({
            data: { typeMarker: type, ...res.data },
            loading: false,
            error: initialState.detailsError,
          })
        );
      } else {
        dispatch(
          setMarkerDetails({
            data: null,
            loading: false,
            error: {
              isError: true,
              description: res.data
            },
          })
        );
      }
    } catch (err: any) {
      dispatch(
        setMarkerDetails({
          data: null,
          loading: false,
          error: {
            isError: true,
            description: err
          },
        })
      );
      throw new Error(err);
    }
  };

// Selectors/Getters
export const selectOpenList = (state: RootState) => state.map.openList;
export const selectMapLayers = (state: RootState) => state.map.layers;
export const selectLayer = (state: RootState) => state.map.activeLayer;
export const selectDrawOptions = (state: RootState) => state.map.drawOptions;
export const selectMarkers = (state: RootState) => state.map.markers;
export const selectMarkersInCluster = (state: RootState) =>
  state.map.markersInCluster;
export const selectDrawingMap = (state: RootState) => state.map.drawingMap;
export const selectCoords = (state: RootState) => state.map.drawnCoords;
export const selectMarkerDetails = (state: RootState) =>
  state.map.markerDetails;
export const selectDetailsLoading = (state: RootState) =>
  state.map.detailsLoading;
export const selectDetailsError = (state: RootState) => state.map.detailsError;
export const selectFiltersTabOpen = (state: RootState) =>
  state.map.filtersTabOpen;
export const getFilterMenuMarkers = (state: RootState) =>
  state.map.filterMenuMarkers;
export const getFilterDateRange = (state: RootState) =>
  state.map.filterDateRange;
export const getMapBounds = (state: RootState) => state.map.bounds;
export const getShapeBounds = (state: RootState) => state.map.shapeBounds;
export const getSelectedMarkerType = (state: RootState) =>
  state.map.selectedMarkerType;
export const getSelectedParishArea = (state: RootState) =>
  state.map.selectedParishArea;
export const getSelectedMapType = (state: RootState) =>
  state.map.selectedMapType;
export const getGeojsonType = (state: RootState) => state.map.geojsonType;
export const getGeojsonFilesUpload = (state: RootState) =>
  state.map.geojsonFilesUpload;
export const getHighlightedListMarker = (state: RootState) =>
  state.map.highlightedListMarker;
export const getSidebarIsOpen = (state: RootState) => state.map.sidebarIsOpen;
export const getSelectedAnalysis = (state: RootState) =>
  state.map.selectedAnalysis;
export const getSelectedAnalysisDetail = (state: RootState) =>
  state.map.selectedAnalysisDetail;
export const getFitBounds = (state: RootState) => state.map.fitBounds;
export default mapSlice.reducer;
