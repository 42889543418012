/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { Feedback, LayoutContext, getTheme, ThemeNames } from "@alb/live-lib";
import { sidebar } from "layouts/layout.sidebar";

import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { getRoles, getUser } from "store/slices/authSlice";

import { ServiceApiUrl } from "services/ServiceApiUrl";

import useCreate from "hooks/fetchData/useCreate";
import usePermission from "hooks/usePermission";

import { urlsPublicPortal } from "utils/clientsPublicPortal";
import { useGetLastNotifications } from "hooks/useGetLastNotifications";
import { getCookie } from "utils/cookie";
import { THEME } from "utils/keys";

const App = () => {
  const { addSidebar } = useContext(LayoutContext);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = router.state.location;
  const pathPublicPortal = urlsPublicPortal();
  const userStorage = useSelector(getUser);
  const { hasManyPermissions } = usePermission();
  const permissionsStorage = useSelector(getRoles);

  const { lastNotifications: notifications, count } = useGetLastNotifications();
  const theme = getCookie(THEME);
  const darkMode = theme === "dark" ?? false;

  var browserLanguage = navigator.language.slice(0, 2); //get browser language

  //theme
  const lng = i18n.language || browserLanguage;
  const finalTheme = getTheme(ThemeNames.Campus, lng, darkMode);

  const { refetch } = useCreate(ServiceApiUrl.logout);

  const onClickLogout = async () => {
    try {
      await refetch().then((response) => {
        if (response.status === 204) {
          dispatch({ type: "logout" });
          window.location.replace("/login");
        }
      });
    } catch (error) {}
  };

  function fromLocation(pathName: string) {
    return {
      from: {
        hash: "",
        key: "default",
        pathname: pathName,
        search: "",
        state: null,
      },
    };
  }

  useEffect(() => {
    if (!userStorage) return;

    addSidebar(
      sidebar(
        t,
        userStorage,
        hasManyPermissions,
        onClickLogout,
        location.state,
        false,
        dispatch,
        { notifications, count }
      )
    );
  }, [
    i18n.language,
    userStorage?.first_name,
    userStorage?.last_name,
    permissionsStorage,
    notifications,
  ]);

  // ----- public portal ----
  useEffect(() => {
    if (pathPublicPortal.includes(location.pathname)) {
      const fromPage = fromLocation(location.pathname);
      addSidebar(
        sidebar(
          t,
          userStorage,
          hasManyPermissions,
          onClickLogout,
          fromPage,
          true,
          dispatch
        )
      );
    }
    if (
      location.state &&
      pathPublicPortal.includes(location.state.from.pathname) &&
      userStorage
    ) {
      addSidebar(
        sidebar(
          t,
          userStorage,
          hasManyPermissions,
          onClickLogout,
          location.state,
          false,
          dispatch
        )
      );
    }
  }, [location.pathname]);

  /*o atributo "data-theme" teve de ser aplicado ao body, caso contrário, não tem efeito sobre
	o conteúdo dos dialogs, uma vez que os dialogs são gerados numa camada diferente da root e
	este componente diz respeito apenas à root */
  useEffect(() => {
    document.body.setAttribute("data-theme", darkMode ? "dark" : "");
  }, [darkMode]);

  return (
    <ThemeProvider theme={finalTheme}>
      <CssBaseline />
      <Feedback>
        <RouterProvider router={router} />
      </Feedback>
    </ThemeProvider>
  );
};

export default App;
