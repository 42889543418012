import { arrayIsEmpty, Loading } from "@alb/live-lib";
import { useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatNumber } from "utils/number";

const OccurrencesStatusChart = (props: {
	selectedRange: {
		startDate: Date;
		endDate: Date;
	},
	needTorefetch: boolean | undefined
}) => {
	const { selectedRange, needTorefetch } = props;
	const { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
	const { t } = useTranslation();


	const { data, loading, error, refetch: refetchOccurrences } = useGet(
		ServiceApiUrl.occurrencesTotalStatus,
		getParams(),
		undefined,
		headersOCurrences
	);
	
	function getParams() {
		var params = undefined;
		if (selectedRange?.startDate || selectedRange?.endDate) {
			params = {
				start_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
			};
		}
		return params;
	}

	useEffect(() => {
		if (needTorefetch)
			refetchOccurrences()
		// eslint-disable-next-line
	}, [needTorefetch])

	let occurrencesByStatus = data?.filter((item: { name: string; value: number }) =>
			item.value > 0 && item.name !== "occurrences"
	);

	if (occurrencesByStatus) {

		occurrencesByStatus = occurrencesByStatus.map((o: any) => {

			return {
				name: t(`occurrences.state.${o.name}`),
				value: o.value
			}
		})
	}





	/*let option = {
		tooltip: {
						confine: true,
			extraCssText: 'white-space:inherit;',
			trigger: "item",
			formatter: function (value: any) {
				return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br/>`;
			},
		},
		legend: {
			show: true,
			orient: "horizontal",
			bottom: 0,
			textStyle: {
				color: "#333333",
				fontFamily: "Altice",
				fontSize: 13,
			},
		},
		series: [
			{
				bottom: "20%",
				color: ["#3e848a", "#346671", "#2a4858", "#51bfbc", "#47a1a3"],
				name: t("occurrences.status"),
				type: "pie",
				radius: ["30%", "80%"],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: "center",
				},
				emphasis: {
					label: {
						show: false,
					},
				},
				labelLine: {
					show: false,
				},
				data: occurrencesByStatus,
			},
		],
	};*/

	/*let option = {
tooltip: {
	confine: true,
	extraCssText: 'white-space:inherit;',
	trigger: "item",
	formatter: function (value: any) {
		return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br/>`;
	},
},
textStyle: {
	fontFamily: "Altice",
	fontSize: 13,
},
xAxis: {
	type: "category",
	data: occurrencesByStatus?.map(
		(d: { name: string; value: number }) => d.name
	),
	axisTick: {
		show: false,
	},
	axisLine: {
		show: false,
	},
	axisLabel: {
		interval: 0,
		//  rotate: 30
	},
},
grid: {
	containLabel: true,
},
color: "#51bfbc",
yAxis: {
	name: t("occurrences.occurrencesNumber"),
	nameLocation: "center",
	nameTextStyle: {
		padding: [0, 0, 50, 0],
		fontSize: 12,
	},
	type: "value",
	axisLabel: {
		formatter: (val: any) => formatNumber(val)
	}
},
series: [
	{
		name: "occurrencesWeekday",
		data: occurrencesByStatus,
		type: "bar",
		label: {
			show: true,
			position: "top",
		},
	},
],
};*/

	const theme = useTheme()

	let option = {
		tooltip: {
			confine: true,
			extraCssText: 'white-space:inherit;',
			trigger: "item",
			formatter: function (value: any) {
				return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br/>`;
			},
		},
		labelLine: {
			show: false,
		},
		textStyle: {
			fontFamily: "Altice",
			fontSize: 13,
		},

		xAxis: {
			name: t("occurrences.occurrencesNumber"),
			nameLocation: 'center',
			nameTextStyle: {
				padding: [22, 0],
				fontSize: 12,
			},
			// show:false,
			max: "dataMax",
			// max: data ? Math.max(...data.map((v: any) => v.value)) : undefined,
			axisLabel: {
				formatter: (val: any) => formatNumber(val)
			},
			axisLine: {
				lineStyle: {
					color: theme.palette.mode === "light" ? "" : theme.palette.common.white
				}
			},
		},
		grid: {
			containLabel: true
		},
		color: "#51bfbc",
		yAxis: {
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
				lineStyle: {
					color: theme.palette.mode === "light" ? "" : theme.palette.common.white
				}
			},
			type: "category",
			data: occurrencesByStatus?.map((d: { name: string; value: number }) => d.name),
			inverse: true,
			max: occurrencesByStatus ? occurrencesByStatus.lenght : 0,
			axisLabel: {
				formatter: (val: any) => formatNumber(val),

			},

		},
		series: [
			{
				barCategoryGap: "30%",
				realtimeSort: true,
				name: "occurrencesParishes",
				type: "bar",
				data: occurrencesByStatus,
				label: {
					color: theme.palette.mode === "light" ? "#333" : theme.palette.common.white,
					show: true,
					position: "right",
					formatter: (params: { value: string | number }) => {
						return formatNumber(params.value);
					},
				},
			},
		],
	};
	return (<>
		<Loading show={loading} />
		{!arrayIsEmpty(occurrencesByStatus) && !loading && (
			<ReactEcharts style={{ height: "364px" }} option={option} />
		)}
		{arrayIsEmpty(occurrencesByStatus)  && !loading && <NoData error={error} />}
	</>)
}

export default OccurrencesStatusChart;
