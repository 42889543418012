import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import EvStationRoundedIcon from "@mui/icons-material/EvStationRounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import { OverridableComponent } from "@mui/types";
import { SvgIconTypeMap } from "@mui/material";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import EditCalendarRoundedIcon from "@mui/icons-material/EditCalendarRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import ContentPasteSearchRoundedIcon from "@mui/icons-material/ContentPasteSearchRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PublishRoundedIcon from "@mui/icons-material/PublishRounded";
import LocalGasStationRoundedIcon from "@mui/icons-material/LocalGasStationRounded";
import PropaneRoundedIcon from "@mui/icons-material/PropaneRounded";
import WaterDropRoundedIcon from "@mui/icons-material/WaterDropRounded";
import RouteIcon from "@mui/icons-material/Route";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { ReactComponent as HydrogenIcon } from "../../../assets/images/icons/others/hydrogen.svg";
import { ReactComponent as WeightIcon } from "../../../assets/images/icons/others/weight.svg";
import { ReactComponent as PowerIcon } from "../../../assets/images/icons/others/power.svg";
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';
import CompressRoundedIcon from '@mui/icons-material/CompressRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { ReactComponent as parkmeter } from "assets/images/map/markers/enabled/smartparking/meter.svg"
import { ReactComponent as loads } from "assets/images/map/markers/enabled/smartparking/load.svg"
import { ReactComponent as eletric } from "assets/images/map/markers/enabled/smartparking/eletric.svg"

export const moduleCardsIcons: {
  [icon: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
} = {
  //events
  events: CalendarMonthRoundedIcon,
  underreview: TimerRoundedIcon,
  Ativo: EventRoundedIcon,
  Concluído: EventAvailableRoundedIcon,
  "Em análise": EventNoteRoundedIcon,
  "Em resolução": EditCalendarRoundedIcon,
  //ocurrences
  occurrences: CampaignRoundedIcon,
  concluída: CheckRoundedIcon,
  submetida: PublishRoundedIcon,
  "em resolução": ConstructionRoundedIcon,
  "em análise": ContentPasteSearchRoundedIcon,
  unida: CampaignRoundedIcon,
  resolution_time_days: TimerRoundedIcon,
  // "não autorizada": TimerRoundedIcon,
  aberto: ContentPasteSearchRoundedIcon,
  fechado: CheckCircleIcon,
  //electricMobility
  avg_charging_duration: TimerRoundedIcon,
  avg_consumption: BoltRoundedIcon,
  total_consumption: BoltRoundedIcon,
  total_average_transactions: EvStationRoundedIcon,
  total_cost: EuroRoundedIcon,
  total_duration: TimerRoundedIcon,
  total_transactions: EvStationRoundedIcon,
  production: PropaneRoundedIcon,
  accumulation: PropaneRoundedIcon,
  //hydrogen
  hydrogenSupply: WaterDropRoundedIcon,
  total_supplies: LocalGasStationRoundedIcon,
  total_productions: HydrogenIcon as any,
  avg_quantity_supplied: WeightIcon as any,
  avg_supply_time: TimerRoundedIcon,
  avg_energy_consumed: BoltRoundedIcon,
  avg_hydrogen_produced: HydrogenIcon as any,
  tank_capacity_kg: PropaneRoundedIcon,
  tank_capacity_l: PropaneRoundedIcon,
  temperature: DeviceThermostatRoundedIcon,
  pressure: CompressRoundedIcon,
  avg_power: PowerIcon as any,

  "Total quilómetros": RouteIcon,
  "Total de combustível gasto": LocalGasStationIcon,
  "Total recolhido": DeleteIcon,
  "Total de descargas": LocalShippingIcon,
  //cer management
  last_value_ac_total_current: BoltRoundedIcon,
  avg_power_sll_phases: PowerIcon as any,
  avg_all_grid_voltage_phases: BoltRoundedIcon,
  avg_all_grid_current_phases: BoltRoundedIcon,
  avg_ac_power_value: PowerIcon as any,
  max_ac_power_value: PowerIcon as any,
  avg_all_voltage_phases: BoltRoundedIcon,

  // mobility
  "Parcómetros": parkmeter as any,
  "Elétricos": eletric as any,
  "Cargas e descargas": loads as any,
  "Parking meters": parkmeter as any,
  "Loads and unloads": loads as any,
  "Electric": eletric as any,
};
