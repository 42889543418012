import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IDialogProps } from 'types/interfaces'
import { TOccurrence } from 'types/types'

import OccurrenceEdit from 'components/occurrences/OccurrenceEdit'
import usePermission from 'hooks/usePermission'
import { getUser } from 'store/slices/authSlice'
import { getMarkerDetails, selectDetailsError, selectDetailsLoading, selectMarkerDetails, TypeMarkerInfo } from 'store/slices/mapSlice'
import { formatDate } from 'utils/date'
import { PERMISSIONS } from 'utils/permissions/permissions'
import { formattedLocation } from 'utils/utils'

import LiveDialogTitle from './DialogTitle'
import MapEntiyInfoItem from './MapEntiyInfoItem'

const OccurrenceInfo = ({ open, onClose, onSuccess }: IDialogProps) => {
  const { t } = useTranslation()

  const data = useSelector(selectMarkerDetails)
  const loading = useSelector(selectDetailsLoading)
  const error = useSelector(selectDetailsError)
  const user = useSelector(getUser);

  const { hasPermission } = usePermission();
  const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);

  const dispatch = useDispatch()

  const [ openEdit, setOpenEdit ] = useState(false);

  const onShowEdit = () => {
    setOpenEdit(true)
  }

  const handleClose = () => {
    setOpenEdit(false);
  }
  

  const handleSucess = (occurrence: TOccurrence) => {
    handleClose()
    onSuccess && onSuccess()
    //update
    dispatch(getMarkerDetails(occurrence.id, 'occurrence' as TypeMarkerInfo, user))
  }

  return (<>
    <Dialog maxWidth="xs" fullWidth open={open}>
      {data && !loading && !error.isError ? (
        <>
          <LiveDialogTitle onClose={onClose}>
            <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography fontWeight={700} fontSize={18}>
                {data?.name || data?.category?.name || t("occurrences.detail")}
              </Typography>
              <Typography marginLeft={1} variant="body1">
                {data.status ? data.status.name :  ""}
              </Typography>
            </Grid>
          </LiveDialogTitle>

          <DialogContent sx={{ pt: 0 }}>
            <Grid container>
              <MapEntiyInfoItem
                label={t('occurrences.type')}
                value={data.type}
              />
              <MapEntiyInfoItem
                label={t('occurrences.location')}
                value={formattedLocation(data.geolocation.iconPosition)}
              />
              <MapEntiyInfoItem
                label={t('occurrences.address')}
                value={data.address}
              />
              <MapEntiyInfoItem
                label={t('occurrences.category')}
                value={data.category?.name}
              />
              <MapEntiyInfoItem
                label={t('occurrences.startDate')}
                value={
                  data.start_date
                    ? formatDate(
                        data.start_date,
                        t('calendar.dateTimeFullFormat')
                      )
                    : 'n/a'
                }
              />
              <MapEntiyInfoItem
                label={t('occurrences.endDate')}
                value={
                  data.end_date
                    ? formatDate(
                        data.end_date,
                        t('calendar.dateTimeFullFormat')
                      )
                    : 'n/a'
                }
              />
              <MapEntiyInfoItem
                label={t('occurrences.occurrenceReporter')}
                value={data.reporter}
              />
              <MapEntiyInfoItem
                label={t('occurrences.description')}
                value={data.description}
              />
              {/* <MapEntiyInfoItem label={t("occurrences.subject")} value={data.subject} /> */}

              <MapEntiyInfoItem
                label={t('common.createdAt')}
                value={
                  data.created_at
                    ? formatDate(
                        data.created_at,
                        t('calendar.dateTimeFullFormat')
                      )
                    : 'n/a'
                }
              />
              <MapEntiyInfoItem
                label={t('common.updatedAt')}
                value={
                  data.updated_at
                    ? formatDate(
                        data.updated_at,
                        t('calendar.dateTimeFullFormat')
                      )
                    : 'n/a'
                }
              />
            </Grid>
            <DialogActions>
                {
                canEdit && 
                <Button variant="outlined" color="primary" onClick={onShowEdit}>
                  {t("common.edit")}
                </Button>
                }
            </DialogActions>
          </DialogContent>
        </>
      ) : (
        <LiveDialogTitle onClose={onClose}>
          <Typography fontWeight={700} fontSize={18}>
            {loading && t('labelLoading')}
            {error.isError && !loading && t('errors.noAction')}
          </Typography>
        </LiveDialogTitle>
      )}
    </Dialog>


      {openEdit && canEdit && <OccurrenceEdit data={data} open={openEdit} onClose={handleClose} onSuccess={handleSucess}/>}
    </>
  )
}

export default OccurrenceInfo
