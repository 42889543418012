/* eslint-disable react-hooks/exhaustive-deps */
import { AppLogo, arrayIsEmpty, ChipStatus, DetailsWidget, FilterSection, LayoutContext } from "@alb/live-lib";
import { Loading } from "@alb/live-lib";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ISelectOption } from "types/interfaces";
import { TAdapterConfigurator, TMarker, TStream } from "types/types";

import MapDevices from "components/dashboard/map-devices/MapDevices";
import StreamsChartAnalysis from "components/dashboard/streams-chart-analysis/StreamsChartAnalysis";
import { getIcons } from "components/map/list/listIcons";
import FilterSelect from "components/Utils/FilterSelect";
import NoData from "components/Utils/NoData";
import ReloadPage from "components/Utils/ReloadPage";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDeviceRealTime, setDeviceRealTime } from "store/slices/adapterConfiguratorsSlice";
import { getNameClient } from "store/slices/authSlice";
import { getDevicesMapDashboard, getSelectedAdapterFilter, getSelectedDevice, getSelectedDeviceId, getSelectedDeviceInfo, setDevice, setDevicesMapDashboard, setOneDeviceMapDashboard, setSelectedAdapterFilter, setSelectedDeviceId, setSelectedDeviceInfo } from "store/slices/dashboardSlice";
import { DoubleSelect } from "styles/css/components";
import { DeviceRealTime, updateDeviceInfo, updateDeviceLastRead } from "utils/data-real-time";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import { formatRoutePath } from "utils/routePath";
import { formatStream } from "utils/utils";

const IconStatus = (props: { isActive: boolean }) => {
  const { t } = useTranslation();
  const { isActive } = props;

  let title = `${t("common.statusLabel")}: `;
  title += isActive ? t("common.status.active") : t("common.status.inactive");

  return (
    <Tooltip placement="top" title={title}>
      <div>
        <ChipStatus status={isActive ? "enabled" : "disabled"} />
      </div>
    </Tooltip>
  );
};

const Dashboard = () => {
  const theme = useTheme();
  // permissions of user
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.DASHBOARD.READ);
  // --------------- Header -------------------
  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: "Dashboard",
  };
  const nameClient = useSelector(getNameClient);
  const { t } = useTranslation();
  let paramsURL = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const deviceRealTime = useSelector(selectDeviceRealTime);
  const devicesDashboardMap = useSelector(getDevicesMapDashboard);
  // guarda o device escolhido na lista
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedDevice = useSelector(getSelectedDevice);
  // valores das streams para o DetailWidget
  const [streamsDetailWidget, setStreamsDetailWidget] = useState<object[]>([]);
  // para o select --> armazena o que está selecionado
  const selectedAdapter = useSelector(getSelectedAdapterFilter);
  const selectedDeviceInfo = useSelector(getSelectedDeviceInfo);
  const [typeSmartParking, setTypeSmartParking] = useState<boolean>(false);
  // valores das streams para o gráfico
  const [streamsChart, setStreamsCharts] = useState<object[]>([]);
  const [loadingFirstDevice, setLoadingFirstDevice] = useState<boolean>(true);

  useEffect(() => {
    addHeader(header);

    // --- SSE
    // --- Smart Parking Funchal Devices
    if (nameClient === "CMFunchal") {
      const deviceEventSource = new EventSource(
        `${ServiceApiUrl.baseApiUrl_SSE}/${ServiceApiUrl.devicesSmartParking}`
      );
      deviceEventSource.onmessage = (e) => {
        const newValue = JSON.parse(e.data);
        if (newValue && newValue[0].external_id) {
          dispatch(setDeviceRealTime(newValue[0]));
        }
      };
      return () => {
        deviceEventSource.close();
        clearFieldsLeavePage();
      };
    }

    return () => {
      clearFieldsLeavePage()
    };
  }, []);

  function clearFieldsLeavePage() {
    clearSomeFieldsRedux();
    dispatch(setSelectedDeviceId(""));
  }

  useEffect(() => {
    if (selectedDeviceInfo) {
      showValuesRealTime(selectedDeviceInfo, deviceRealTime);
    }
  }, [deviceRealTime]);

  function showValuesRealTime(device: TMarker, data: DeviceRealTime) {
    // devices for map
    const visibleDevice = devicesDashboardMap.find((device: TMarker) => {
      return device.external_id === data.external_id;
    });
    if (visibleDevice) {
      const infoLastRead = updateDeviceLastRead(visibleDevice, data);
      dispatch(setOneDeviceMapDashboard(infoLastRead));
    }
    // detail widget
    if (device.external_id === data.external_id) {
      const infoDevice = updateDeviceInfo(device, data);
      dispatch(setSelectedDeviceInfo(infoDevice));
    }
  }

  // --------------- Main Page -------------------
  //get adapters
  const {
    data: adapterConfigurators,
    refetch: refetchAdapters,
    loading: loadingAdapters,
    error: errorAdapters,
  } = useGet(ServiceApiUrl.adapterConfigurators, null, {
    manual: true,
  });

  // get last values of selected device
  const { refetch, loading: loadingDeviceDetail } = useGet(
    `${ServiceApiUrl.devices}/${selectedDeviceId}`,
    null,
    {
      manual: true,
    }
  );

  // get first device of selected adapter
  const {
    data: firstDevice,
    loading: loadingRequestFirstDevice,
    error: errorFirstDevice,
    refetch: refetchFirstDevice,
  } = useGet(ServiceApiUrl.devices, undefined, {
    manual: true,
  });

  function getParamsRequest(selectedAdapter: ISelectOption) {
    const params: {
      page: number;
      items: number;
      adapters_id: string;
      device_id?: string;
    } =
      selectedDeviceId && paramsURL.deviceId
        ? {
            adapters_id: selectedAdapter.value,
            device_id: selectedDeviceId,
            page: 1,
            items: 1,
          }
        : {
            adapters_id: selectedAdapter.value,
            page: 1,
            items: 1,
          };
    return params;
  }

  useEffect(() => {
    if (paramsURL.deviceId) {
      if (
        paramsURL.deviceId === selectedDeviceId &&
        paramsURL.deviceId === selectedDevice?.id
      ) {
        // buscar a informação para o detalhe do device (widget e gráfico)
        getDeviceDetails();
      } else if (
        paramsURL.deviceId !== selectedDeviceId &&
        adapterConfigurators
      ) {
        // atualizar o device id para ir buscar a informação do device
        dispatch(setSelectedDeviceId(paramsURL.deviceId));
      } else if (!adapterConfigurators) {
        // nao existem adapters logo deve-se ir buscar todas as informações
        refetchAdapters();
      }
    } else {
      refetchAdapters();
    }
  }, [paramsURL.deviceId]);

  useEffect(() => {
    if (adapterConfigurators) {
      if (paramsURL.deviceId) {
        if (selectedDeviceInfo?.id === paramsURL.deviceId) {
          // selecionar o adapter certo
          const adapter = selectedDeviceInfo.adapter_configurators[0];
          if (
            adapterConfigurators?.data.some(
              (elem: TAdapterConfigurator) => elem.id === adapter.id
            )
          ) {
            handleAdapterChange({
              label: adapter.name,
              value: adapter.id,
            });
          }
        } else if (
          selectedDeviceId === paramsURL.deviceId &&
          selectedAdapter.value.length === 0
        ) {
          // buscar e selecionar adapter do device do url
          getAdapterDeviceURL();
        } else {
          // atualizar o device id
          dispatch(setSelectedDeviceId(paramsURL.deviceId));
        }
      } else {
        // colocar adapter de default (o primeiro da lista)
        setDefaultAdapter();
      }
    }
  }, [adapterConfigurators]);

  useEffect(() => {
    // quando se entra e nao ha nenhum device selecionado  ||   quando se altera de adapter
    if (
      (firstDevice?.data.length > 0 && !paramsURL.deviceId) ||
      (firstDevice?.data.length > 0 &&
        paramsURL.deviceId &&
        selectedDeviceInfo?.adapter_configurators[0]?.id !==
          selectedAdapter.value)
    ) {
      if (selectedDeviceId === firstDevice.data[0].id && !paramsURL.deviceId) {
        // este if é quando se clica no sidebar e estamos no primeiro device do primeiro adapter
        onChangeURL(selectedDeviceId);
        dispatch(setDevice(firstDevice.data[0]));
        return;
      } else if (
        selectedDeviceId === firstDevice.data[0].id &&
        paramsURL.deviceId === selectedDeviceId &&
        (selectedDevice?.id !== selectedDeviceId || !selectedDevice)
      ) {
        // quando se entra no dashboard atraves do url/mapa/...
        dispatch(setDevice(firstDevice.data[0]));
        getDeviceDetails();
        return;
      }
      dispatch(setSelectedDeviceId(firstDevice.data[0].id));
      dispatch(setDevice(firstDevice.data[0]));
    }
    if (
      firstDevice?.data.length > 0 &&
      paramsURL.deviceId &&
      selectedDeviceInfo?.adapter_configurators[0]?.id === selectedAdapter.value
    ) {
      // quando se entra no dashboard e ja ha dados do detalhe do device (mas o device selecionado do mapa ainda nao "existe")
      dispatch(setDevice(firstDevice.data[0]));
    }
    // quando o pedido é feito e nao ha valores
    if(loadingFirstDevice && !loadingRequestFirstDevice && !loadingDeviceDetail && arrayIsEmpty(firstDevice)) {
      setLoadingFirstDevice(false);
      dispatch(setSelectedDeviceInfo(null));
    }
  }, [firstDevice]);

  useEffect(() => {
    if (
      selectedDeviceId.length > 0 &&
      paramsURL.deviceId !== selectedDeviceId
    ) {
      // alterar url para o device selecionado
      onChangeURL(selectedDeviceId);
    }
    if (
      selectedDeviceId.length > 0 &&
      paramsURL.deviceId === selectedDeviceId &&
      selectedDeviceId !== selectedDevice?.id &&
      adapterConfigurators
    ) {
      // buscar o adapter do device do url
      getAdapterDeviceURL();
    }
    if (
      selectedAdapter.value.length > 0 &&
      paramsURL.deviceId === selectedDeviceId &&
      !selectedDevice
    ) {
      // quando se entra na pagina com url e sem dados
      getFirstDevice(selectedAdapter);
    }
  }, [selectedDeviceId]);

  const setDefaultAdapter = () => {
    // mostrar o primeiro adapter
    if (adapterConfigurators.data && !arrayIsEmpty(adapterConfigurators.data))
      handleAdapterChange({
        label: adapterConfigurators?.data[0].name,
        value: adapterConfigurators?.data[0].id,
      });
  };

  useEffect(() => {
    // get streams for selected device
    if (selectedDeviceInfo) {
      setTypeSmartParking(false);
      const adapterInformation = selectedDeviceInfo?.adapter_configurators[0];
      if (
        adapterInformation.domain === "mobility" &&
        adapterInformation.partner__name === "SmartParkingFunchal"
      ) {
        setTypeSmartParking(true);
        const streams = Object.keys(selectedDeviceInfo.last_read_value).map(
          (stream: any) => {
            return {
              name: t("streams." + stream.toLowerCase()),
              value: selectedDeviceInfo.last_read_value[stream]
                ? t(
                    "typesParking." +
                      stream +
                      "." +
                      (
                        selectedDeviceInfo.last_read_value[stream].value + ""
                      ).toLowerCase()
                  )
                : "--",
              unit: "",
            };
          }
        );
        setStreamsDetailWidget(streams);
      } else {
        setTypeSmartParking(false);
        const streams = Object.keys(selectedDeviceInfo.last_read_value).map(
          (stream: any) => {
            return {
              name: t("streams." + stream.toLowerCase()),
              value:
                formatStream(selectedDeviceInfo.last_read_value[stream]) ??
                "--",
              unit: "",
            };
          }
        );
        setStreamsDetailWidget(streams);
      }
    }
  }, [selectedDeviceInfo]);

  const handleAdapterChange = (adapterOption: ISelectOption) => {
    dispatch(setDevice(null));
    dispatch(setDevicesMapDashboard([]));
    // buscar primeiro device do adaptador
    getFirstDevice(adapterOption);
    // atualizar selectedAdapter
    dispatch(setSelectedAdapterFilter(adapterOption));
    // buscar e atualizar streams para gráfico
    handleStreamsChart(adapterOption.value);
  };

  const handleStreamsChart = (adapterID: string) => {
    setStreamsCharts([]);
    const infoAdapter = adapterConfigurators.data.find(
      (adapter: TAdapterConfigurator) => adapter.id === adapterID
    );
    if (
      infoAdapter.domain === "mobility" &&
      infoAdapter.partner.name === "SmartParkingFunchal"
    ) {
      setStreamsCharts(
        infoAdapter.streams.filter((el: TStream) => {
          return el.name !== "park_type";
        })
      );
    } else {
      setStreamsCharts(infoAdapter.streams);
    }
  };

  //vai trocar o estado do select
  const onChangeOptionAdapter = (option: ISelectOption | null) => {
    if (!option) return undefined;
    handleAdapterChange(option);
  };

  const sendRequestDeviceDetails = async () => {
    await refetch()
      .then((res) => {
        const response = res.data;
        dispatch(setSelectedDeviceInfo(response));
      })
      .catch((error) => {
        dispatch(setSelectedDeviceInfo(null));
      });
  };

  const getDeviceDetails = () => {
    // buscar info de detalhe do device selecionado
    sendRequestDeviceDetails();
    setLoadingFirstDevice(false); // este loading é por causa dos 2 loadings/pedidos seguidos para evitar aparecer a mensagem noData
    setStreamsDetailWidget([]);
  };

  const getFirstDevice = (adapterOption: ISelectOption) => {
    refetchFirstDevice({ params: getParamsRequest(adapterOption) });
    if (!loadingFirstDevice && !loadingDeviceDetail)
      setLoadingFirstDevice(true);
  };

  const onChangeURL = (deviceId: string) => {
    const path = formatRoutePath(location, paramsURL, { deviceId });
    navigate(path, { replace: true });
  };

  const clearSomeFieldsRedux = () => {
    dispatch(setSelectedAdapterFilter({ label: "", value: "" }));
    dispatch(setDevice(null));
    dispatch(setDevicesMapDashboard([]));
    dispatch(setSelectedDeviceInfo(null));
  };
  const getAdapterDeviceURL = async () => {
    await refetch()
      .then((res) => {
        const response = res.data;
        const adapter = response.adapter_configurators[0];
        if (
          adapterConfigurators?.data.some(
            (elem: TAdapterConfigurator) => elem.id === adapter.id
          )
        ) {
          handleAdapterChange({
            label: adapter.name,
            value: adapter.id,
          });
        } else {
          clearSomeFieldsRedux();
        }
      })
      .catch((error) => {
        clearSomeFieldsRedux();
      });
  };

  useEffect(() => {
    if (errorFirstDevice) {
      setLoadingFirstDevice(false);
    }
  }, [errorFirstDevice]);

  const detailsWidgetData = {
    coordinates: {
      icon: <LocationOnIcon sx={{ fontSize: 18 }} color="primary" />,
      value: `${selectedDeviceInfo?.geolocation.iconPosition[0].toFixed(
        4
      )}, ${selectedDeviceInfo?.geolocation.iconPosition[1].toFixed(4)}`,
    },
    firstRead: {
      label: t("dashboard.firstRead"),
      icon: <AccessTimeIcon sx={{ fontSize: 18 }} color="primary" />,
      value: formatDate(
        selectedDeviceInfo?.first_read_at,
        t("calendar.dateTimeFullFormat")
      ),
    },
    lastRead: {
      label: t("dashboard.lastRecords"),
      icon: <AccessTimeIcon sx={{ fontSize: 18 }} color="primary" />,
      value: formatDate(
        selectedDeviceInfo?.last_read_at,
        t("calendar.dateTimeFullFormat")
      ),
    },
    tableValues: {
      sizeTable: "small",
      lastCellNoSpace: true,
      rowsValues: streamsDetailWidget,
    },
  };

  const park = selectedDeviceInfo?.last_read_value;
  return (
    <>
      {canREAD && (
        <>
          <ReloadPage interval={3000 * 60} />
          <Grid container>
            {selectedAdapter.value.length > 0 && !loadingAdapters && (
              <>
                <Grid container item xs={false} sm={12} md={12} mt={4}>
                  <DoubleSelect>
                    <Grid item>
                      <Typography mr={1} variant="h6">
                        {t("dashboard.dataFrom")}
                      </Typography>
                    </Grid>
                    <Grid item xs={true}>
                      <FilterSection
                        select={
                          <FilterSelect<TAdapterConfigurator>
                            showLabel
                            showBorder
                            key={`select--${selectedAdapter.label}`}
                            onSelected={onChangeOptionAdapter}
                            selectedValue={selectedAdapter.value}
                            borderColor="rgba(81,191,188,0.5)"
                            sx={{
                              ...(theme.palette.mode === "light" && {
                                backgroundColor: "#ECF8F8",
                              }),
                            }}
                            optionsData={adapterConfigurators}
                          />
                        }
                      ></FilterSection>
                    </Grid>
                  </DoubleSelect>
                </Grid>
                <Loading show={loadingDeviceDetail || loadingFirstDevice} />
                {!loadingDeviceDetail &&
                  !loadingFirstDevice &&
                  selectedDeviceId.length > 0 &&
                  selectedDevice && (
                    <>
                      <Grid
                        container
                        item
                        xs={false}
                        sm={12}
                        md={12}
                        mt={3}
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={false}
                          sm={12}
                          md={selectedDeviceInfo ? 8 : 12}
                        >
                          <MapDevices />
                        </Grid>
                        {selectedDeviceInfo && (
                          <Grid item xs={false} sm={12} md={4}>
                            <DetailsWidget
                              subTitle={t(
                                `domains.${selectedDeviceInfo?.adapter_configurators[0].domain}`
                              )}
                              title={selectedDeviceInfo?.name}
                              avatar={
                                <AppLogo
                                  sx={{ width: 60, height: "auto" }}
                                  variant="circular"
                                  src={getIcons(
                                    "device",
                                    selectedDeviceInfo?.adapter_configurators[0]
                                      .domain,
                                    selectedDeviceInfo?.is_active,
                                    park?.park_type?.value || "",
                                    park?.park_occupied?.value + "" || ""
                                  )}
                                  alt={
                                    selectedDeviceInfo?.adapter_configurators[0]
                                      .domain
                                      ? t(
                                          `domains.${selectedDeviceInfo?.adapter_configurators[0].domain}`
                                        )
                                      : "device"
                                  }
                                />
                              }
                              iconStatus={
                                <IconStatus
                                  isActive={selectedDeviceInfo?.is_active}
                                />
                              }
                              data={detailsWidgetData}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container item xs={false} sm={12} md={12} my={3}>
                        {selectedDeviceInfo &&
                          streamsChart &&
                          streamsChart.length > 0 && (
                            <StreamsChartAnalysis
                              selectedDeviceInfo={selectedDeviceInfo}
                              streamsChart={streamsChart}
                              smartParking={typeSmartParking}
                            />
                          )}
                      </Grid>
                    </>
                  )}
                {((!loadingDeviceDetail &&
                  !loadingFirstDevice &&
                  (selectedDeviceId.length === 0 ||
                    (!selectedDevice && !selectedDeviceInfo))) ||
                  errorFirstDevice) && <NoData error={errorFirstDevice} />}
              </>
            )}

            {!loadingDeviceDetail &&
              !loadingAdapters &&
              (arrayIsEmpty(adapterConfigurators?.data) ||
                (!arrayIsEmpty(adapterConfigurators?.data) &&
                  selectedAdapter.value.length === 0)) && (
                <NoData error={errorAdapters} />
              )}

            <Loading show={loadingAdapters} />
          </Grid>
        </>
      )}
    </>
  );
};

export default Dashboard;
