import { IApiResponse } from "@alb/live-lib";
import { INotification } from "@alb/live-lib/templates/sidebar/components/SidebarNotificationItem";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchLastNotifications, fetchReadNotification, getLastNotifications, getReloadLastNotifications } from "store/slices/notificationsSlice";
import { INotificationAPI } from "types/interfaces";
import { formatDate } from "utils/date";
import { useNotificationsUtils } from "./useNotificationsUtils";

export const useGetLastNotifications = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const lastNotificationsResponse: IApiResponse<INotificationAPI> = useSelector(getLastNotifications);
	const reloadLastNotifications: boolean = useSelector(getReloadLastNotifications);
	const {getTitle, getLink, getIcon} = useNotificationsUtils();

	function readNotification(id: string, read: boolean, reload?: boolean) {
		dispatch(fetchReadNotification({ id: id, read: read, reload: reload }));
	}

	const onActionClickHandler = (notification: INotification) => {
		readNotification(notification.id, !notification.read, true);
	}

	const onNotificationClickHandler = (notification: INotificationAPI) => {
		if (!notification.read) {
			readNotification(notification.id, true, true);
		}
	}

	const [lastNotifications, setLastNotifications] = useState<INotification[]>([]);
	useEffect(() => {
		if (!lastNotificationsResponse) return;

		const _lastNotifications: INotification[] = [];
		lastNotificationsResponse?.data.forEach((notification: INotificationAPI) => {
			_lastNotifications.push({
				id: notification.id,
				title: getTitle(notification),
				subTitle: formatDate(notification.created_at, t("calendar.dateFullFormatOf")),
				read: notification.read,
				icon: getIcon(notification),
				// linkTo: "/notificationRedirect/"+ notification.id,
				linkTo: getLink(notification),
				onItemClick: () => onNotificationClickHandler(notification),
				onActionClick: onActionClickHandler,
			})
		});

		setLastNotifications(_lastNotifications)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastNotificationsResponse])

	useEffect(() => {
		if (!reloadLastNotifications) return;
		dispatch(fetchLastNotifications());

	}, [reloadLastNotifications, dispatch])

	return { lastNotifications, count: lastNotificationsResponse?.totalCount || 0 };
};
