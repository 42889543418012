import { IOccurrenceForm } from "types/interfaces";

export const formDefaultValues = (data?: IOccurrenceForm) => {
  const defaultValues = {
    name: data?.name || "",
    // type: data?.type.id || "",
    description: data?.description || "",
    category: data?.category?.id || "",
    start_date: data?.start_date || null,
    end_date: data?.end_date || null,
    geolocation: JSON.stringify(data?.geolocation.iconPosition) || "",
    address: data?.address || "",
    channel: data?.channel || null,
    status: data?.status?.id || "",
    // subject: data?.subject || "",
    status_date: data?.status_date || null,
    url: data?.url || "",
    reporter: data?.reporter || "",
    notes: data?.notes || "",
    urgency: data?.urgency?.id || "",
    assignee: {
      assignee_type: data?.assignee?.assignee_type || "",
      assignee_id: data?.assignee?.assignee_id  || ""
    }
  };
  return defaultValues;
};
