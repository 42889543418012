import { PageLayout } from "@alb/live-lib";
import { createBrowserRouter, Outlet } from "react-router-dom";

import EventDetail from "components/events/EventDetail";
import EventsAdministration from "components/events/EventsAdministration/Administration";
import CategoriesList from "components/events/EventsAdministration/categories/CategoriesList";
import StatusList from "components/events/EventsAdministration/status/StatusList";
import TypesList from "components/events/EventsAdministration/types/TypesList";
import OccurrencesAdministration from "components/occurrences/administration/Administration";
import OccurrencesCategoriesList from "components/occurrences/administration/categories/CategoriesList";
import OccurrencesStatusList from "components/occurrences/administration/status/StatusList";
import OccurrencesUrgenciesList from "components/occurrences/administration/urgencies/UrgenciesList";
import OccurrenceDetail from "components/occurrences/OccurrenceDetail";
import About from "pages/About";
import CERManagement from "pages/CERManagement";
import ComparativeAnalysis from "pages/ComparativeAnalysis";
import Dashboard from "pages/Dashboard";
import ElectricMobility from "pages/ElectricMobility";
import EnergyPTD from "pages/EnergyPTD";
import NotFound from "pages/errors/NotFound";
import Unauthorized from "pages/errors/Unauthorized";
import Events from "pages/Events";
import Executive from "pages/Executive";
import ForgotPassword from "pages/ForgotPassword";
import HydrogenSupply from "pages/HydrogenSupply";
import IsoPage from "pages/indicators/iso/IsoPage";
import IsoViewPage from "pages/indicators/iso/IsoViewPage";
import Invite from "pages/Invite";
import Accounts from "pages/management/accounts/Accounts";
import AccountsViewPage from "pages/management/accounts/AccountsViewPage";
import Adapters from "pages/management/adapters/Adapters";
import AdaptersViewPage from "pages/management/adapters/AdaptersViewPage";
import Clients from "pages/management/client";
import ClientsViewPage from "pages/management/ClientsView";
import GroupDetail from "pages/management/groups/GroupDetail";
import Groups from "pages/management/groups/Groups";
import Modules from "pages/management/Modules";
import Users from "pages/management/users/List";
import { UserDetail } from "pages/management/users/UserDetail";
import Map from "pages/Map";
import Notifications from "pages/Notifications";
import Occurrences from "pages/Occurrences";
import OpenData from "pages/OpenData";
import Parking from "pages/Parking";
import ProfileSettings from "pages/ProfileSettings";
import PublicPortal from "pages/PublicPortal";
import RecoverPassword from "pages/RecoverPassword";
import UrbanWaste from "pages/UrbanWaste";
import { PERMISSIONS } from "utils/permissions/permissions";

import AllowedPermission from "./allowedPermission";
import RequireAuth from "./requireAuth";

const router = createBrowserRouter([
	{
		path: "login",
		element: <RequireAuth required={false} />,
	},
	{
		path: "/",
		element: <RequireAuth required={false} />,
	},
	{
		element: <RequireAuth required={true} />,
		path: "/",
		children: [
			{
				path: "map",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MAP.READ}
						element={<Map />}
					/>
				),
			},
			{
				path: "dashboard",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.DASHBOARD.READ}
						element={<Dashboard />}
					/>
				),
				children: [
					{
						path: ":deviceId",
						element: (
							<AllowedPermission
								permission={PERMISSIONS.DASHBOARD.READ}
								element={<Dashboard />}
							/>
						),
					},
				],
			},
			{
				path: "events",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.EVENTS.READ}
						element={<Events />}
					/>
				),
			},
			{
				path: "events/:eventId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.EVENTS.READ}
						element={<EventDetail />}
					/>
				),
			},
			{
				path: "occurrences",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.OCCURRENCES.READ}
						element={<Occurrences />}
					/>
				),
			},
			{
				path: "occurrences/:occurrenceId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.OCCURRENCES.READ}
						element={<OccurrenceDetail />}
					/>
				),
			},
			{
				path: "consumption-bt",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.ENERGYPTD.READ}
						element={<EnergyPTD />}
					/>
				),
			},
			{
				path: "parking",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.PARKING.READ}
						element={<Parking />}
					/>
				),
			},
			{
				path: "executive",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.EXECUTIVE.READ}
						element={<Executive />}
					/>
				),
			},
			{
				path: "management/modules/",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<Modules />}
					/>
				),
			},
			{
				path: "management/modules/events",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<EventsAdministration />}
					/>
				),
			},
			{
				path: "management/modules/events/categories",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<CategoriesList />}
					/>
				),
			},
			{
				path: "management/modules/events/types",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<TypesList />}
					/>
				),
			},
			{
				path: "management/modules/events/status",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<StatusList />}
					/>
				),
			},
			{
				path: "iso-indicators",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.INDICATORS.ISO.READ}
						element={<IsoPage />}
					/>
				),
			},
			{
				path: "iso-indicators/:isoIndicatorName",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.INDICATORS.ISO.READ}
						element={<IsoViewPage />}
					/>
				),
			},
			{
				path: "management/users",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.USERS.READ}
						element={<Users />}
					/>
				),
			},
			{
				path: "management/users/:userId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.USERS.READ}
						element={<UserDetail />}
					/>
				),
			},
			{
				path: "management/groups",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.GROUPS.READ}
						element={<Groups />}
					/>
				),
			},
			{
				path: "management/groups/:groupId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.GROUPS.READ}
						element={<GroupDetail />}
					/>
				),
			},
			{
				path: "management/adapters",
				element: (
					<AllowedPermission
						element={<Adapters />}
						permission={PERMISSIONS.MANAGEMENT.ADAPTERS.READ}
					/>
				),
			},
			{
				path: "management/adapters/:adaptersId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.ADAPTERS.READ}
						element={<AdaptersViewPage />}
					/>
				),
			},
			{
				path: "management/accounts",
				element: (
					<AllowedPermission
						element={<Accounts />}
						permission={PERMISSIONS.MANAGEMENT.ACCOUNTS.READ}
					/>
				),
			},
			{
				path: "management/accounts/:accountsId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.ACCOUNTS.READ}
						element={<AccountsViewPage />}
					/>
				),
			},
			{
				path: "management/modules/occurrences",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<OccurrencesAdministration />}
					/>
				),
			},
			{
				path: "management/modules/occurrences/categories",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<OccurrencesCategoriesList />}
					/>
				),
			},
			{
				path: "management/modules/occurrences/urgencies",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<OccurrencesUrgenciesList />}
					/>
				),
			},
			{
				path: "management/modules/occurrences/status",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.MODULES.READ}
						element={<OccurrencesStatusList />}
					/>
				),
			},
			// {
			//   path: "management/alerts",
			//   element: (
			//     <AllowedPermission
			//       permission={PERMISSIONS.MANAGEMENT.ALERTS.READ}
			//       element={<Alerts/>}
			//     />
			//   ),
			// },
			{
				path: "/comparativeanalysis",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.COMPARATIVE_ANALYSIS.READ}
						element={<ComparativeAnalysis />}
					/>
				),
			},
			{
				path: "profile-settings",
				element: <ProfileSettings />,
			},
			{
				path: "about",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.ABOUT.READ}
						element={<About />}
					/>
				),
			},
			{
				path: "opendata",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.OPENDATA.READ}
						element={<OpenData />}
					/>
				),
			},
			{
				path: "administration/clients",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.CLIENTS.READ}
						element={<Clients />}
					/>
				),
			},
			{
				path: "administration/clients/:clientsId",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MANAGEMENT.CLIENTS.READ}
						element={<ClientsViewPage />}
					/>
				),
			},
			{
				path: "electricmobility",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.ELECTRICMOBILITY.READ}
						element={<ElectricMobility />}
					/>
				),
			},
			{
				path: "hydrogensupply",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.HYDROGENSUPPLY.READ}
						element={<HydrogenSupply />}
					/>
				),
			},
			{
				path: "urbanwaste",
				element: (
					<AllowedPermission
						permission={PERMISSIONS.MODULES.URBANWASTE.READ}
						element={<UrbanWaste />}
					/>
				),
			},
      {
        path: "cermanagement",
        element: (
          <AllowedPermission
            permission={PERMISSIONS.MODULES.CERMANAGEMENT.READ}
            element={<CERManagement />}
          />
        ),
      },
			{
				path: "notifications",
				// element: (
				// 	<AllowedPermission
				// 		permission={PERMISSIONS.MODULES.NOTIFICATIONS.READ}
				// 		element={<Notifications />}
				// 	/>
				// ),
				element: <Notifications />,
			},

		],
	},
	{
		path: "/unauthorized",
		element: <Unauthorized />,
	},
	{
		path: "*",
		element: <NotFound />,
	},
	// ---- public portal
	{
		element: <PageLayout children={<Outlet />} />,
		path: "/",
		children: [
			{
				path: "funchal",
				element: <PublicPortal />,
			},
			// {
			//   path: "aveiro",
			//   element: <PublicPortal />,
			// },
		],
	},
	{
		element: <RequireAuth required={false} invite />,
		path: "/",
		children: [
			{
				path: "users/invite",
				element: <Invite />,
			},
			{
				path: "users/recover",
				element: <RecoverPassword />,
			},
			{
				path: "forgot",
				element: <ForgotPassword />,
			},
		],
	},
]);

export { router };
