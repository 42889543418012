/* eslint-disable react-hooks/exhaustive-deps */
import { IMap, IMapPoint, Map } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TOccurrence } from "types/types";
import { v4 as uuid } from "uuid";

import { getUser } from "store/slices/authSlice";
import { setBounds } from "store/slices/dashboardSlice";
import { getFitBounds, selectDrawingMap, setFitBounds } from "store/slices/mapSlice";
import { objectIsEmpty } from "utils/utils";

interface IOccurrenceDetailMap {
  occurrence: TOccurrence;
}

const OccurrenceDetailMap = ({ occurrence }: IOccurrenceDetailMap) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const fitBounds = useSelector(getFitBounds);
  const drawingEditMap = useSelector(selectDrawingMap);
  const theme = useTheme();

  const initialZoom = 15;
  const mapTile = user.extra_params?.map_tile_layer;
  const darkMode = user.extra_params?.dark_mode;
  const translations = {
    buttons: {
      layers: t("map.buttons.layers"),
      recenter: t("map.buttons.recenter"),
    },
    popups: {
      lastRead: t("map.marker.lastReading"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
  };

  const [zoom, setZoom] = useState<number>(initialZoom);
  const [argsMap, setArgsMap] = useState<IMap | null>(null);

  const handleZoom = (zoom: number) => {
    setZoom(zoom);
  };

  const handleChangeFitBounds = (fitBoundsValue: boolean) => {
    fitBoundsValue !== fitBounds && dispatch(setFitBounds(fitBoundsValue));
  };

  const handleBoundsChange = (bounds: number[][]) => {
    dispatch(setBounds(bounds));
  };

  const defaultArgs = {
    mapZoom: zoom,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    appDarkThemeMode: darkMode,
    mapVariant: "component" as "component",
    mapModule: false,
    showZoom: true,
    onZoom: handleZoom,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: translations,
    mapFitBounds: fitBounds,
    onChangeFitBounds: handleChangeFitBounds,
    onBounds: handleBoundsChange,
  };

  useEffect(() => {
    if (!objectIsEmpty(occurrence) && !drawingEditMap) {
      const markers: IMapPoint[] = [
        {
          id: occurrence.id,
          external_id: occurrence.external_id,
          title: occurrence.name,
          type: occurrence.type,
          subType: occurrence.category?.name,
          geolocation: occurrence.geolocation,
          status: occurrence.status?.name,
          date: [String(occurrence.created_at)],
          selected: false,
          icon: occurrence.category?.icon
        },
      ];
      setArgsMap({
        idMap: uuid(),
        ...defaultArgs,
        mapCenterPoint: occurrence.geolocation.iconPosition,
        initialMapCenter: occurrence.geolocation.iconPosition,
        points: markers,
      });
    }
  }, [occurrence, drawingEditMap]);

  return (
    <>
      {!objectIsEmpty(occurrence) && argsMap && (
        <Box
          className="map-component"
          my={3}
          sx={{
            height: "300px",
            ">div >div.leaflet-container": {
              borderRadius: theme.shape.borderRadius,
            },
          }}
        >
          <Map {...argsMap} />
        </Box>
      )}
    </>
  );
};
export default OccurrenceDetailMap;
