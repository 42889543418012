/* eslint-disable react-hooks/exhaustive-deps */
import { IMap, IMapPoint, Map } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TEvent } from "types/types";
import { v4 as uuid } from "uuid";

import { getUser } from "store/slices/authSlice";
import { selectDrawingMap } from "store/slices/mapSlice";
import { objectIsEmpty } from "utils/utils";

interface IEventDetailMap {
  event: TEvent;
}

const EventDetailMap = ({ event }: IEventDetailMap) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
	const drawingEditMap = useSelector(selectDrawingMap);
  const theme = useTheme();

	const idMap = uuid();

  const initialZoom = 15;
  const mapTile = user.extra_params?.map_tile_layer;
  const darkMode = user.extra_params?.dark_mode;
  const translations = {
    buttons: {
      layers: t("map.buttons.layers"),
      recenter: t("map.buttons.recenter"),
    },
    popups: {
      lastRead: t("map.marker.lastReading"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
  };

  const [argsMap, setArgsMap] = useState<IMap | null>(null);

  const defaultArgs = {
    mapZoom: initialZoom,
    mapTileType: mapTile,
    appDarkThemeMode: darkMode,
    mapVariant: "component" as "component",
    mapModule: false,
    showZoom: true,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: translations,
    mapFitBounds: true,
  };

  useEffect(() => {
    if (!objectIsEmpty(event) && !drawingEditMap) {
			const markers: IMapPoint[] = [
        {
          id: event.id,
          title: event.name,
          type: "event",
          subType: "",
          geolocation: event.geolocation,
          status: event.status?.name,
          date: [event.created_at.toString()],
          selected: false,
        },
      ];
      setArgsMap({
        idMap: idMap,
        ...defaultArgs,
        mapCenterPoint: event.geolocation.iconPosition,
        initialMapCenter: event.geolocation.iconPosition,
        points: markers,
      });
    }
  }, [event, drawingEditMap]);

  return (
    <>
      {!objectIsEmpty(event) && argsMap && (
        <Box
          className="map-component"
          my={3}
          sx={{
            height: "300px",
            ">div >div.leaflet-container": {
              borderRadius: theme.shape.borderRadius,
            },
          }}
        >
          <Map {...argsMap} />
        </Box>
      )}
    </>
  );
};
export default EventDetailMap;
