import { getIcons } from "components/map/list/listIcons";
import { AppLogo } from "@alb/live-lib";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { INotificationAPI } from "types/interfaces";
import { useTranslation } from "react-i18next";

export const useNotificationsUtils = () => {
	const { t } = useTranslation();
	const userStorage = useSelector(getUser);

	function getTitle(notification: INotificationAPI) {
		let title = '';

		switch (notification.type) {
			case "alarm":
				title = t("notifications.devices.device", { device: notification.content.device_name, adapter: notification.content.adapter_name, status: notification.content.status === "failure" ? t("notifications.stoppedWork") : t("notifications.worksAgain") });
				break;
			case "occurrence":
				break;
		}

		return title;
	}
	function getIcon(notification: INotificationAPI): React.ReactElement {
		// TODO
		const icon = getIcons(notification.type === "alarm" ? "device" : notification.type, notification.content.domain, notification.content.status !== 'failure');
		const iconElem = <AppLogo
			sx={{ width: 46, height: "auto" }}
			variant="circular"
			src={icon}
			alt={"Device..."} // TODO
		/>

		return iconElem;
	}
	function getDeviceLink(notification: INotificationAPI): string {
		const simCli = notification.client?.findIndex((cli) => cli.id === userStorage.client.id) !== -1;
		if (!simCli) return '';

		return `/dashboard/${notification.content.device_id}`;
	}
	function getLink(notification: INotificationAPI): string {
		// TODO
		let link = '';

		switch (notification.type) {
			case "alarm":
				link = getDeviceLink(notification) // `/dashboard/${notification.content.device_id}`
				break;
			case "occurrence":
				break;
		}

		return link;
	}

	return {getIcon, getTitle, getLink, getDeviceLink}
}
