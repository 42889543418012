import { I18NEXT } from "utils/keys";
import { getCookie } from "./cookie";

interface INumberOptions {
    style?: "decimal" | "currency" | "percent",
    useGrouping?: boolean,
    minimumIntegerDigits?: number,
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
    minimumSignificantDigits?: number,
    maximumSignificantDigits?: number,
    currency?: string,
    currencyDisplay?: "symbol" | "code" | "name"
}
const defaultDecimalOptions: INumberOptions = {
    style: "decimal",
    useGrouping: true,
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0
};

export function isNumeric(value: any) {
  return !isNaN(+value);
}

export function formatNumber(value: string | number, options?: INumberOptions) {
  if (!isNumeric(value)) return value;

	const storedLang = getCookie<string>(I18NEXT) || "en";

  try {
    const valueFormatted = Intl.NumberFormat(storedLang, {...defaultDecimalOptions, ...options}).format(
      Number(value)
    );
    return valueFormatted;
  } catch (error) {
    console.log(`formatNumber error ${error}`);
  }

  return value;
}
