import { IMap, IMapPoint, Map, SearchBar, ToggleViewButton, TToggle, getDefaultBoxShadow } from "@alb/live-lib";
import { Map as MapIcon, ViewList } from "@mui/icons-material";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Paper } from "@mui/material";
import { debounce } from "lodash";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TMarker } from "types/types";
import { v4 as uuid } from "uuid";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getDevicesMapDashboard, getMapBoundsDashboard, getSelectedAdapterFilter, getSelectedDevice, getSelectedDeviceId, getTextSearchBar, setBounds, setDevice, setDevicesMapDashboard, setSelectedDeviceId, setTextSearchBar } from "store/slices/dashboardSlice";
import { getFitBounds, setFitBounds } from "store/slices/mapSlice";
import { arrayIsEmpty } from "utils/conditions";

import ListDevices from "./ListDevices";

const MapDevices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const textSearch = useSelector(getTextSearchBar); //text from searchbar
  const mapBounds = useSelector(getMapBoundsDashboard);
  const fitBounds = useSelector(getFitBounds);
  const user = useSelector(getUser);
  const mapTile = user.extra_params?.map_tile_layer;

  // toggle display state
  const [display, setDisplay] = useState("map");
  // toggle display handler
  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };

  // toggle button configuration
  const toggleConfig: TToggle[] = [
    {
      value: "map",
      icon: <MapIcon fontSize="small" />,
    },
    {
      value: "list",
      icon: <ViewList fontSize="small" />,
    },
  ];

  // atualizar devices e texto do SearchBar
  function setTextSearch(text: SetStateAction<string>) {
    dispatch(setFitBounds(true)); //avisa mapa para fazer fitbounds
    debounceData(() => {
      dispatch(setTextSearchBar(text));
    });
    if (text.length === 0 && argsMap) {
      setArgsMap((prevArgs) => {
        return {
          ...(prevArgs as IMap),
          idMap: uuid(),
        };
      });
    }
  }

  const [argsMap, setArgsMap] = useState<IMap | null>(null);
  const [prevBounds, setPrevBounds] = useState<number[][] | undefined>(
    mapBounds
  );
  const initialZoom = 15;
  const selectedDeviceID = useSelector(getSelectedDeviceId);
  const selectedAdapter = useSelector(getSelectedAdapterFilter);
  const markers: TMarker[] = useSelector(getDevicesMapDashboard);
  const selectedDevice = useSelector(getSelectedDevice);
  const darkMode = useSelector(getUser)?.extra_params?.dark_mode;

  interface OrderParams {
    adapters_id: any;
    points?: any;
  }

  function getParamsRequest() {
    var params1: OrderParams = {
      points: JSON.stringify(mapBounds),
      adapters_id: selectedAdapter.value,
      ...(textSearch?.length > 0 && { contains: textSearch }),
    };
    if (textSearch) {
      delete params1["points"];
      return params1;
    }
    return params1;
  }

  //quando o texto sofrer update do texto do search, faz novamente pedido
  const debounceData = debounce((cb) => {
    cb();
  }, 400);
  useEffect(() => {
    fetchDevicesMarkers();
  }, [textSearch]);

  const options = {
    manual: true,
  };

  const { refetch: refetchDevices } = useGet(
    ServiceApiUrl.devices,
    getParamsRequest(),
    options
  );

  async function fetchDevicesMarkers() {
    if (display === "map" && !arrayIsEmpty(mapBounds)) {
      await refetchDevices()
        .then((res) => {
          const response = res.data.data;
          dispatch(setDevicesMapDashboard(response));
        })
        .catch((error) => {
          dispatch(setDevicesMapDashboard([]));
        });
    }
  }

  function arraysEqual(a1: any, a2: any) {
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
  useEffect(() => {
    if (mapBounds && !arraysEqual(prevBounds, mapBounds)) {
      fetchDevicesMarkers();
    }
    if (textSearch.length > 0 && arraysEqual(prevBounds, mapBounds)) {
      fetchDevicesMarkers();
    }
  }, [mapBounds, display]);

  function getHighlightDevice(markers: IMapPoint[]) {
    let selectedDeviceInfo = markers.find(
      (item: IMapPoint) => item.id === selectedDeviceID
    );
    if (selectedDeviceInfo) {
      selectedDeviceInfo = {
        ...selectedDeviceInfo,
        selected: true,
      };
    }
    return selectedDeviceInfo;
  }

  useEffect(() => {
    if (selectedDevice) {
      const positionDefault =
        textSearch.length === 0 ||
        (textSearch.length > 0 && markers.length === 0)
          ? selectedDevice.geolocation.iconPosition
          : markers[0].geolocation.iconPosition;

      setArgsMap({
        ...defaultArgs,
        mapCenterPoint: positionDefault,
        initialMapCenter: positionDefault, //centro do mapa inicial, estático
        points: markers,
        highlightPoint: getHighlightDevice(markers),
      });
    }
  }, [markers, selectedDevice]);

  useEffect(() => {
    return () => {
      dispatch(setBounds([]));
      if (textSearch.length > 0) dispatch(setTextSearchBar(""));
      if (fitBounds) dispatch(setFitBounds(false));
    };
  }, []);

  const onClickIconMap = (device: IMapPoint) => {
    dispatch(setDevice(device));
    dispatch(setSelectedDeviceId(device.id));
  };

  const handleBoundsChange = (bounds: number[][]) => {
    setPrevBounds(bounds);
    dispatch(setBounds(bounds));
  };

  const translationsMap = {
    buttons: {
      layers: t("map.buttons.layers"),

      recenter: t("map.buttons.recenter"),
      cancel: t("map.buttons.cancel"),
      close: t("close"),
    },
    popups: {
      lastRead: t("map.marker.lastReading"),
      showDetail: t("common.showDetail"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
    markers: {
      active: t("common.active"),
      inactive: t("common.inactive"),
    },
    common: {
      noData: t("common.noData"),
    },
  };

  const handleChangeFitBounds = (fitBoundsValue: boolean) => {
    fitBoundsValue !== fitBounds && dispatch(setFitBounds(fitBoundsValue));
  };

  const defaultArgs = {
    mapTranslations: { ...translationsMap },
    language: user?.language.toLowerCase() || "pt",
    mapVariant: "component" as "component",
    mapZoom: initialZoom,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    mapModule: false,
    showZoom: true,
    onPointClick: onClickIconMap,
    onBounds: handleBoundsChange,
    searchBarText: textSearch,
    appDarkThemeMode: darkMode,
    mapFitBounds: fitBounds,
    onChangeFitBounds: handleChangeFitBounds,
  };
  return (
    <>
      <Paper
        sx={{
          minWidth: "100%",
          height: 420,
          boxShadow: getDefaultBoxShadow(),
          position: "relative",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            zIndex: 500,
            paddingTop: 1,
            paddingRight: 1,
            position: "absolute",
            width: "50%",
            right: 0,
          }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={10} lg={7}>
            <SearchBar
              placeholder={t("dashboard.searchDevice")}
              setTerm={setTextSearch}
              term={textSearch}
              tooltipClearText={t("dashboard.clear")}
              tooltipSearchText={t("dashboard.search")}
            />
          </Grid>
          <Grid item>
            <ToggleViewButton
              display={display}
              toggle={toggleConfig}
              onChange={handleDisplay}
            />
          </Grid>
        </Grid>
        {display === "map" && (
          <Box className="map-component">{argsMap && <Map {...argsMap} />}</Box>
        )}
        {display === "list" && <ListDevices />}
      </Paper>
    </>
  );
};

export default MapDevices;
