/* eslint-disable react-hooks/exhaustive-deps */
import { ServiceApiUrl } from "services/ServiceApiUrl";

import { useDispatch, useSelector } from "react-redux";

import {
  getMapBounds,
  getSelectedMarkerType,
  getShapeBounds,
  setMarkers,
} from "store/slices/mapSlice";
import { selectDevices } from "store/slices/adapterConfiguratorsSlice";
import {
  fetchEvents,
  getEvents,
  selectEventsFilterParams,
} from "store/slices/eventsSlice";
import {
  fetchOccurrences,
  getOccurrences,
  selectOccurrencesFilterParams,
} from "store/slices/occurrencesSlice";

import { TMarker, TUser } from "types/types";

import { arrayIsEmpty } from "utils/conditions";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { IClientPublicPortal } from "utils/clientsPublicPortal";

import { IFilters } from "components/map/controls/FilterMapTab/FilterTabForm";
import { MarkersTypeOptions } from "components/map/controls/FilterMapTab/utils/GetOptions";
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";

const useMarkers = () => {
  const { hasPermission } = usePermission();
  const readEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ);
  const readOccurences = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.READ);

  const dispatch = useDispatch<any>();
  const devices: TMarker[] = useSelector(selectDevices);
  const events: TMarker[] = useSelector(getEvents);
  const occurrences: TMarker[] = useSelector(getOccurrences);
  const bounds = useSelector(getMapBounds);
  const selectedType = useSelector(getSelectedMarkerType);
  let mapOccurrencesParams = useSelector(selectOccurrencesFilterParams);
  let mapEventsParams = useSelector(selectEventsFilterParams);
  const shapeBounds = useSelector(getShapeBounds);

  function modulesParams(
    publicAPI: boolean,
    user: TUser | IClientPublicPortal,
    keyModule: string,
    url: string,
    filterParams: IFilters
  ) {
    return {
      publicAPI,
      user,
      keyModule,
      url,
      ...(!arrayIsEmpty(shapeBounds) ? { bounds: shapeBounds } : { bounds }),
      filterParams,
    };
  }

  // buscar markers dos modulos dos clients
  function getMarkersModulesClient(
    user: TUser | IClientPublicPortal,
    publicAPI: boolean
  ) {
    getEventsData(user, publicAPI);
    getOccurrencesData(user, publicAPI);
  }
  // ------------- Events Module
  function getEventsData(
    user: TUser | IClientPublicPortal,
    publicAPI: boolean = false
  ) {
    const userData = user as TUser;
    const client = user as IClientPublicPortal;
    const eventsParams = modulesParams(
      publicAPI,
      user,
      MODULES_KEYS.EVENTS,
      !publicAPI
        ? ServiceApiUrl.events
        : ServiceApiUrl.publicEvents(client.city),
      mapEventsParams
    );
    if (
      readEvents &&
      !publicAPI &&
      !arrayIsEmpty(userData.client.modules) &&
      !arrayIsEmpty(bounds)
    ) {
      dispatch(fetchEvents(eventsParams));
    } else if (publicAPI && !arrayIsEmpty(bounds)) {
      dispatch(fetchEvents(eventsParams));
    }
  }
  // ------------- Occurrences Module
  function getOccurrencesData(
    user: TUser | IClientPublicPortal,
    publicAPI: boolean = false
  ) {
    const userData = user as TUser;
    const client = user as IClientPublicPortal;
    const occurrencesParams = modulesParams(
      publicAPI,
      user,
      MODULES_KEYS.OCCURRENCES,
      !publicAPI
        ? ServiceApiUrl.occurrences
        : ServiceApiUrl.publicOccurrences(client.city),
      mapOccurrencesParams
    );
    if (
      readOccurences &&
      !publicAPI &&
      !arrayIsEmpty(userData.client.modules) &&
      !arrayIsEmpty(bounds)
    ) {
      dispatch(fetchOccurrences(occurrencesParams));
    } else if (publicAPI && !arrayIsEmpty(bounds)) {
      dispatch(fetchOccurrences(occurrencesParams));
    }
  }

  // buscar devices
  function getMarkersDevices() {
    return devices;
  }

  // buscar markers dos modulos do client
  function getMarkersModules() {
    return [...events, ...occurrences];
  }

  // agrupar e atualizar todos os markers
  function getAllMarkersMap() {
    let markers = getMarkersOfSelectedType(selectedType);
    dispatch(setMarkers(markers));
  }

  function getMarkersOfSelectedType(type: string) {
    const devices = getMarkersDevices();
    const modulesMarkers = getMarkersModules();
    let markers: TMarker[] = [];
    switch (type) {
      case MarkersTypeOptions.devices:
        markers = [...devices];
        break;
      case MarkersTypeOptions.events:
        markers = [...events];
        break;
      case MarkersTypeOptions.occurrences:
        markers = [...occurrences];
        break;
      case MarkersTypeOptions.all:
        markers = [...devices, ...modulesMarkers];
        break;
      case MarkersTypeOptions.visits:
        markers = [];
        break;
    }
    return markers;
  }

  return {
    getMarkersModulesClient,
    getAllMarkersMap,
    getEventsData,
    getOccurrencesData,
    getMarkersOfSelectedType,
  };
};

export default useMarkers;
