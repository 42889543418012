/* eslint-disable react-hooks/exhaustive-deps */
import { EqualityFn } from "react-redux";
import { useLocation } from "react-router";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getSelectedModule } from "utils/modules/modules";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { PERMISSIONS } from "utils/permissions/permissions";
import { clientPublicPortal } from "utils/clientsPublicPortal";
import { useEffect } from "react";
import { ISelectOption } from "types/interfaces";
import { TFunction } from "i18next";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";

export enum MarkersTypeOptions {
  all = "all",
  devices = "devices",
  events = "events",
  occurrences = "occurrences",
  visits = "visits"
}

export function GetOptions(
  useSelector: <TState = unknown, Selected = unknown>(
    selector: (state: TState) => Selected,
    equalityFn?: EqualityFn<Selected> | undefined
  ) => Selected,
  useGet: any,
  t: TFunction<"translation", undefined>,
  hasPermission: (permission: number) => boolean,
  publicAPI: boolean
) {
  // ---- headers for privateAPI
  let { headers: headersEvents } = useGetModuleHeaders(MODULES_KEYS.EVENTS);
  let { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
  let eventsStatusOptions = undefined;
  let occurrencesStatusOptions = undefined;
  let eventsCategoriesOptions = undefined;
  let occurrencesCategoriesOptions = undefined;
  const user = useSelector(getUser);
  const canReadEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ);
  const canReadOccurrences = hasPermission(
    PERMISSIONS.MODULES.OCCURRENCES.READ
  );
  const moduleIDEvents =
    !publicAPI && canReadEvents
      ? getSelectedModule(user, MODULES_KEYS.EVENTS)
      : "";
  const moduleIDOccurrences =
    !publicAPI && canReadOccurrences
      ? getSelectedModule(user, MODULES_KEYS.OCCURRENCES)
      : "";

  const convertToOptions = (data: any) => {
    let selectOptions = undefined;
    if (data) {
      const options = data?.data?.map((p: any) => ({
        label: p.name,
        value: p.id,
      }));
      selectOptions = options.sort((a: ISelectOption, b: ISelectOption) => //sort by label
        a.label > b.label ? 1 : -1
      );
    }
    return selectOptions;
  };

  // ---- headers for publicAPI
  const location = useLocation();
  if (publicAPI) {
    const client = clientPublicPortal(location.pathname);
    const headersPublicAPI = {
      headers: {
        "Client-ID": client?.id,
      },
    };
    headersEvents = headersPublicAPI;
    headersOCurrences = headersPublicAPI;
  }
  useEffect(() => {
    if (moduleIDEvents || publicAPI) {
      refetchEventsCategories();
      refetchEventsStatus();
    }
    if (moduleIDOccurrences || publicAPI) {
      refetchOccurrencesCategories();
      refetchOccurrencesStatus();
    }
  }, [moduleIDOccurrences, moduleIDEvents]);

  //get events status
  const { data: eventsStatus, refetch: refetchEventsStatus } = useGet(
    !publicAPI
      ? `${ServiceApiUrl.events}/status`
      : ServiceApiUrl.publicEventsFilters(location.pathname.slice(1), "status"),
    undefined,
    { manual: true },
    headersEvents
  );
  if (eventsStatus) {
    eventsStatusOptions = eventsStatus?.data?.map(
      (p: { name: string, id: string }, index: number) => ({
        label: p.name,
        value: p.id,
      })
    );
  }
  //get occurrences status
  const { data: occurrencesStatus, refetch: refetchOccurrencesStatus } = useGet(
    !publicAPI
      ? `${ServiceApiUrl.occurrences}/status`
      : ServiceApiUrl.publicOccurrencesFilters(
          location.pathname.slice(1),
          "status"
        ),
    undefined,
    { manual: true },
    headersOCurrences
  );
  if (occurrencesStatus) {
    occurrencesStatusOptions = occurrencesStatus?.data?.map(
      (p: any, index: number) => ({
        label: p.name,
        value: p.id,
      })
    );
  }
  //get events categories
  const { data: eventsCategories, refetch: refetchEventsCategories } = useGet(
    !publicAPI
      ? `${ServiceApiUrl.events}/categories`
      : ServiceApiUrl.publicEventsFilters(
          location.pathname.slice(1),
          "categories"
        ),
    undefined,
    { manual: true },
    headersEvents
  );
  //get occurrences categories
  const { data: occurrencesCategories, refetch: refetchOccurrencesCategories } =
    useGet(
      !publicAPI
        ? `${ServiceApiUrl.occurrences}/categories`
        : ServiceApiUrl.publicOccurrencesFilters(
            location.pathname.slice(1),
            "categories"
          ),
      undefined,
      { manual: true },
      headersOCurrences
    );

  if (eventsCategories)
    eventsCategoriesOptions = convertToOptions(eventsCategories);

  if (occurrencesCategories)
    occurrencesCategoriesOptions = convertToOptions(occurrencesCategories);

  const typeOptions = [
    {
      label: t("map.filter.types.all"),
      value: MarkersTypeOptions.all,
    },
    ...(!publicAPI
      ? [
          {
            label: t("map.filter.types.devices"),
            value: MarkersTypeOptions.devices,
          },
        ]
      : []),
    ...(canReadEvents || publicAPI
      ? [
          {
            label: t("map.filter.types.events"),
            value: MarkersTypeOptions.events,
          },
        ]
      : []),
    ...(canReadOccurrences || publicAPI
      ? [
          {
            label: t("map.filter.types.occurrences"),
            value: MarkersTypeOptions.occurrences,
          },
        ]
      : []),
  ];

  const devicesStatusOptions = [
    {
      label: t("common.active"),
      value: "True",
    },
    {
      label: t("common.inactive"),
      value: "False",
    },
  ];

  const visitsMadeOptions = [
    {
      label: t("map.filter.options.visitors"),
      value: "visitors"
    },
    {
      label: t("map.filter.options.tourists"),
      value: "tourists"
    }
  ]

  const geographicalAreaOptions = [
    {
      label: t("map.detail.nationals"),
      value: "nationals"
    },
    {
      label: t("map.detail.internationals"),
      value: "internationals"
    }
  ]

  const periodOptions = [
    {
      label: t("map.detail.morning"),
      value: "morning"
    },
    {
      label: t("map.detail.afternoon"),
      value: "afternoon"
    },
    {
      label: t("map.detail.night"),
      value: "night"
    }
  ]

  return {
    eventsStatusOptions,
    occurrencesStatusOptions,
    eventsCategoriesOptions,
    occurrencesCategoriesOptions,
    typeOptions,
    devicesStatusOptions,
    MarkersTypeOptions,
    visitsMadeOptions,
    geographicalAreaOptions,
    periodOptions,
  };
}
