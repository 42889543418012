/* eslint-disable react-hooks/exhaustive-deps */
import { IApiResponse, Loading } from "@alb/live-lib";
import { Grid, Typography, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOccurrenceStatus, ISelectOption } from "types/interfaces";

import { defaultOptions } from "components/charts/defaultOptions";
import FilterSelect from "components/Utils/FilterSelect";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatNumber } from "utils/number";

const ChartCategory = (props: {
  selectedRange: {
    startDate: Date;
    endDate: Date;
  },
  needToRefetch: boolean | undefined}) => {



  const { needToRefetch, selectedRange } = props;
  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
  const theme = useTheme();

  const { t } = useTranslation();
  const ALL_OPTION_KEY = "all";

  const allOption = { label: t("occurrences.all"), value: ALL_OPTION_KEY };
  const [occurrenceStatus, setOccurrenceStatus] = useState<{
    label: string;
    value: string;
  }>(allOption);


  //request to get total occurrences by category
  const { data, loading, error, refetch: refetchOccurrences } = useGet(
    ServiceApiUrl.occurrencesAllCategories,
    getParams(),
    undefined,
    headers
  );


  function getParams() {
		let params: any =  {
      ...(occurrenceStatus.value !== ALL_OPTION_KEY && {
        status: occurrenceStatus.value,
      }),
    };
		if (selectedRange?.startDate || selectedRange?.endDate) {
      params.start_date_interval = formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
		}
		return params;
	}

  //transform data to get only the ones with values
  let occurrencesByCategories = data?.filter(
    (item: { name: string; value: number }) =>
      item.value > 0 && item.name !== "occurrences"
  );

  useEffect(() => {
    if(needToRefetch) {
      refetchOccurrences();
    }

  }, [needToRefetch])

  //chart options
  var option = {
    tooltip: {
      confine: true,
      extraCssText: "white-space:inherit;",
      trigger: "item",
      formatter: function (value: any) {
        return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br/>`;
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      bottom: 0,
      textStyle: {
        color: theme.palette.text.primary,
        fontFamily: "Altice",
        fontSize: 13,
      },
    },
    series: [
      {
        bottom: "20%",
        color: defaultOptions.color,
        name: t("occurrences.category"),
        type: "pie",
        radius: ["30%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: occurrencesByCategories,
      },
    ],
  };

  //on status change
  const handleSelectedStatus = (option: ISelectOption | null) => {
    if (!option) return undefined;
    setOccurrenceStatus(option);
  };

  const customOptions = (data: IApiResponse<IOccurrenceStatus>) => {
    let opt = data.data?.map((occurrence: IOccurrenceStatus) => ({
      label: occurrence?.name,
      value: occurrence?.id,
    }));
    return [{ label: t("occurrences.all"), value: ALL_OPTION_KEY }, ...opt];
  };

  return (
    <>
      <>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              display: "inline-flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ padding: "8px 0px" }}
            >
              {t("occurrences.status")}:
            </Typography>
            {occurrenceStatus && (
              <FilterSelect<IOccurrenceStatus>
                immediateRequest
                apiUrl={ServiceApiUrl.occurrencesStatus}
                onSelected={handleSelectedStatus}
                selectedValue={occurrenceStatus.value}
                customOptions={customOptions}
                othersConfig={headers}
                showLabel
              />
            )}
          </Grid>
        </Grid>
      </>

      <Loading show={loading} />
      {!arrayIsEmpty(occurrencesByCategories) && !loading && (
        <ReactEcharts style={{ height: "500px" }} option={option} />
      )}
      {arrayIsEmpty(occurrencesByCategories) && !loading && <NoData error={error} />}
    </>
  );
};

export default ChartCategory;
