import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TAdapterConfigurator, TModule } from "types/types";

import { ChipStatus } from 'components/Utils/ChipStatus'
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/css/components";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from 'utils/date'
import { formatOrderBy } from "utils/orderByAPI";
import { PERMISSIONS } from "utils/permissions/permissions";
import { statusColor } from 'utils/utils'
import RowItem from "utils/Table/RowItem";
import LinkOffIcon from '@mui/icons-material/LinkOff';

import ModulesAdd from "./ModulesAdd";
import ModulesDelete from "./ModulesDelete";
import ModulesEdit from "./ModulesEdit";
import ModulesDisassociate from "./ModulesDisassociate";

interface IModulesListTab {
	filters: { clientID: string };
}

function Row(props: {
	row: TModule;
	open: boolean;
	onClick: Function;
	actionsClick: {
		editAction: Function;
		deleteAction: Function;
		disassociateAction: Function;
	};
}) {
  const showExpandCell = true;
	const { row, open, onClick, actionsClick } = props;
	const { editAction, deleteAction, disassociateAction } = actionsClick;
	const { t } = useTranslation();

	// PERMISSIONS
	const { hasPermission } = usePermission();
	const canUpdate = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.UPDATE);
	const canDelete = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.DELETE);

	// const rowExpandCell = !arrayIsEmpty(row.adapter_configurators);

	return (
		<>
			<TableRow
				className={clsx({ expanded: open })}
				onClick={() => {
					// if (!rowExpandCell) return void (0);
					onClick(open ? "" : row.id)
				}}
			>
				{showExpandCell &&
					<TableCell sx={{ width: 50 }} size="small">
						{/* {rowExpandCell && ( */}
							<IconButton >
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						{/* )} */}
					</TableCell>
				}

				<TableCell>{row.name}</TableCell>
				<TableCell>
					<ChipStatus
						label={row.is_active ? t("common.active") : t("common.inactive")}
						color={
							row.is_active ? statusColor("active") : statusColor("non-active")
						}
					/>
				</TableCell>
				<TableCell>
					{formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>
				<TableCell>
					{formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>

				<TableCell align={"right"} sx={{ whiteSpace: "nowrap" }}>
					{canUpdate && (
						<IconButton
							title={t("common.edit")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								editAction(true, e, row);
							}}>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t("common.disassociate")}
							aria-label="disassociate"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								disassociateAction(true, e, row);
							}}>
							<LinkOffIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t("common.delete")}
							aria-label="delete"
							disabled={row.clients ? row.clients.length > 1 : true}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								deleteAction(true, e, row);
							}}>
							<DeleteIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			{showExpandCell && (
				<TableRowExpanded>
					<TableCellExpanded colSpan={7}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<GatewaysList moduleID={row.id} extra_params={row.extra_params} />
						</Collapse>
					</TableCellExpanded>
				</TableRowExpanded>
			)}
		</>
	);
}

function GatewaysList(props: { moduleID: string, extra_params: any }) {
	const { t } = useTranslation();
	const {moduleID, extra_params } = props;

		const [page, setPage] = useState(1);

		const itemsPerPage = 6

		const {
			data: adapters,
			loading,
		} = useGet(ServiceApiUrl.adapterConfigurators, {
			module_id: moduleID,
			page: page,
			items: itemsPerPage,
		});

		const handleChangePage = (
			newPage: number
		) => {
			setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
		};

  return (
		<>
      <Grid container rowGap={2} columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
        <RowItem label={t("adapters.extraParams")} value={JSON.stringify(extra_params)} />
      </Grid>
        {/* <Grid container rowGap={2} marginTop={2} columns={{ xs: 12 }}> */}

			<Typography variant="h5" className="bold">
				{t("clients.listDetails.adaptersList")}
			</Typography>

			{loading && <Loading show={loading}/>}

			{arrayIsEmpty(adapters?.data) && !loading && (
				<Typography mt={4} mb={2} variant="body1">
					{t("adapters.noAdapters")}
				</Typography>
			)}

		{!arrayIsEmpty(adapters?.data) && (
			<>
			<TableContainer>
				<Table sx={{ minWidth: 600, marginTop: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell>{t("common.name")}</TableCell>
							<TableCell>{t("common.statusLabel")}</TableCell>
							<TableCell>{t("common.createdAt")}</TableCell>
							<TableCell>{t("common.updatedAt")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{adapters?.data.map((gateway: TAdapterConfigurator) => (
							<TableRow key={gateway.id}>
								<TableCell>{gateway.name}</TableCell>
								<TableCell>
									<ChipStatus
										label={
											gateway.is_active
												? t("common.active")
												: t("common.inactive")
										}
										color={
											gateway.is_active
												? statusColor("active")
												: statusColor("non-active")
										}
									/>
								</TableCell>
								<TableCell>
									{formatDate(
										gateway.created_at,
										t("calendar.dateTimeFormatLocal")
									)}
								</TableCell>
								<TableCell>
									{formatDate(
										gateway.updated_at,
										t("calendar.dateTimeFormatLocal")
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<CustomTablePagination
				count={adapters?.totalCount}
				page={page}
				totalPages={adapters?.totalPages}
				onPageChange={handleChangePage}
				itemsPage={itemsPerPage}
				/>
				</>
			)}
        {/* </Grid> */}
		</>
	);
}

function ModulesListTab({ filters }: IModulesListTab) {
	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const {
		data: modules,
		loading,
		refetch,
	} = useGet(ServiceApiUrl.modules, {
		client: filters.clientID,
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
	});

	useEffect(() => {
		setPage(1);
	}, [filters.clientID]);

	//para fazer nova chamada dos Módulos
	const updateModules = () => {
		refetch();
	};

	const [openRow, setOpenRow] = useState("");
	const { hasPermission } = usePermission();
	const crudPermissions = hasPermission(PERMISSIONS.ADMINISTRATION.CREATE);
	const canDelete = hasPermission(PERMISSIONS.ADMINISTRATION.DELETE);
	const canEdit = hasPermission(PERMISSIONS.ADMINISTRATION.UPDATE);
	const canList = hasPermission(PERMISSIONS.ADMINISTRATION.READ);

	const [selectedModule, setSelectedModule] = useState<TModule>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [openDialogDisassociate, setOpenDialogDisassociate] = useState(false);

	//atualiza o estado do modal de remover
	const handlerDialogDeleteModule = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		module: TModule
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedModule(module);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de desassociar
	const handlerDialogDisassociateModule = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		module: TModule
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedModule(module);
		}
		setOpenDialogDisassociate(!openDialogDisassociate);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditModule = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		module: TModule
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedModule(module);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const [openDialogAddMod, setOpenDialogAddMod] = useState(false);
	const onAddClick = () => {
		setOpenDialogAddMod(true);
	};

	const tableHeads: TableHeadCell[] = [
    {
      id: "expand_cell",
      label: "",
      notSortable: true,
    },
		{
			id: "name",
			label: t("common.name"),
		},
		{
			id: "is_active",
			label: t("common.statusLabel"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "updated_at",
			label: t("common.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<div>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h5" className="bold">
						{t("clients.listDetails.modulesList")}
					</Typography>
				</Grid>
				{crudPermissions && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			{/* <LiveLoading show={loading} /> */}

			{arrayIsEmpty(modules?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("modules.noModules")}
				</Typography>
			)}

			{modules && !arrayIsEmpty(modules?.data) && !loading && (
				<>
					<TableContainer>
						<TableExpanded>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{modules?.data?.map((module: TModule, index: number) => (
									<Row
										key={module.id}
										row={module}
										open={module.id === openRow}
										onClick={(id: string) => setOpenRow(id)}
										actionsClick={{
											editAction: handlerDialogEditModule,
											deleteAction: handlerDialogDeleteModule,
											disassociateAction: handlerDialogDisassociateModule
										}}
									/>
								))}
							</TableBody>
						</TableExpanded>
					</TableContainer>

					<CustomTablePagination
						count={modules?.totalCount}
						page={page}
						totalPages={modules?.totalPages}
						onPageChange={handleChangePage}
					/>
				</>
			)}

			{crudPermissions && openDialogAddMod && (
				<ModulesAdd
					clientID={filters.clientID}
					open={openDialogAddMod}
					onClose={() => setOpenDialogAddMod(false)}
					updateList={updateModules}
				/>
			)}

			{canDelete && selectedModule && openDialogDelete && (
				<ModulesDelete
					module={selectedModule}
					clientID={filters.clientID}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateModules}
				/>
			)}
			{canDelete && selectedModule && openDialogDisassociate && (
				<ModulesDisassociate
					module={selectedModule}
					clientID={filters.clientID}
					open={openDialogDisassociate}
					handlerClose={() => setOpenDialogDisassociate(false)}
					updateList={updateModules}
				/>
			)}
			{canEdit && selectedModule && openDialogEdit && (
				<ModulesEdit
					module={selectedModule}
					clientID={filters.clientID}
					open={openDialogEdit}
					onClose={() => setOpenDialogEdit(false)}
					onSuccess={updateModules}
				/>
			)}
		</div>
	);
}

export default ModulesListTab;
