/* eslint-disable react-hooks/exhaustive-deps */
import {
  DynamicForm,
  FilterSection,
  IDynamicInputTemplate,
  IFormDefinition,
  IRange,
  TypeDynamicInput,
} from "@alb/live-lib";
import { Button, Grid, Typography } from "@mui/material";
import { debounce } from "lodash";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import { stringIsEmpty } from "utils/utils";
import { useStatusOptions } from "./hooks/useStatusOptions";
import useUrgencyOptions from "./hooks/useUrgencyOptions";

export interface IFiltersSection {
  name?: string;
  status?: string | null;
  urgency?: string | null;
  startDateRange?: IRange | undefined;
}
//#NS TODO: passar a interface e a função para um utils q possa ser utilizado nos vários sitios.
// passar para a lib?
interface IColXTemplate extends IDynamicInputTemplate {
  xsCol: "auto" | number | boolean;
}
export const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {
  return (
    <Grid item xs={xsCol}>
      {inputLabel && (
        <Typography gutterBottom variant="h6">
          {inputLabel}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

export const FiltersSection = (props: {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
  setFilterUrgency: React.Dispatch<React.SetStateAction<string>>;
  // setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
}) => {
  const { t } = useTranslation();
  const {
    setPage,
    setFilterSearch,
    setFilterStatus,
    setFilterUrgency,
    // setFilterDateRange,
  } = props;

  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [urgency, setUrgency] = useState<string>("");

  const { statusOptions } = useStatusOptions();
  const { urgencyOptions } = useUrgencyOptions();

  const calculateFiltersCount = useCallback(() => {
    let count = 0;
    if (!stringIsEmpty(search)) count++;
    if (!stringIsEmpty(status)) count++;
    if (!stringIsEmpty(urgency)) count++;
    return count;
  }, [search, status, urgency]);

  const handleChangeUrgency = (
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) => {
    setPage(1);
    //se existir opção selecionada
    if (value && value.value) {
      setUrgency(value.value);
    }
    //se não existir opção selecionada
    else {
      setUrgency("");
    }
  };

  const debounceChangeName = debounce((cb) => {
    cb();
  }, 350);
  const handleChangeName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debounceChangeName(() => {
      setPage(1);
      setSearch(e.target.value);
    });
  };

  const handleChangeStatus = (
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) => {
    setPage(1);
    if (value && value.value) {
      setStatus(value.value);
    } else {
      setStatus("");
    }
  };

  const template = useCallback(
    ({ inputLabel, children }: IDynamicInputTemplate) => {
      return (
        <ColXTemplate
          xsCol={true}
          inputLabel={inputLabel}
          children={children}
        />
      );
    },
    []
  );

  // const predefinedRanges = {
  //   labelLast30Days: t("calendar.last30Days"),
  //   labelLast7Days: t("calendar.last7Days"),
  //   position: "left",
  // };

  // const selectedRangeDates = (date: IRange | undefined) => {
  //   setSelectedRange(date);
  // };

  // const defaultValueFieldDateRange = { startDateRange: undefined };

  useEffect(() => {
    setFilterUrgency(urgency);
  }, [urgency, setFilterUrgency]);

  const dynamicForm = {
    formId: "form-search",
    inputsDefinition: {
      name: {
        inputType: TypeDynamicInput.text,
        label: t("common.search"),
        placeholder: t("common.typeToSearchPlaceholder"),
        defaultValue: "",
        handleOnChange: handleChangeName,
        template: template,
      },
      status: {
        inputType: TypeDynamicInput.autocomplete,
        label: t("occurrences.status"),
        placeholder: t("occurrences.statusPlaceholder"),
        options: statusOptions,
        defaultValue: null,
        handleOnChange: handleChangeStatus,
        noOptionsText: t("common.noOptions"),
        template: template,
      },
      urgency: {
        inputType: TypeDynamicInput.autocomplete,
        label: t("occurrences.urgency"),
        placeholder: t("occurrences.urgencyPlaceholder"),
        options: urgencyOptions,
        defaultValue: null,
        handleOnChange: handleChangeUrgency,
        noOptionsText: t("common.noOptions"),
        template: template,
      },
      // startDateRange: {
      //   inputType: TypeDynamicInput.daterange,
      //   label: t("occurrences.startDate"),
      //   placeholder: t("calendar.placeholderDate"),
      //   defaultValue: defaultValueFieldDateRange.startDateRange,
      //   defaultValueField: defaultValueFieldDateRange,
      //   onSelectedRange: selectedRangeDates,
      //   noOptionsText: t("common.noOptions"),
      //   template: template,
      //   locale: i18n.language,
      //   predefinedRanges: predefinedRanges,
      //   labelBtnCancel: t("cancel"),
      //   labelBtnConfirm: t("apply"),
      //   clearButton: true,
      // },
    },
  };

  const defaultValues: IFiltersSection = {
    name: dynamicForm.inputsDefinition.name.defaultValue,
    status: dynamicForm.inputsDefinition.status.defaultValue,
    urgency: dynamicForm.inputsDefinition.urgency.defaultValue,
    // startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
  };
  const cleanFilters = () => {
    methodsForm.reset();
    setSearch("");
    setStatus("");
    setUrgency("");
    // setSelectedRange(undefined);
  };

  const methodsForm = useForm<IFiltersSection>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    // resolver: yupResolver(getFiltersSchema()),
  });

  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  useEffect(() => {
    setFilterSearch(search);
  }, [search, setFilterSearch]);

  useEffect(() => {
    setFilterStatus(status);
  }, [status, setFilterStatus]);

  // useEffect(() => {
  //   setFilterDateRange(selectedRange);
  // }, [selectedRange, setFilterDateRange]);

  const showClearFilters = () => {
    return calculateFiltersCount() > 1;
  };

  return (
    <>
      <FilterSection
        activeFiltersCount={calculateFiltersCount()}
        filterTitle={t("common.filter")}
      >
        <Grid container gap={2}>
          <Grid item md={12} lg={showClearFilters() ? 10 : 12}>
            <DynamicForm formDefinition={formDefinition} />
          </Grid>
          {showClearFilters() && (
            <Grid
              item
              mb={1}
              xs={true}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button variant="outlined" onClick={cleanFilters}>
                {t("common.cleanFilters")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FilterSection>
    </>
  );
};
