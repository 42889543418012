import { AUTH } from "utils/keys";

import { arrayIsEmpty } from "utils/conditions";
import { adminPermissions } from "utils/permissions/users/adminPermissions";
import { PERMISSIONS } from "utils/permissions/permissions";
import { superAdminPermissions } from "utils/permissions/users/superAdminPermissions";
import { userPermissions } from "utils/permissions/users/userPermissions";

import { TUser } from "types/types";
import useLocalStorage from "./useLocalStorage";
import { IUserPermission } from "types/interfaces";
import { clientsModulesArray } from "utils/permissions/clients/clientsModulesArray";
import { cmAveiroRoles } from "utils/permissions/clients/cmAveiroRoles";
import { cmFunchalRoles } from "utils/permissions/clients/cmFunchalRoles";
import { cmChavesRoles } from "utils/permissions/clients/cmChavesRoles";
import { cmBejaRoles } from "utils/permissions/clients/cmBejaRoles";
import { aveiroDemoRoles } from "utils/permissions/clients/aveiroDemoRoles";

interface IClientsModulesArray {
  name: string;
  roles: any;
  modules: { name: string; key: string }[];
}

//para ir buscar as permissões que dependem do cliente mas que não são módulos (inclui todos os tipos de utilizadores)
export let clientsRoles: { [clientName: string]: number[] } = {
  CMAveiro: cmAveiroRoles,
  CMFunchal: cmFunchalRoles,
  CMChaves: cmChavesRoles,
	CMBeja: cmBejaRoles,
	AveiroDemo: aveiroDemoRoles,
  //...adicionar aqui os clientes todos!
};

const usePermission = () => {
  const { getLocalStorage } = useLocalStorage();

  function getUserPermissions(userLogin: TUser) {
    // permissoes para cada tipo de user
    let permissions = userPermissions;
    if (userLogin.is_admin) permissions = adminPermissions;
    if (userLogin.is_superadmin) permissions = superAdminPermissions;

    const idAdminOrSuperAdmin = userLogin?.is_admin || userLogin?.is_superadmin;

    // permissoes e modulos do cliente default do user
    if (userLogin.client) {
      const userHasModules = !arrayIsEmpty(userLogin?.client.modules);
      const userHasModulesAndPermissions =
        !arrayIsEmpty(userLogin?.client.modules) &&
        !arrayIsEmpty(userLogin?.permission);

      // se um cliente normal tiver valores nos módulos e nas permissões
      if (!idAdminOrSuperAdmin && userHasModulesAndPermissions) {
        permissions = [...permissions, PERMISSIONS.MODULES.READ];
      }
      //se o cliente for admin/super admin e tiver valores nos módulos
      if (idAdminOrSuperAdmin && userHasModules) {
        permissions = [
          ...permissions,
          PERMISSIONS.MODULES.READ,
          ...Object.values(PERMISSIONS.MANAGEMENT.MODULES),
        ];
      }

      //vai buscar o cliente do array associado ao utilizador
      let clientFound = clientsModulesArray(userLogin.client).find(
        (c: IClientsModulesArray) => c.name === userLogin.client.name
      );


      if (clientFound) {
        //se o cliente for igual a um dos clientes do array
        if (userLogin.client.name === clientFound.name) {
          //se for um admin ou superadmin fica com as permissões todas
          if (idAdminOrSuperAdmin) {
            permissions = [...permissions, ...clientFound.roles];
          }
          //se for um user normal, tem de verificar se tem permissões e se o read do value é igual a 1
          else {
            if (userLogin?.permission.length > 0) {
              if (!arrayIsEmpty(userLogin?.permission)) {
                let addPermissions: number[] = [];
                userLogin?.permission.forEach((p: IUserPermission) => {
                  let moduleKey = clientFound?.modules.find(
                    (m: { name: string; key: string }) =>
                      m.name === p.module_name
                  )?.key;
                  if (moduleKey) {
                    addPermissions = checkPermissions(p.value, moduleKey);
                    permissions = [...permissions, ...addPermissions];
                  }
                });
              }
            }
          }
          //vai buscar as permissões do cliente (as permissões que não dependem do tipo de user, nem dos módulos), se existirem no clientsRoles
          if (clientsRoles[clientFound?.name]) {
            permissions.push(...clientsRoles[clientFound?.name]);
          }
        }
      }
    }

    return permissions;
  }

  function hasPermission(permission: number) {
    const auth = getLocalStorage(AUTH);
    return auth?.roles.includes(permission) ? true : false;
  }

  function hasManyPermissions(permissions: number[]) {
    const auth = getLocalStorage(AUTH);
    const checkPermissions = permissions.map((p) => {
      return auth?.roles.includes(p) ? true : false;
    });
    return checkPermissions.includes(false) ? false : true;
  }

  //isto vai ter de servir vários módulos
  function checkPermissions(valuePermi: string, module: string) {
    let permissionsArray: number[] = [];
    let arr: Array<string> = valuePermi.split("");
    arr.map((value: string, index: number) => {
      switch (true) {
        case value === "1" && index === 0:
          permissionsArray = [
            ...permissionsArray,
            (PERMISSIONS.MODULES as any)[module].CREATE,
          ];
          break;
        case value === "1" && index === 1:
          permissionsArray = [
            ...permissionsArray,
            (PERMISSIONS.MODULES as any)[module].READ,
          ];
          break;
        case value === "1" && index === 2:
          permissionsArray = [
            ...permissionsArray,
            (PERMISSIONS.MODULES as any)[module].UPDATE,
          ];
          break;
        case value === "1" && index === 3:
          permissionsArray = [
            ...permissionsArray,
            (PERMISSIONS.MODULES as any)[module].DELETE,
          ];
          break;
        default:
          break;
      }
      return permissionsArray;
    });
    return permissionsArray;
  }

  return {
    getUserPermissions,
    hasPermission,
    hasManyPermissions,
  };
};

export default usePermission;
