import { useTranslation } from "react-i18next";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import ChartWidget, { IChartInfo } from "components/charts/ChartWidget";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";

//component responsible to build all energy charts
const ConsumptionBTWidget = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.EXECUTIVE.READ);
  const user = useSelector(getUser);
  
  const {
    data: consumptionBT,
    loading,
    error
  } = useGet(ServiceApiUrl.energyPTD);

  //transform consumptionBT data
  let consumptionBTData = consumptionBT?.map((e: any) => {
    return { name: e.freguesia, value: Number(e.sum_active_energy / 1000).toFixed(0) };
  });

  let chartLegend = consumptionBT ? t("executive.monthlyConsumptionParish") +
     new Date(consumptionBT[0]?.aggregated_collect_date).toLocaleString(user?.language, {
       month: "long",
     }): "";

  //info to send to chartWidget to build chart
  let consumptionBTInfo: IChartInfo = {
    title: t("executive.consumptionInBT"),
    description: t("executive.energyParish"),
    unit: "MWh",
    data: consumptionBTData,
    legendName: chartLegend,
    hideXAxisLabel: true,
    loading: loading, 
    testId: "consumptionInBTChart",
    error: error
  };


  return (
    <>
      {canREAD && (
        <ChartWidget {...consumptionBTInfo} />
      )}
    </>
  );
};

export default ConsumptionBTWidget;

