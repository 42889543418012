import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { api as axios } from "utils/axios";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IApiResponse } from "@alb/live-lib";
import { INotificationAPI } from "types/interfaces";
import { AUTH_TOKEN, NOTIFICATIONS } from "utils/keys";
import { getLocalStorage, setLocalStorage } from "utils/localStore";
import { getCookie } from "utils/cookie";

const authToken = getCookie(AUTH_TOKEN);

interface INotificationsSlice {
  lastNotifications: IApiResponse<INotificationAPI> | null
	reloadLastNotifications: boolean
}

const initialState: INotificationsSlice = {
  lastNotifications: getLocalStorage<IApiResponse<INotificationAPI> | null>(
    NOTIFICATIONS
  ), // GET FROM LOCALSTORE
	reloadLastNotifications: false
};

export const fetchLastNotifications: any = createAsyncThunk(
"notifications/fetchLastNotifications",
  async () => {
    try {
			//last 5 unread notifications
      const filterParams = { items: 5, read: false};
      const response = await axios.get(ServiceApiUrl.notifications, {
        headers: {
          Authorization: `token ${authToken}`,
        },
        params: {
          ...filterParams,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

interface IFetchReadNotification {
  id: string;
  read: boolean;
	reload?: boolean;
}
export const fetchReadNotification: any = createAsyncThunk(
  "notifications/fetchReadNotification",
  async ({ read, id, reload }: IFetchReadNotification) => {
    try {
      const body = { id: [id], read: read };

      const response = await axios.put(
        ServiceApiUrl.notificationsRead,
        body,
        {
          headers: {
            Authorization: `token ${authToken }`,
          },
        }
      )
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const notificationsSlice: any = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLastNotifications.fulfilled, (state, action) => {
      state.lastNotifications = action.payload;
      setLocalStorage<IApiResponse<INotificationAPI>>(
        NOTIFICATIONS,
        action.payload
      );
			state.reloadLastNotifications = false;
    });
    builder.addCase(fetchLastNotifications.rejected, (state) => {
      state.lastNotifications = null;
    });

    builder.addCase(fetchReadNotification.fulfilled, (state, action) => {
			state.reloadLastNotifications = action.meta.arg.reload;
		});
    builder.addCase(fetchReadNotification.rejected, (state) => {
      state.lastNotifications = getLocalStorage(NOTIFICATIONS);
    });
  },
});

export const getLastNotifications = (state: RootState) =>
  state.notifications.lastNotifications;

export const getReloadLastNotifications = (state: RootState) =>
  state.notifications.reloadLastNotifications;

export default notificationsSlice.reducer;
