import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { TMarker } from "types/types";

import { fetchMarkersOneModule } from "services/MarkersAPI";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { api as axios } from "utils/axios";

import { IFetchMarkersModule } from "./eventsSlice";

interface IOccurrencesSlice {
  occurrences: TMarker[];
  occurrencesStatus: any;
  occurrencesFilterParams: any;
}

const initialState: IOccurrencesSlice = {
  occurrences: [],
  occurrencesStatus: [],
  occurrencesFilterParams: {},
};

export const fetchOccurrences = createAsyncThunk(
  "occurrences/fetchOccurrences",
  async (
    {
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      filterParams: params,
    }: IFetchMarkersModule,
    thunkAPI
  ) => {
    const response = await fetchMarkersOneModule(
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      params
    );
    return response;
  }
);

export const fetchStatus: any = createAsyncThunk(
  "occurrences/fetchOccurrencesStatus",
  async ({ token, id }: any, thunkAPI) => {
    const response = await axios.get(ServiceApiUrl.occurrencesTotalStatus, {
      headers: {
        Authorization: `token ${token}`,
        "MODULE-ID": id,
      },
    });
    return response.data;
  }
);

const occurrencesSlice = createSlice({
  name: "occurrences",
  initialState,
  reducers: {
    setOccurrences(state, action) {
      state.occurrences = action.payload;
    },
    setOccurrencesFilterParams(state, action) {
      state.occurrencesFilterParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOccurrences.fulfilled, (state, action) => {
      state.occurrences = [...action.payload];
    });
    builder.addCase(fetchOccurrences.rejected, (state, action) => {
      state.occurrences = [];
    });
    builder.addCase(fetchStatus.fulfilled, (state, { payload }) => {
      // TO DO: Confirmar a posição dos estados no array
      let items = [];
      let totalOccurences = payload[0].value;
      //como altero o objeto value para colocar
      items.push({ ...payload[0], type: "total" });
      // items.push({ ...payload[2], type: "total" });
      // items.push({ ...payload[3], type: "total" });
      items = [
        ...items,
        ...(payload[1]
          ? [
              {
                name: payload[1]?.name,
                value: `${payload[1]?.value}/${totalOccurences}`,
                type: "total",
              },
            ]
          : []),
        ...(payload[2]
          ? [
              {
                name: payload[2]?.name,
                value: `${payload[2]?.value}/${totalOccurences}`,
                type: "total",
              },
            ]
          : []),
        ...(payload[3]
          ? [
              {
                name: payload[3]?.name,
                value: `${payload[3]?.value}/${totalOccurences}`,
                type: "total",
              },
            ]
          : []),
      ];
      // #NS TODO
      // const items = payload.map((item:any)=> {
      // 	return {...item, type:'total'}
      // })
      state.occurrencesStatus = items;
      // state.occurrencesStatus = [payload[0], payload[1], payload[3], payload[4]]
    });
  },
});

export const {
  setOccurrences,
  setOccurrencesFilterParams,
} = occurrencesSlice.actions;

export const getOccurrences = (state: RootState) =>
  state.occurrences.occurrences;
export const selectOccurrencesStatus = (state: RootState) =>
  state.occurrences.occurrencesStatus;
export const selectOccurrencesFilterParams = (state: RootState) =>
  state.occurrences.occurrencesFilterParams;
export default occurrencesSlice.reducer;
