import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext } from '@alb/live-lib'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Tab } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ISelectOption } from 'types/interfaces'
import { TAdapterConfigurator } from 'types/types'

import FilterSelect from 'components/Utils/FilterSelect'
import Loading from 'components/Utils/Loading'
import NoData from 'components/Utils/NoData'
import { TabPanelContainer } from 'components/Utils/Tab/TabPanelContainer'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { ContainedTabs } from 'styles/css/components'
import { formatDate } from 'utils/date'
import { formatRoutePath } from 'utils/routePath'

import DevicesListTab from './devicesTab/DevicesListTab'
import StreamsListTab from './streamsTab/StreamsListTab'

const AdaptersView = () => {
  let params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { addHeader } = useContext(LayoutContext)
  const { data: adapter, loading, error } = useGet(
    `${ServiceApiUrl.adapterConfigurators}/${params.adaptersId}`
  )

  const handleSelectedAdapter = (option: ISelectOption | null) => {
    if (!option) return undefined;

    const path = formatRoutePath(location, params, {
      adaptersId: option.value,
    });
    navigate(path);
  };

  const header = {
    title: adapter?.name ? adapter?.name : t("adapters.detail"),
    backTo: (
      <BackToButton
        title={t('goBack')}
        onClick={() => navigate('/management/adapters')}
      />
    ),
    select: (adapter &&
      <FilterSelect<TAdapterConfigurator>
        apiUrl={ServiceApiUrl.adapterConfigurators}
        onSelected={handleSelectedAdapter}
        selectedValue={params?.adaptersId}
      />
    ),
  }

  useEffect(() => {
    addHeader(header)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter?.name])

  let deviceView = <NoData error={error} />
  let adaptersDevices = <NoData error={error} />
  let adaptersStreams = <NoData error={error} />

  if (loading) {
    deviceView = <Loading />
    adaptersDevices = <Loading />
    adaptersStreams = <Loading />
  }

  if (adapter) {
    deviceView = (
      <EntityInfo title={t('common.details')}>
        <EntityInfoItem label={t('adapters.name')} value={adapter.name} />
        <EntityInfoItem
          label={t('adapters.status')}
          value={adapter.is_active ? t('common.active') : t('common.inactive')}
        />
        <EntityInfoItem
          label={t('adapters.description')}
          value={adapter.description}
        />
        <EntityInfoItem
          label={t('adapters.protectedIngestion')}
          value={
            adapter.protected_ingestion
              ? t('common.active')
              : t('common.inactive')
          }
        />
        <EntityInfoItem
          label={t('adapters.devoDomain')}
          value={adapter.domain}
        />
        <EntityInfoItem label={t('adapters.domain')} value={adapter.domain} />
        <EntityInfoItem
          label={t('adapters.devoClient')}
          value={adapter.partner.name}
        />

        <EntityInfoItem label={t('adapters.client')} value={adapter?.name} />

        {/* <EntityInfoItem
          label={t("adapters.adapterURL")}
          value={adapter.gateway_url}
        />
        <EntityInfoItem
          label={t("adapters.adapterUser")}
          value={adapter.gateway_user}
        /> */}
        <EntityInfoItem
          label={t('adapters.createdAt')}
          value={formatDate(
            adapter.created_at,
            t('calendar.dateTimeFullFormat')
          )}
        />
        <EntityInfoItem
          label={t('adapters.updatedAt')}
          value={formatDate(
            adapter.updated_at,
            t('calendar.dateTimeFullFormat')
          )}
        />
      </EntityInfo>
    )

    adaptersDevices = <DevicesListTab filters={{ id: adapter.id }} />
    adaptersStreams = <StreamsListTab filters={{ id: adapter.id }} />
  }

  const [value, setValue] = React.useState('1')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {deviceView}

     {adapter &&
      <Box mt={2}>
        <TabContext value={value}>
          <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label={t('adapters.devices')} value="1" />
            <Tab label={t('adapters.streams')} value="2" />
          </ContainedTabs>

          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
            <TabPanelContainer>{adaptersDevices}</TabPanelContainer>
          </TabPanel>
          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
            <TabPanelContainer>{adaptersStreams}</TabPanelContainer>
          </TabPanel>
        </TabContext>
      </Box>
      }
    </>
  )
}

export default AdaptersView
