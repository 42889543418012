function objectIsEmpty(obj: Object | null | undefined): boolean {
  const length = Object.keys(obj || {}).length;
  return length === 0;
}

function arrayIsEmpty<T>(array: T | null | undefined): boolean {
  return !array || !Array.isArray(array) || !array.length
}

function stringIsEmpty(string: string | null | undefined): boolean {
  const length =string?.length;
  return length === 0;
}

export {
  objectIsEmpty,
  arrayIsEmpty,
  stringIsEmpty, 
};
