import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IDialogProps } from 'types/interfaces'
import { TEvent } from 'types/types'

import { EventEdit } from 'components/events/EventEdit'
import { getUser } from 'store/slices/authSlice'
import { getMarkerDetails, selectDetailsError, selectDetailsLoading, selectMarkerDetails, TypeMarkerInfo } from 'store/slices/mapSlice'
import { formatDate } from 'utils/date'
import { formattedLocation } from 'utils/utils'

import LiveDialogTitle from './DialogTitle'
import MapEntiyInfoItem from './MapEntiyInfoItem'

interface INewComponentProps extends IDialogProps {
  onSuccess?: () => void
  publicPage?: boolean | false
}

const EventInfo = ({
  open,
  onClose,
  onSuccess,
  publicPage,
}: INewComponentProps) => {
  const { t } = useTranslation()

  const data = useSelector(selectMarkerDetails)
  const loading = useSelector(selectDetailsLoading)
  const error = useSelector(selectDetailsError)
  const dispatch = useDispatch()
  const user = useSelector(getUser);

  const [openEdit, setOpenEdit] = useState(false)

  const onShowEdit = () => {
    setOpenEdit(true)
  }

  const handleCloseEdit = () => {
    
    setOpenEdit(false)
  }
  const handleEditSuccess = (event: TEvent) => {
    handleCloseEdit()
    onSuccess && onSuccess()
    // update
    dispatch(getMarkerDetails(event.id, 'event' as TypeMarkerInfo, user))
  }

  return (
    <>
      <Dialog maxWidth="xs" fullWidth open={open}>
        {data && !loading && !error.isError ? (
          <>
            <LiveDialogTitle onClose={onClose}>
              <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography fontWeight={700} fontSize={18}>
                  {data.name}
                </Typography>
                <Typography marginLeft={1} variant="body1">
                  {data.status.name}
                </Typography>
              </Grid>
            </LiveDialogTitle>
            <DialogContent sx={{ pt: 0 }}>
              <Grid container>
                {/* <MapEntiyInfoItem label={'Cliente'} value={data.client.name} /> */}
                <MapEntiyInfoItem label={t('events.type')} value={data.type.name} />
                <MapEntiyInfoItem
                  label={t('events.description')}
                  value={data.description}
                />
                <MapEntiyInfoItem
                  label={t('events.category')}
                  value={data.category.name}
                />

                <MapEntiyInfoItem
                  label={
                    data.type === 'expected'
                      ? t('events.startDate')
                      : t('events.unexpectedStartDate')
                  }
                  value={formatDate(
                    data.start_date,
                    t('calendar.dateTimeFullFormat')
                  )}
                />
                <MapEntiyInfoItem
                  label={
                    data.type === 'expected'
                      ? t('events.endDate')
                      : t('events.unexpectedEndDate')
                  }
                  value={formatDate(
                    data.end_date,
                    t('calendar.dateTimeFullFormat')
                  )}
                />

                <MapEntiyInfoItem
                  label={t('events.location')}
                  value={formattedLocation(data.geolocation.iconPosition)}
                />
                <MapEntiyInfoItem
                  label={t('events.eventURL')}
                  value={data.url}
                />
                {data.notes && (
                  <MapEntiyInfoItem
                    label={t('events.notes')}
                    value={data.notes}
                  />
                )}
              </Grid>
            </DialogContent>
            {!publicPage && (
              <DialogActions>
                <Button variant="outlined" color="primary" onClick={onShowEdit}>
                  {t('common.edit')}
                </Button>
              </DialogActions>
            )}
          </>
        ) : (
          <LiveDialogTitle onClose={onClose}>
            <Typography fontWeight={700} fontSize={18}>
              {loading && t('labelLoading')}
              {error.isError && !loading && t('errors.noAction')}
            </Typography>
          </LiveDialogTitle>
        )}
      </Dialog>

      {openEdit && data && !publicPage && (
        <EventEdit
          open={openEdit}
          data={data}
          onClose={handleCloseEdit}
          onSuccess={handleEditSuccess}
        />
      )}
    </>
  )
}

export default EventInfo
