import { Loading } from "@alb/live-lib";
import { AxiosError } from "axios";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";

import NoData from "components/Utils/NoData";
import { getNameClient, getUser } from "store/slices/authSlice";
import { arrayIsEmpty } from "utils/conditions";
// import geoData from "../../../assets/funchal.json";
import { formatNumber } from "utils/number";
import { useTheme } from "@mui/material";

interface IChartFunchal {
  data: { name: string; value: number }[];
  loading: boolean;
  error: AxiosError<any, any> | null;
}

const ChartFunchal = ({ data, loading, error }: IChartFunchal) => {
  const nameClient = useSelector(getNameClient);
  const themeMode = useSelector(getUser)?.extra_params?.dark_mode;
  const theme = useTheme();

  const option = {
    tooltip: {
      confine: true,
      extraCssText: "white-space:inherit;",
      trigger: "item",
      formatter: function (value: any) {
        return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br />`;
      },
    },
    geo: {
      select: "disabled",
      // silent:true,
      map: nameClient?.toLowerCase(),
      roam: false,
      // itemStyle: { //desta forma já não é utilizada
      //   emphasis: "disabled",
      // },
      emphasis: {
        itemStyle: {
          color: null,
        },
        label: {
          show: false,
        },
      },
    },
    visualMap: {
      left: "right",
      top: "20px",
      type: "piecewise",
      min: 0,
      max: data ? Math.max(...data.map((v: any) => v.value), 0) : 0,
      seriesIndex: 0,
      itemWidth: 22,
      itemHeight: 22,
      itemGap: 2,
      hoverLink: false,
      inRange: {
        color: [
          "#51bfbc",
          "#4eb5b4",
          "#4babab",
          "#47a1a3",
          "#44979b",
          "#418d92",
          "#3e848a",
          "#3a7a82",
          "#377079",
          "#346671",
          "#315c69",
          "#2d5260",
          "#2a4858",
        ],
      },
      textStyle: {
        color: themeMode ? theme.palette.common.white : "#333333",
        fontFamily: "Altice",
        fontSize: 13,
      },
      calculable: true,
      formatter: function (value: any, value2: any) {
        return formatNumber(value) + " - " + formatNumber(value2);
      },
    },
    series: [
      {
        nodeClick: false,
        name: "occurrences",
        type: "map",
        geoIndex: 0,
        data: data,
      },
    ],
  };

  const geoFunchal: any = require(`assets/${nameClient?.toLowerCase()}.json`);
  echarts.registerMap(nameClient?.toLowerCase(), geoFunchal);

  const checkData = (data: any) => {
    let haveValues = 0;
    if (data) {
      for (const value of Object.values(data) as any) {
        haveValues += value.value;
      }
    }
    return haveValues === 0;
  };

  return (
    <>
      <Loading show={loading} />
      {!arrayIsEmpty(data) && !checkData(data) && !loading && (
        <ReactEcharts style={{ height: "537px" }} option={option} />
      )}
      {checkData(data) && !loading && <NoData error={error} />}
    </>
  );
};

export default ChartFunchal;
