import { IDialogProps } from "types/interfaces";
import DeviceInfo from "./DeviceInfo";
import EventInfo from "./EventInfo";
import OccurrenceInfo from "./OccurrenceInfo";

const lookupComponent = {
  device: DeviceInfo,
  event: EventInfo,
  occurrence: OccurrenceInfo
}

const MarkerInfoComponent = (props: { type: string, dialogProps: IDialogProps, onSuccess?: () => void, publicPage?: boolean}) => {
  const Component = lookupComponent[props.type as keyof typeof lookupComponent];

  const handleCloseEdit = () => {
    props.onSuccess && props.onSuccess()
  };
  
  return (
    <Component {...props.dialogProps} onSuccess={handleCloseEdit} publicPage={props.publicPage}/>
  )
}

export default MarkerInfoComponent;