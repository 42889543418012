/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TUser } from "types/types";

import useGet from "hooks/fetchData/useGet";
import useMarkers from "hooks/useMarkers";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDevicesFilterParams, setDevices } from "store/slices/adapterConfiguratorsSlice";
import { getUser } from "store/slices/authSlice";
import { getMapBounds, getSelectedMarkerType, getShapeBounds } from "store/slices/mapSlice";
import { clientPublicPortal, IClientPublicPortal } from "utils/clientsPublicPortal";
import { arrayIsEmpty } from "utils/utils";

import { MarkersTypeOptions } from "../components/map/controls/FilterMapTab/utils/GetOptions";

const useFetchMarkers = (
  publicAPI: boolean = false
) => {
  const dispatch = useDispatch();
  const { addFeedback } = useFeedback();
  let mapDeviceParams = useSelector(selectDevicesFilterParams);
  const mapBounds = useSelector(getMapBounds);
  const shapeBounds = useSelector(getShapeBounds);
  const location = useLocation();
  const userRedux = useSelector(getUser);
  const user = !publicAPI
    ? userRedux
    : (clientPublicPortal(location.pathname) as TUser | IClientPublicPortal);
  const { getEventsData, getOccurrencesData, getMarkersModulesClient } =
    useMarkers();
  const { t } = useTranslation();
  const selectedType = useSelector(getSelectedMarkerType);

  //se existirem formas no mapa, enviar os bounds da forma no params do pedido!
  let deviceParams: any = {
    points: !arrayIsEmpty(shapeBounds)
      ? JSON.stringify(shapeBounds)
      : JSON.stringify(mapBounds),
    ...(mapDeviceParams !== undefined && { ...mapDeviceParams }),
  };
  const { refetch: refetchDevices } = useGet(
    ServiceApiUrl.devices,
    deviceParams,
    {
      manual: true,
    }
  );
  //pedido dos devices
  async function fetchDevicesMarkers(filterApplied?: boolean) {
    await refetchDevices()
      .then((res) => {
        const response = res.data.data;
        dispatch(setDevices(response));
        filterApplied && success();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const success = () => {
    addFeedback({
      message: t("feedback.success.appliedFilters"),
      severity: "success",
      duration: 1500,
    });
  };

  async function fetchAllMarkers() {
    switch (selectedType) {
      case MarkersTypeOptions.devices:
        fetchDevicesMarkers();
        break;
      case MarkersTypeOptions.events:
        getEventsData(user, publicAPI);
        break;
      case MarkersTypeOptions.occurrences:
        getOccurrencesData(user, publicAPI);
        break;
      case MarkersTypeOptions.visits:
        // TODO adicionar pedido visitas
        break;
      default:
        if (!publicAPI) {
          fetchDevicesMarkers();
        }
        getMarkersModulesClient(user, publicAPI);
    }
  }

  return {
    fetchDevicesMarkers,
    fetchAllMarkers,
  };
};

export default useFetchMarkers;
