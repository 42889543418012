/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import ReactEcharts from "echarts-for-react";
import {
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { arrayIsEmpty } from "utils/conditions";
import { formatNumber } from "utils/number";
import { useTheme } from "@mui/material";
import { TDevice } from "types/types";
import { Loading } from "@alb/live-lib";
import NoData from "components/Utils/NoData";
import useDidMountEffect from "hooks/useDidMountEffect";

interface IWeatherChart {
  deviceSelected: TDevice;
  adapterId: string;
  selectedStream: any;
  dateRange: { startDate: string; endDate: string };
}

const WeatherChart = ({
  deviceSelected,
  adapterId,
  selectedStream,
  dateRange,
}: IWeatherChart) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const themeMode = useSelector(getUser)?.extra_params?.dark_mode;

  const [dataChart, setDataChart] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({});

  const { loading, refetch, error } = useGet(
    `${ServiceApiUrl.adapterConfigurators}/${adapterId}/values`,
    null,
    {
      manual: true,
    }
  );

  const sendRequestStreamsChart = async (params: any) => {
    const res = await refetch({
      params: { ...params },
    });
    setDataChart(res.data.object || []);
  };

  useDidMountEffect(() => {
    if (deviceSelected) {
      const params = {
        external_id: deviceSelected?.external_id,
        from: dateRange?.startDate,
        to: dateRange?.endDate,
        streams: selectedStream?.streamName,
      };
      sendRequestStreamsChart(params);
    }
  }, [deviceSelected, selectedStream]);

  function addChartOptions() {
    let arrayValues = dataChart?.map((e: any) => {
      return {
        name: format(new Date(e.collect_date), "HH:mm"),
        value: e[selectedStream?.streamName],
      };
    });
    const unit = selectedStream?.unit;
    const data = arrayValues;
    const legendName = selectedStream
      ? t("streams." + selectedStream?.streamName?.toLowerCase())
      : "";

    const color = theme.palette.primary;
    var option = {
      tooltip: {
        confine: true,
        extraCssText: "white-space:inherit;",
        trigger: "axis",
        formatter: function (value: any) {
          return `
        ${value[0].axisValueLabel}<br/>
        ${value[0].marker} ${value[0].seriesName}:  ${formatNumber(
            value[0].value
          )} ${unit} <br/>`;
        },
      },
      textStyle: {
        fontFamily: "Altice",
        fontSize: 13,
      },
      xAxis: {
        type: "category",
        data: data?.map(
          (d: { name: string; value: string | number }) => d.name
        ),
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
          }
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
      grid: {
        left: "2%",
        right: "5%",
        containLabel: true,
      },
      color: color["main"],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (val: any) => formatNumber(val),
        },
        name: selectedStream?.unit,
        nameTextStyle: {
          padding: [0, 30, 0, 0],
        },
        axisLine: {
          lineStyle: {
            color: theme.palette.mode === "light" ? "" : theme.palette.common.white
          }
        }
      },
      legend: {
        show: false,
        orient: "horizontal",
        bottom: 0,
        textStyle: {
          color: themeMode ? theme.palette.common.white: "#333333",
          fontFamily: "Altice",
          fontSize: 13,
        },
        itemHeight: 16,
        itemWidth: 16,
        icon: "circle",
        itemGap: 25,
        left: 0,
      },
      series: [
        {
          showSymbol: false,
          name: legendName,
          data: data,
          type: "line",
        },
      ],
    };
    setChartOptions(option);
  }
  useEffect(() => {
    if (dataChart) {
      addChartOptions();
    }
  }, [dataChart, dataChart.length]);

  return (
    <>
      {<Loading show={loading} />}
      {!loading && arrayIsEmpty(dataChart) && <NoData error={error}/>}
      {chartOptions && !arrayIsEmpty(dataChart) && !loading && (
        <ReactEcharts style={{ height: "308px" }} option={chartOptions} />
      )}
    </>
  );
};

export default WeatherChart;
