import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ChartFunchal from 'components/occurrences/charts/ChartFunchal'
import useGet from 'hooks/fetchData/useGet'
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { formatDate } from 'utils/date'
import { MODULES_KEYS } from 'utils/modules/modulesKeys'

import ChartCategory from './charts/OccurrencesCategoriesChart'
import OccurrencesStatusChart from './charts/OccurrencesStatusChart'
import OccurrencesWeekChart from './charts/OccurrencesWeekChart'

export const OccurrencesCardsView = (props: { needToRefetch?: boolean, selectedRange: any } ) => {
	const { needToRefetch, selectedRange } = props
	const { t } = useTranslation()
	const { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const { data, loading, error, refetch: refetchOccurrences } = useGet(
		ServiceApiUrl.occurrencesAllParishes,
		getParams(),
		undefined,
		headersOCurrences
	)

	function getParams() {
        let params = undefined;
        if (selectedRange?.startDate || selectedRange?.endDate) {
          params = {
            start_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
          };
        }
        return params;
      }

	useEffect(() => {
		if(needToRefetch) {
			refetchOccurrences()
		}
			// eslint-disable-next-line
	}, [needToRefetch])



	return (
		<>

			<Grid
				mb={3}
				container
				columnSpacing={5}
				rowSpacing={3}
				mt={4}
				justifyContent="space-between"
				sx={{ pt: '35px' }}
			>
				<Grid item xs={12} sm={6}>
					<Card data-testid={"OccurrencesNumberChart"}>
						<CardContent>
							<Typography variant="h3" fontSize={'22px'}>
								{t('occurrences.occurrencesNumber')}
							</Typography>
							<ChartFunchal data={data} loading={loading} error={error} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card data-testid={"OccurrencesByCategoryChart"}>
						<CardContent>
							<Typography variant="h3" fontSize={'22px'}>
								{t('occurrences.occurrencesByCategory')}
							</Typography>
							<ChartCategory selectedRange={selectedRange} needToRefetch={needToRefetch} />
						</CardContent>
					</Card>
				</Grid>
				{
					/*<Grid item xs={12} sm={6}>
						<Card>
							<CardContent>
								<Typography variant="h3" fontSize={'22px'}>
									{t('occurrences.occurrencesByParishes')}
								</Typography>
								<ChartOccurrencesParishes data={data} loading={loading} error={error} />
							</CardContent>
						</Card>
					</Grid>*/
				}
				<Grid item xs={12} sm={6}>
					<Card data-testid={"OccurrencesByStatusChart"}>
						<CardContent>
							<Typography variant="h3" fontSize={'22px'}>
								{t('occurrences.occurrencesByStatus')}
							</Typography>
							<OccurrencesStatusChart  selectedRange={selectedRange} needTorefetch={needToRefetch}/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Card data-testid={"OccurrencesPerWeekChart"}> 
						<CardContent>
							<Typography variant="h3" fontSize={'22px'}>
								{t('occurrences.occurrencesPerWeek')}
							</Typography>
							<OccurrencesWeekChart selectedRange={selectedRange} needToRefetch={needToRefetch} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</>
	)
}
