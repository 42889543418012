import { TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import useGet from "hooks/fetchData/useGet";
import { TAdapterConfigurator } from "types/types";
import { ChipStatus } from "components/Utils/ChipStatus";
import { statusColor } from "utils/utils";
import { formatDate } from "utils/date";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/conditions";
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";
import Table from "@mui/material/Table";
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
interface IAdaptersListTab {
  filters: { id: string };
}

function Row(props: { row: TAdapterConfigurator }) {
  const { row } = props;
  const { t } = useTranslation();
  return (
    <>
      <TableRow >
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>
          {formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
      </TableRow>
    </>
  );
}

function AdaptersListTab({ filters }: IAdaptersListTab) {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canRead = hasPermission(PERMISSIONS.MANAGEMENT.ACCOUNTS.READ);

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: adapters,
    loading,
  } = useGet(ServiceApiUrl.adapterConfigurators, {
    account_id: filters.id,
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "created_at",
      label: t("common.createdAt"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
          <Typography variant="h5" className="bold">
            {t("accounts.adapters")}
          </Typography>
        </Grid>
      </Grid>

      {arrayIsEmpty(adapters?.data) && !loading && canRead && (
        <Typography mt={4} mb={2} variant="body1">
          {t("adapters.noAdapters")}
        </Typography>
      )}

      {adapters && !arrayIsEmpty(adapters?.data) && !loading && (
        <>
          <TableContainer>
            <Table>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {adapters?.data?.map(
                  (adapter: TAdapterConfigurator, index: number) => (
                    <Row key={adapter.id} row={adapter} />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

					<CustomTablePagination
						count={adapters?.totalCount}
						page={page}
						totalPages={adapters?.totalPages}
						onPageChange={handleChangePage}
					/>
        </>
      )}
    </div>
  );
}

export default AdaptersListTab;
