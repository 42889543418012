import { Typography, Box, Stack, Tooltip } from "@mui/material";
import { debounce } from "lodash";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

interface IColorRangeLegend {
  colors: string[];
  maxValue: undefined | number;
  minValue: undefined | number;
}

export const ColorRangeLegend = ({
  colors,
  maxValue,
  minValue,
}: IColorRangeLegend) => {
  const { t } = useTranslation();

  const [hoverValue, setHoverValue] = useState<number>();

  const debounce_ = debounce((cb) => {
    cb();
  }, 10);

  let difBetweenMaxMin = maxValue && minValue && maxValue - minValue; //diferença entre o mínimo e o máximo

  const handleMouseMove = (e: MouseEvent) => {
    //calcula o valor no hover
    let cursorHoverPosition = e.nativeEvent.offsetX; //posição do cursor no range
    let boxWidth = (e.target as HTMLElement).clientWidth; //tamanho total do range

    var value: number = 0;
    debounce_(() => {
      if (difBetweenMaxMin && maxValue && minValue)
        value = (difBetweenMaxMin * cursorHoverPosition) / boxWidth + Number(minValue);
      setHoverValue(Number(value?.toFixed(2)));
    });
  };

  return (
    <>
      <Tooltip
        followCursor
        placement={"top"}
        title={hoverValue + " " + t("map.legend.visits")}
      >
        <Box sx={{ m: 1 }}>
          <Box
            onMouseMove={handleMouseMove}
            sx={{
              width: "100%",
              borderRadius: "6px",
              height: 25,
              background: `linear-gradient(to right, ${colors})`,
            }}
          />
        </Box>
      </Tooltip>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="caption">{minValue}</Typography>
        <Typography variant="caption">{maxValue}</Typography>
      </Stack>
    </>
  );
};
