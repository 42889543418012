import { IconButton, Link, TableCell, TableRow } from "@mui/material";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useTranslation } from "react-i18next";
import Checkbox from '@mui/material/Checkbox';

// import usePermission from "hooks/usePermission";
// import { PERMISSIONS } from "utils/permissions/permissions";

import { INotificationAPI } from "types/interfaces";
import useUpdate from "hooks/fetchData/useUpdate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { LoadingButton } from "@mui/lab";
import { NavLink } from "react-router-dom";

export interface INotificationRow extends INotificationAPI {
	icon: React.ReactElement,
	link: string,
	title: string,
}
interface INotificationsTableRow {
	row: INotificationRow;
	onRowClick: (event: React.MouseEvent<unknown>, notification: INotificationAPI) => void;
	onReadClick: (row: INotificationAPI) => void;
	selected: boolean;
}

const NotificationsTableRow = (props: INotificationsTableRow) => {
	const { row, onRowClick, onReadClick, selected } = props;
	const { t } = useTranslation();
	// const { hasPermission } = usePermission();
	// const canEdit = hasPermission(PERMISSIONS.MODULES.NOTIFICATIONS.UPDATE);

	const labelId = `row-checkbox-${row.id}`;

	const { loading, refetch: readNotification } = useUpdate(
		ServiceApiUrl.notificationsRead
	);

	const handleRowActionClick = async (event: React.MouseEvent<unknown>) => {
		try {
			await readNotification({ data: { id: [row.id], read: !row.read } }).then((response) => {
				onReadClick(row);
			});
		} catch (error) { console.log(error) }
	}

	const handleRowClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (row.read) return
		onRowClick(event, row);
	}

	return (
		<>
			<TableRow
				selected={selected}
			>
				<TableCell>
					<Checkbox
						color="primary"
						checked={selected}
						disabled={row.read}
						onClick={handleRowClick}
						inputProps={{
							'aria-labelledby': labelId,
						}}
					/>
				</TableCell>
				<TableCell>{row.icon}</TableCell>
				<TableCell>
					{row.link && <Link underline="none" component={NavLink} to={row.link} >{row.title}</Link>}
					{!row.link && row.title }
				</TableCell>

				<TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
					{/* {canEdit && */}
					{loading && <LoadingButton loading={loading} />}
					{
						<IconButton
							title={row.read ? t("common.markAsUnread") : t("common.markAsRead")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								e.stopPropagation();
								handleRowActionClick(e);
							}}
						>
							{!loading && !row.read && <MarkEmailUnreadIcon color="primary" />}
							{!loading && row.read && <DraftsIcon />}
						</IconButton>
					}
				</TableCell>
			</TableRow>
		</>
	);
};

export default NotificationsTableRow;
