import { IRange, Loading } from "@alb/live-lib";
import { Box, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { YAXisComponentOption } from "echarts";
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApiResponse, IHydrogenSupply } from "types/interfaces";

import { defaultOptions } from "components/charts/defaultOptions";
import { IReactECharts, ReactECharts as StackedLineChart } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { GridWhiteContainer } from "styles/css/components";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { objectIsEmpty } from "utils/utils";

import StreamsCharts from "./StreamsCharts"

interface ICharts {
  deviceName: string;
  filterDateRange?: IRange | undefined;
}

const Charts = ({
  deviceName,
  filterDateRange
}: ICharts) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [dataChart, setDataChart] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({});
  const [streamsChart, setStreamsCharts] = useState<any[]>([]);
  const [ loadingChartOptions, setLoadingChartOptions ] = useState<boolean>(true);
  //guarda as streams que estão em cada eixo no gráfico
  const [streamsAxis, setStreamsAxis] = useState<any>({
    streamsChartLeftAxis: [],
    streamsChartRightAxis: [],
  });
  //guarda as streams selecionadas nos selects
  const [selectedItemsAxis, setSelectedItemsAxis] =
    useState<any>({
      leftAxisSelectedItems: [],
      rightAxisSelectedItems: [],
    });

  const getParams = () => {
    var params: {
      //   page: number;
      //   items: number;
      //   order_by: string;
      start_date?: string | undefined
      end_date?: string | undefined
    } = {
      //   page: page,
      //   items: ROWS_PER_PAGE,
      //   order_by: formatOrderBy(order, orderBy),
      ...(filterDateRange && {
        start_date: formatDate(
          filterDateRange?.startDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
        end_date: formatDate(
          filterDateRange?.endDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
      }),
    };
    return params;
  };

  const {
    loading,
    error,
    refetch
  } = useGet<IApiResponse<IHydrogenSupply>>(
    ServiceApiUrl.hydrogenList(deviceName),
    null,
    {
      manual: true,
    }
  );
  useEffect(() => {
    setLoadingChartOptions(false)
  }, [error])
  
  const sendRequestStreamsChart = async (params: any) => {
    const res = await refetch({
      params: { ...params },
    });
    if(arrayIsEmpty(res.data.data))
      setLoadingChartOptions(false)
    setDataChart(res.data.data || []);
  };

  useEffect(() => {
    if (deviceName) {
      setDataChart([])
      setStreamsCharts([])
      setStreamsAxis({ ...streamsAxis, streamsChartRightAxis: [] });
      setSelectedItemsAxis({ ...selectedItemsAxis, rightAxisSelectedItems: [] });
      setChartOptions({})
      setLoadingChartOptions(true)
      sendRequestStreamsChart(getParams());
    }
  }, [deviceName, filterDateRange]);

  useEffect(() => {
    if (dataChart && dataChart.length > 0) {
      //vou criar um array que só vai criar opções consoante os objetos que existem
      let tableHeads: any[] = [];

      Object.keys(dataChart[0]).forEach(function (key) {
        //campos a ignorar e não mostrar no grafico, para já
        if (key === "production_id") return;
        if (key === "supply_id") return;
        if (key === "local_id") return;
        if (key === "supply_time") return;
        if (key === "local") return;
        if (key === "tank_capacity_l") return;
        if (key === "date") return;
        if (key === "supply_time_minutes") return;

        let obj = {
          id: key,
          label: t(`hydrogenSupply.table.header.${key}`),
          unit: t(`hydrogenSupply.table.unit.${key}`)
        };
        return (tableHeads = [...tableHeads, obj]);
      })
      setStreamsCharts(tableHeads)
      //o primeiro eixo por defeito já vai ter uma stream selecionada, a primeira
      setSelectedItemsAxis({
        ...selectedItemsAxis,
        leftAxisSelectedItems: [tableHeads[0].id],
      });
      setStreamsAxis({ ...streamsAxis, streamsChartLeftAxis: [tableHeads[0]] });
      //  addChartOptions();
    }
  }, [dataChart, dataChart.length]);

  //quando o dataChart ou alguma das streams alterar, recarrega o gráfico
  useEffect(() => {
    if (
      streamsAxis.streamsChartLeftAxis.length > 0 ||
      streamsAxis.streamsChartRightAxis.length > 0
    ) {
      addChartOptions();
    }
  }, [
    dataChart,
    dataChart.length,
    streamsAxis.streamsChartLeftAxis,
    streamsAxis.streamsChartRightAxis,
  ]);

  useEffect(() => {
    if(!objectIsEmpty(chartOptions))
      setLoadingChartOptions(false)
  }, [chartOptions])
 

  //controla o valor selecionado no select
  const handleStateSelectChange = (
    event: SelectChangeEvent<string[]>,
    setOpenSelect: Dispatch<SetStateAction<boolean>>,
    axisSide: string
  ) => {
    let items: string = "";
    let axis: string = "";
    let axisChartStreams: any[] = [];

    if (axisSide === "left") {
      items = Object.keys(selectedItemsAxis)[0];
      axis = Object.keys(streamsAxis)[0];
      axisChartStreams = streamsAxis.streamsChartLeftAxis;
    } else if (axisSide === "right") {
      items = Object.keys(selectedItemsAxis)[1];
      axis = Object.keys(streamsAxis)[1];
      axisChartStreams = streamsAxis.streamsChartRightAxis;
    }
    if (items !== "" && axis !== "") {
      setSelectedItemsAxis({ ...selectedItemsAxis, [items]: [] });

      let selectedStreams: any[] | undefined = [];
      //se não existirem valores selecionados, coloca os array dos valores para o gráfico, vazio
      if (event.target.value.length === 0) {
        setOpenSelect(false); //fecha o select

        setStreamsAxis({ ...streamsAxis, [axis]: [] });
        return;
      }

      //para obter o conteudo completo das streams, e não só o id
      selectedStreams = streamsChart?.filter((s: any) =>
        event.target.value.includes(s.id)
      );

      //se só existir uma stream no gráfico
      if (axisChartStreams.length === 1) {
        //ao selecionar uma nova stream vai verificar se as unidades das duas streams são diferentes.
        let streamWithDiffUnit = selectedStreams?.find(
          (s: any) => s.unit !== axisChartStreams[0].unit
        );
        //se existir uma diferente stream com unidade diferente, coloca a que foi adicionada em segundo, troca
        if (streamWithDiffUnit && selectedStreams) {
          setStreamsAxis({
            ...streamsAxis,
            [axis]: [streamWithDiffUnit as any],
          }); //coloca no gráfico esta stream, substituindo a outra
          setSelectedItemsAxis({
            ...selectedItemsAxis,
            [items]: [streamWithDiffUnit.id as string],
          }); //seleciona no select
        }
        //se nao existirem diferentes
        else {
          //seleciona a que o utilizador escolheu e adiciona ao gráfico
          if (selectedStreams)
            setStreamsAxis({
              ...streamsAxis,
              [axis]: selectedStreams as any[],
            });

          setSelectedItemsAxis({
            ...selectedItemsAxis,
            [items]: event.target.value as string[],
          });
        }
      } else {
        //se já houver mais que uma stream no gráfico, seleciona as que o utilizador escolheu e adiciona-as
        //a ação de adicionar mais do que uma unidade diferente está protegida pelo disabled no menuItems no select
        if (selectedStreams)
          setStreamsAxis({
            ...streamsAxis,
            [axis]: selectedStreams as any[],
          });
        setSelectedItemsAxis({
          ...selectedItemsAxis,
          [items]: event.target.value as string[],
        });
      }
    }
  };

  //função que vai adicionar os dados e as opções ao gráfico
  const addChartOptions = useCallback(() => {
    let seriesArray: any[] = [];
    let mapYAxisValues: any = [];
    let mapXAxisValues: string | string[] = [];
    if (
      dataChart.length > 0
    ) {
      mapXAxisValues = dataChart.map((v: any) =>
        formatDate(v.date, t("calendar.dateTimeFormatLocal"))
      );

      //se existirem streams selecionadas no primeiro select
      if (streamsAxis.streamsChartLeftAxis.length > 0) {
        //vai adicionar ao array dos dados, as streams do primeiro select
        streamsAxis.streamsChartLeftAxis.forEach((element: any) => {
          seriesArray = [
            ...seriesArray,
            {
              name: element?.label,
              data: dataChart.map((s) => s[element?.id]),
              type: "line",
              lineStyle: { width: 1 },
              showSymbol: false,
              step: "",
              areaStyle: null,
            },
          ];
        });

        //vai adicionar o eixo da primeira stream
        mapYAxisValues = [
          {
            name: `${streamsAxis.streamsChartLeftAxis[0].unit}`,
            position: "left",
            type: "value",
            nameLocation: "end",
            nameTextStyle: {
              padding: [0, 30, 0, 0],
            },
            max: dataChart ? (Math.max(...dataChart.map((s) => s[streamsAxis.streamsChartLeftAxis[0].id])) + 2) : undefined,
            min: dataChart ? (Math.min(...dataChart.map((s) => s[streamsAxis.streamsChartLeftAxis[0].id])) - 2) : undefined,
            // axisLabel: {
            //   formatter: `{value}`,
            // },
            axisLabel: {
              formatter: (val: any) => formatNumber(val),
            },
            splitLine: {
              lineStyle: {
                color: "#F0F0F0",
              },
            },
            axisLine: {
              lineStyle: {
                color: theme.palette.mode === "light" ? "" : theme.palette.common.white
              }
            }
          },
        ];
      }

      //se existirem streams selecionadas no segundo select
      if (streamsAxis.streamsChartRightAxis.length > 0) {
        //vai adicionar ao array dos dados, as streams do segundo select
        streamsAxis.streamsChartRightAxis.forEach((element: any) => {
          seriesArray = [
            ...seriesArray,
            {
              name: element?.label,
              yAxisIndex: streamsAxis.streamsChartLeftAxis.length > 0 ? 1 : 0,
              data: dataChart.map((s) => s[element?.id]),
              type: "line",
              showSymbol: false,
              step: "",
              areaStyle: null,
            },
          ];
        });
        //caso existam streams no segundo select, gera o novo eixo
        let YAxisAux: YAXisComponentOption | YAXisComponentOption[];
        YAxisAux = {
          name: `${streamsAxis.streamsChartRightAxis[0].unit}`,
          position: "right",
          nameLocation: "end",
          nameTextStyle: {
            padding: [0, 0, 0, 30],
          },
          type: "value",
          axisLabel: {
            formatter: (val: any) => formatNumber(val) as string,
          },
          max: dataChart ? (Math.max(...dataChart.map((s) => s[streamsAxis.streamsChartRightAxis[0].id])) + 2) : undefined,
            min: dataChart ? (Math.min(...dataChart.map((s) => s[streamsAxis.streamsChartRightAxis[0].id])) - 2) : undefined,
        };
        //adiciona o novo eixo ao gráfico
        mapYAxisValues = [...mapYAxisValues, YAxisAux];
      }

      //cria as opções do gráfico
      const options: IReactECharts["option"] = {
        ...defaultOptions,
        xAxis: {
          ...defaultOptions.xAxis, ...{
            data: mapXAxisValues,
            axisLine: {
              lineStyle: {
                color: theme.palette.mode === "light" ? "" : theme.palette.common.white
              },
            },
          }
        },
        yAxis: mapYAxisValues,
        series: seriesArray,
        legend: {
          ...defaultOptions.legend,
          ...{
            textStyle: {
              color: theme.palette.text.primary,
              fontFamily: "Altice",
              fontSize: "12px",
            },
          },
        },
        tooltip: {
          ...defaultOptions.tooltip,
          ...{
            trigger: "axis",
            formatter: "",
          },
        },
      };
      // //adiciona as opções ao gráfico
      setChartOptions(options);
    }
  }, [
    dataChart,
    streamsAxis.streamsChartLeftAxis,
    streamsAxis.streamsChartRightAxis,
  ]);

  return (
    <>
      <Box mt={4} py={6} data-testid="hydrogenSupplyChart">
        <GridWhiteContainer container item xs={false} sm={12} md={12} mt={3}>
        {(loading || (loadingChartOptions && !loading && !arrayIsEmpty(dataChart))) && (
            <Loading show={true} />
        )}

        { arrayIsEmpty(dataChart) && !loading &&  !loadingChartOptions && (
            <NoData error={error} />
          )}

      {chartOptions && !arrayIsEmpty(dataChart) && !loading && !loadingChartOptions && (
            <>
              {<StreamsCharts
                allStreams={streamsChart}
                streamsAxis={streamsAxis}
                setStreamsAxis={setStreamsAxis}
                selectedItemsAxis={selectedItemsAxis}
                setSelectedItemsAxis={setSelectedItemsAxis}
                handleStateSelectChange={handleStateSelectChange}
              />}

              {chartOptions &&
                (streamsAxis?.streamsChartLeftAxis?.length > 0 ||
                  streamsAxis?.streamsChartRightAxis?.length > 0) ? (
                <StackedLineChart option={chartOptions} />
              ) : (
                <Typography mt={4} variant={"body1"}>
                  {t("dashboard.selectAtLeastOneStream")}
                </Typography>
              )}
            </>
         

      )}
        </GridWhiteContainer>
      </Box>
    </>
  );
};

export default Charts;
