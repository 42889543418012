import { TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Button, Collapse, Grid, IconButton, Stack, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IUserPermission } from "types/interfaces";
import { TModule, TUser } from "types/types";

import { ChipStatus } from 'components/Utils/ChipStatus'
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from 'store/slices/authSlice'
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/css/components";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from 'utils/date'
import { formatOrderBy } from "utils/orderByAPI";
import { PERMISSIONS } from "utils/permissions/permissions";
import RowItem from "utils/Table/RowItem";
import { statusColor } from 'utils/utils'

import UserInvite from "./UserInvite";
import UserPermissions from "./UserPermissions";
import UsersAdd from "./UsersAdd";
import UsersDelete from "./UsersDelete";
import UsersEdit from "./UsersEdit";
import PersonOffIcon from '@mui/icons-material/PersonOff';

interface IUsersListTab {
	filters: { clientID: string };
}

function Row(props: {
	row: TUser;
	open: boolean;
	onClick: Function;
	actionsClick: {
		editAction: Function;
		deleteAction: Function;
		inviteAction: Function;
	};
}) {
	const { row, open, onClick, actionsClick } = props;
	const { editAction, deleteAction, inviteAction } = actionsClick;
	const { t } = useTranslation();
	const user = useSelector(getUser);

	// PERMISSIONS
	const { hasPermission } = usePermission();
	const canUpdate = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.UPDATE);
	const canDelete = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.DELETE);
	const canInvite = user.is_superadmin || user.is_admin;
	const idAdminOrSuperAdmin = user?.is_admin || user?.is_superadmin;

	// const showExpandCell = !arrayIsEmpty(row.adapter_configurators);
	const showExpandCell = true

	return (
		<>
			<TableRow
				className={clsx({ "no-pointer": !showExpandCell, expanded: open })}
				onClick={() => {
					if (!showExpandCell) return void (0);
					onClick(open ? "" : row.id)
				}}
			>
				<TableCell sx={{ width: 50 }} size="small">
					{showExpandCell && (
						<IconButton>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</TableCell>

				<TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
				<TableCell>
					<Stack direction="row" alignItems="flex-end" gap={1}>
						{row.username}
						{(row.is_superadmin || row.is_admin) && (
							<SupervisorAccountIcon
								fontSize="small"
								color={row.is_superadmin ? "primary" : "inherit"}
								titleAccess={
									row.is_superadmin
										? t("user.superAdmin")
										: row.is_admin
											? t("user.admin")
											: ""
								}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>
					<ChipStatus
						label={t(`user.status.${row.status}`)}
						color={statusColor(row.status)}
					/>
				</TableCell>
				{idAdminOrSuperAdmin && (
          			<TableCell>
           				 <Stack direction="row" alignItems="flex-end" gap={1}>
             				 {row?.account ? row?.account?.name : <PersonOffIcon
               				 fontSize="small"
                			 titleAccess={ t("user.noAccountUser")} />}
            			</Stack>
        			  </TableCell>
        		)}
				<TableCell>
					{formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>
				<TableCell>
					{formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>

				<TableCell align={"right"} sx={{ whiteSpace: "nowrap" }}>
					{canUpdate && (
						<IconButton
							title={t("common.edit")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								editAction(true, e, row);
							}}
							disabled={row.status !== "deleted" ? false : true}>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t("common.delete")}
							aria-label="delete"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								deleteAction(true, e, row);
							}}
							disabled={row.status !== "deleted" ? false : true}>
							<DeleteIcon />
						</IconButton>
					)}
					{canInvite && (
						<IconButton
							title={t("user.reinviteUser")}
							onClick={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => inviteAction(true, e, row)}
							disabled={!row.last_pwd_update || row.status === "deleted" ? false : true}>
							<EmailRoundedIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			{showExpandCell && (
				<TableRowExpanded>
					<TableCellExpanded colSpan={9}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<UserInformation user={row} />
						</Collapse>
					</TableCellExpanded>
				</TableRowExpanded>
			)}
		</>
	);
}

function UserInformation(props: { user: TUser }) {
	const { user } = props;
	const { t } = useTranslation();

	return (
		<>
			<Grid container rowGap={2} columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
				<RowItem label={t("user.Username")} value={user.username} fitContent />
				<RowItem label={t("user.email")} value={user.email} fitContent />
				<RowItem
					label={t("user.admin")}
					value={user.is_admin ? t("user.yes") : t("user.no")}
				/>
				<RowItem
					label={t("user.superAdmin")}
					value={user.is_superadmin ? t("user.yes") : t("user.no")}
				/>
				<RowItem label={t("user.language")} value={user.language} />
				{/* <UserLanguage language={user.language} /> */}
				{/* </RowItem> */}
				<RowItem label={t("accounts.account")} value={user.account?.name} />
			</Grid>
			{!user.is_superadmin && !user.is_admin && user.permission && (
				<Grid container rowGap={2} marginTop={2} columns={{ xs: 12 }}>
					<UserPermissions permissions={user.permission} />
				</Grid>
			)}
		</>
	);
}

function UsersListTab({ filters }: IUsersListTab) {
	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("full_name");

	const user = useSelector(getUser)
	const idAdminOrSuperAdmin = user?.is_admin || user?.is_superadmin;

	const {
		data: users,
		loading,
		refetch,
	} = useGet(ServiceApiUrl.user, getParams());

	function orderByAPI() {
		let _orderBy = formatOrderBy(order, orderBy);
		if (orderBy === "full_name") {
			_orderBy = ["first_name", "last_name"]
				.map((o) => (order === "desc" ? "-" + o : o))
				.join(",");
		}
		return _orderBy;
	}
	function getParams() {
		var params: {
			client: string,
			page: number;
			items: number;
			order_by: string;
		} = {
			client: filters.clientID,
			page: page,
			items: ROWS_PER_PAGE,
			order_by: orderByAPI()
		}

		return params;
	}

	useEffect(() => {
		setPage(1);
	}, [filters.clientID]);

	//para fazer nova chamada dos Módulos
	const updateUsers = () => {
		refetch();
	};

	const [openRow, setOpenRow] = useState("");
	// PERMISSIONS
	const { hasPermission } = usePermission();
	const crudPermissions = hasPermission(PERMISSIONS.ADMINISTRATION.CREATE);
	const canList = hasPermission(PERMISSIONS.ADMINISTRATION.READ);

	const { data: allModules } = useGet(
		ServiceApiUrl.modules,
		{ client: filters.clientID }
	);
	const [permissions, setPermissions] = useState<IUserPermission[]>([]);
	useEffect(() => {
		if (allModules) {
			const perm = allModules?.data.map((m: TModule) => ({
				module_name: m.name,
				module_id: m.id,
				value: "0000",
			}));
			setPermissions(perm);
		}
	}, [allModules]);
	const [selectedUser, setSelectedUser] = useState<TUser>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [openDialogInvite, setOpenDialogInvite] = useState(false);

	//atualiza o estado do modal de remover
	const handlerDialogDeleteUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	//reconvidar utilizador
	const handlerDialogInvite = (open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedUser(user); //troca o user pelo escolhido
		}
		setOpenDialogInvite(!openDialogInvite);
	}

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const [openDialogAddMod, setOpenDialogAddMod] = useState(false);
	const onAddClick = () => {
		setOpenDialogAddMod(true);
	};

	const tableHeads: TableHeadCell[] = [
		{
			id: "expand_cell",
			label: "",
			notSortable: true,
		},
		{
			id: "full_name",
			label: t("common.name"),
		},
		{
			id: "username",
			label: t("user.Username"),
		},
		{
			id: "status",
			label: t("common.statusLabel"),
		},
		...(idAdminOrSuperAdmin
			? [{ id: "account", label: t("user.account") }]
			: []),
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "last_login",
			label: t("common.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<div>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h5" className="bold">
						{t("clients.listDetails.usersList")}
					</Typography>
				</Grid>
				{crudPermissions && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			{/* <LiveLoading show={loading} /> */}

			{arrayIsEmpty(users?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("user.noUsers")}
				</Typography>
			)}

			{users && !arrayIsEmpty(users?.data) && !loading && (
				<>
					<TableContainer>
						<TableExpanded>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{users?.data?.map((user: TUser, index: number) => (
									<Row
										key={user.id}
										row={user}
										open={user.id === openRow}
										onClick={(id: string) => setOpenRow(id)}
										actionsClick={{
											editAction: handlerDialogEditUser,
											deleteAction: handlerDialogDeleteUser,
											inviteAction: handlerDialogInvite
										}}
									/>
								))}
							</TableBody>
						</TableExpanded>
					</TableContainer>

					<CustomTablePagination
						count={users?.totalCount}
						page={page}
						totalPages={users?.totalPages}
						onPageChange={handleChangePage}
					/>
				</>
			)}

			{crudPermissions && openDialogAddMod && (
				<UsersAdd
					allModules={allModules}
					administration
					permissions={permissions}
					clientID={filters.clientID}
					open={openDialogAddMod}
					onClose={() => setOpenDialogAddMod(false)}
					updateList={updateUsers}
				/>
			)}

			{selectedUser && openDialogDelete && (
				<UsersDelete
					user={selectedUser}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateUsers}
				/>
			)}
			{selectedUser && openDialogEdit && (
				<UsersEdit
					user={selectedUser}
					administration
					clientID={filters.clientID}
					open={openDialogEdit}
					onClose={() => setOpenDialogEdit(false)}
					updateList={updateUsers}
				/>
			)}
			{selectedUser && openDialogInvite && (
				<UserInvite
					user={selectedUser}
					open={openDialogInvite}
					handlerClose={handlerDialogInvite}
					updateGetUsers={updateUsers}
				/>
			)}
		</div>
	);
}

export default UsersListTab;
