/* eslint-disable react-hooks/exhaustive-deps */
import { InputDateTime, InputSelect, InputText, Loading, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
	FormGroup,
	Grid,
	IconButton,
	InputAdornment,
	Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IEventForm } from "types/interfaces";
import {
	selectCoords,
	selectDrawingMap,
	setDrawingMap,
	setDrawnCoords,
} from "store/slices/mapSlice";
import { arrayIsEmpty } from "utils/conditions";

import { formDefaultValues } from "./formDefaultValues";
import { getEventFormSchema } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";
import { useStatusOptions } from "./hooks/useStatusOptions";
import { useTypeOptions } from "./hooks/useTypeOptions";
import { useCategoriesOptions } from "./hooks/useCategoriesOptions";

const EventForm = (props: {
	formId: string;
	data?: IEventForm;
	onFormSubmit: (payLoad: IEventForm, location: any) => void;
	setFormIsValidStatus?: (status: boolean) => void;
	mapAction: { action: 'cancel' | 'confirm' } | undefined;
}) => {
	const { formId, data, onFormSubmit, setFormIsValidStatus, mapAction } = props;

	const { t, i18n } = useTranslation();
	const coords = useSelector(selectCoords);
	const defaultValues = formDefaultValues(data);
	const dispatch = useDispatch();
	const drawingMap = useSelector(selectDrawingMap);

	const methodsForm = useForm<IEventForm>({
		defaultValues: defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
		resolver: yupResolver(getEventFormSchema()),
	});
	const { control, handleSubmit, resetField, getValues, trigger, watch,
		formState: { errors: filterErrors, dirtyFields } } = methodsForm;

	const [selectedDates, setSelectedDates] = useState({
		start_date: undefined,
		end_date: undefined,
	});

	const { statusOptions: stateOptions, loading: loadingStatusOptions } = useStatusOptions();
	const { categoriesOptions, loading: loadingCategoriesOptions } = useCategoriesOptions();
	const { typeOptions, loading: loadingTypesOptions } = useTypeOptions();


	//guarda o valor final da localização
	const [location, setLocation] = useState<string | any>("");

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IEventForm> = (
		payLoad: IEventForm
	) => {
		onFormSubmit(payLoad, location);
	};

	//para enviar as coordenadas iniciais para serem desenhadas, se for um edit
	useEffect(() => {
		if (data) {
			setLocation(data.geolocation);
			dispatch(setDrawnCoords(data.geolocation));
		}

	}, []);

	useEffect(() => {
		if (mapAction) {
			trigger("geolocation");
		}
	}, [mapAction])

	useEffect(() => {
		let _location = location.coordinates
			? JSON.stringify(location.coordinates)
			: "";
		resetField("geolocation", {
			defaultValue: _location
		});
	}, [location]);

	//sempre que for criada uma shape, deve colocar o valor no input, pelo menos temporariamente, até dar check
	useEffect(() => {
		if (coords) {
			setLocation(coords || "");
		} else {
			// if (drawingMap) {
			setLocation("");
			// }
		}
	}, [coords]);

	const handleDateOnChange = (
		date: Date | null,
		positionDate: string
	) => {
		setSelectedDates({
			...selectedDates,
			[positionDate]: date,
		});
	};

	const watchStartDate = watch("start_date");
	const watchEndDate = watch("end_date");
	useEffect(() => {
		// o touchedfield não está a funcionar com o input date time :(...
		// if (!touchedFields.start_date) return;
		if (dirtyFields.end_date) {
			trigger('start_date'); // re validate field
			trigger('end_date'); // re validate field
		}
		// trigger('end_date'); // re validate field
		setTimeout(() => {
			if (!filterErrors.start_date && !filterErrors.end_date) {
				handleDateOnChange(watchStartDate, "start_date");
			}
		}, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchStartDate])
	useEffect(() => {
		if (dirtyFields.start_date) {
			trigger('start_date'); // re validate field
			trigger('end_date');
		}
		// trigger('start_date'); // re validate field
		setTimeout(() => {
			if (!filterErrors.end_date) {
				handleDateOnChange(watchEndDate, "end_date");
			}
		}, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchEndDate])

	//Sempre que os methodsForm.valid alterar, envia os dados
	useEffect(() => {
		if (setFormIsValidStatus)
			setFormIsValidStatus(methodsForm.formState.isValid);
	}, [methodsForm.formState.isValid]);

	// const prevDrawingMapRef = useRef<boolean>(false);
	// useEffect(() => {
	// 	prevDrawingMapRef.current = drawingMap;

	// 	return () => {
	// 		if (prevDrawingMapRef.current) {
	// 			trigger("geolocation");
	// 		}
	// 		prevDrawingMapRef.current = false;
	// 	}
	// }, [drawingMap])

	//ao selecionar para abrir, troca o mapa
	function changeMap() {
		const inputLocation = getValues("geolocation");
		if (coords && inputLocation) {
			const newCoords = { ...coords, coordinates: JSON.parse(inputLocation) };

			dispatch(setDrawnCoords(newCoords));
		} else {
			dispatch(setDrawnCoords(null));
		}
		// ****************
		dispatch(setDrawingMap(!drawingMap)); //troca o mapa
	}


	const locationInputProps = {
		classes: { inputMultiline: 'pointer' },
		readOnly: true,
		onFocus: changeMap,
	}

	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<FormGroup>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<RequiredField title={t("events.name")} />
								<InputText
									placeholder={t("events.namePlaceholder")}
									name="name"
									type="text"
									control={control}
								/>
							</Grid>
							<Grid item xs={6}>
								<RequiredField title={t("events.type")} />
								{typeOptions && !arrayIsEmpty(typeOptions) && (
									<InputSelect
										placeholder={t("events.typePlaceholder")}
										name="type"
										control={control}
										options={typeOptions}
									/>
								)}
								{<Loading show={loadingTypesOptions} />}
							</Grid>
							<Grid item xs={6}>
								<Typography gutterBottom variant="h6">
									{t("events.description")}
								</Typography>
								<InputText
									placeholder={t("events.descriptionPlaceholder")}
									name="description"
									type="text"
									multiline
									rows={2}
									control={control}
								/>
							</Grid>
							<Grid item xs={6}>
								<RequiredField title={t("events.category")} />
								{categoriesOptions && !arrayIsEmpty(categoriesOptions) && (
									<InputSelect
										placeholder={t("events.categoryPlaceholder")}
										name="category"
										control={control}
										options={categoriesOptions}
									/>
								)}
								{<Loading show={loadingCategoriesOptions} />}
							</Grid>
							<Grid item xs={6}>
								<RequiredField
									title={t("events.startDate")}
								/>
								<InputDateTime
									name="start_date"
									control={control}
									maxDate={
										selectedDates?.end_date
											? new Date(selectedDates.end_date)
											: undefined
									}
									handleOnChange={(date: Date | null) => {
										handleDateOnChange(date, "start_date");
									}}
									locale={i18n.language}
									labelTextTime={t("calendar.time")}
									showTimeInput
									labelBtnCancel={t("cancel")}
									labelBtnConfirm={t("apply")}
									placeholder={t("calendar.placeholderDateTime")}
								/>
							</Grid>
							<Grid item xs={6}>
								<Typography gutterBottom variant="h6">
									{t("events.endDate")}
								</Typography>
								<InputDateTime
									name="end_date"
									control={control}
									minDate={
										selectedDates?.start_date
											? new Date(selectedDates.start_date)
											: undefined
									}
									handleOnChange={(date: Date | null) => {
										handleDateOnChange(date, "end_date");
									}}
									locale={i18n.language}
									labelTextTime={t("calendar.time")}
									showTimeInput
									labelBtnCancel={t("cancel")}
									labelBtnConfirm={t("apply")}
									placeholder={t("calendar.placeholderDateTime")}
									clearButton
								/>
							</Grid>

							<Grid item xs={6}>
								<RequiredField
									title={t("events.location")}
									sx={{
										verticalAlign: "middle",
										display: "inline-flex",
										gap: 0.5,
									}}
								>
									<Tooltip
										placement="right"
										title={t("events.chooseToSelectOnMap")}
									>
										<HelpOutlineIcon sx={{ fontSize: 20 }} color="primary" />
									</Tooltip>
								</RequiredField>
								<InputText
									multiline
									minRows={2}
									maxRows={5}
									disabled={drawingMap}
									placeholder={t("events.locationPlaceholder")}
									name="geolocation"
									type="text"
									control={control}
									{...(!drawingMap && {
										endAdornment: (
											<InputAdornment position="end">
												<Tooltip title={t("events.selectLocationOnMap")}>
													<IconButton onClick={changeMap} sx={{ padding: 0 }}>
														<LocationSearchingIcon />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										),
									})}
									InputProps={locationInputProps}
								/>
							</Grid>

							<Grid item xs={6}>
								<RequiredField title={t("events.status")} />
								{stateOptions && !arrayIsEmpty(stateOptions) && (
									<InputSelect
										placeholder={t("events.statusPlaceholder")}
										name="status"
										control={control}
										options={stateOptions}
									/>
								)}
								{<Loading show={loadingStatusOptions} />}
							</Grid>
							<Grid item xs={6}>
								<Typography gutterBottom variant="h6">
									{t("events.eventURL")}
								</Typography>
								<InputText
									placeholder={t("events.eventURLPlaceholder")}
									name="url"
									type="text"
									control={control}
								/>
							</Grid>

							<Grid item xs={6}>
								<Typography gutterBottom variant="h6">
									{t("events.notes")}
								</Typography>
								<InputText
								 placeholder={t("events.notesPlaceholder")}
									name="notes"
									type="text"
									control={control}
									multiline
									rows={2}
								/>
							</Grid>
						</Grid>
					</FormGroup>
				</form>
			</FormProvider>
			<RequiredFields />
		</>
	);
};

export default EventForm;
