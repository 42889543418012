import { AppLogo, IMapPoint } from '@alb/live-lib'
import { LocationOnRounded } from '@mui/icons-material'
import { Box, Grid, Link, ListItem, ListItemButton, Typography, useTheme } from '@mui/material'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getHighlightedListMarker } from 'store/slices/mapSlice'
import { formatDate } from 'utils/date'

import { getIcons } from './listIcons'

interface IItem {
  items: IMapPoint[]
  index: number
  style: any
  onClick: (item: IMapPoint) => void
  onHover: (item: IMapPoint | null) => void
}

export const ListItemRow = ({
  items,
  index,
  style,
  onClick,
  onHover,
}: IItem) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const item = items[index]
  const latLng = L.latLng(item.geolocation.iconPosition)
  const icon = getIcons(item.type, item.subType, item.is_active, item.last_read_value?.park_type, item.last_read_value?.park_occupied,  item.icon)
  const highlightedMarker = useSelector(getHighlightedListMarker)
  const theme = useTheme();
  
  const handleClick = (item: IMapPoint) => {
    if (item.type === 'device') {
      return navigate(`/dashboard/${item.id}`)
    }
    onClick(item)
  }

  const onMouseEnter = (e: any) => {
    const point = { ...item, selected: true }
    if (
      point.id !== highlightedMarker?.id ||
      (point.id === highlightedMarker?.id &&
        point.selected !== highlightedMarker?.selected)
    ) {
      onHover(point)
    }
  }
  const onMouseLeave = (e: any) => {
    const point = { ...item, selected: false }
    onHover(point)
  }

  return (
    <ListItem
      sx={{ margin: 0 }}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        sx={{ width: '100%' }}
        color="text.primary"
        underline="none"
        onClick={() => handleClick(item)}
      >
        <ListItemButton>
          <Grid py={1} container>
            <Grid item xs={2}>
              <AppLogo
                sx={{ width: 70, height: 'auto' }}
                variant="circular"
                src={icon}
                alt={item.subType ? i18n.exists(`domains.${item.subType}`) ? t(`domains.${item.subType}`) : item.subType : item.type}
              />
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography color="inherit" variant="h6">
                {item.title ? item.title : i18n.exists(`domains.${item.subType}`) ? t(`domains.${item.subType}`) : item.subType }
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {item.title && <Typography color="text.secondary" variant="caption">
                  {/* {item.subType && t(`domains.${item.subType}`)} */}
									{i18n.exists(`domains.${item.subType}`) ? t(`domains.${item.subType}`) : item.subType}
                </Typography>}
                {item.status ? (
                  <Typography color="text.secondary" variant="subtitle2">
                    {item.status}
                  </Typography>
                ) : (
                  <Typography
                    color={
                      item.is_active
                        ? theme.palette.success.main
                        : theme.palette.error.main
                    }
                    variant="subtitle2"
                  >
                    {item.is_active ? t('common.active') : t('common.inactive')}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
                  <LocationOnRounded
                    color="primary"
                    sx={{
                      fontSize: '18px',
                    }}
                  />
                  <Typography color="text.secondary" variant="caption">
                    {latLng.lat.toFixed(4)}, {latLng.lng.toFixed(4)}
                  </Typography>
                </Box>
                <Typography color="text.secondary" fontSize={'0.7rem'}>
                  {item.date &&
                    formatDate(item.date[0], t('calendar.dateTimeFormatLocal'))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

