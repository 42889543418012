/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import ChartWidget, { IChartInfo } from "components/charts/ChartWidget";
import { getUser } from "store/slices/authSlice";
import { useSelector } from "react-redux";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useTheme } from "@mui/material";

const PublicBuildingsWidget = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.EXECUTIVE.READ);
  const user = useSelector(getUser);
  const theme = useTheme();

  const {
    data: publicBuildings,
    loading,
    error
  } = useGet(ServiceApiUrl.energyConsumption);

  //transform publicBuildings data
  let publicBuildingsData = publicBuildings?.map((e: any) => {
    const month = new Date(e.aggregated_collect_date).toLocaleString(
      user?.language,
      { month: "long" }
    );
    return {
      name: month.charAt(0).toUpperCase() + month.slice(1),
      value: Number(e.sum_active_power / 1000).toFixed(0),
    };
  });

  //info to send to chartWidget to build chart
  const publicBuildingsInfo: IChartInfo = {
    title: t("executive.publicBuildings"),
    description: t("executive.sum"),
    unit: "MWh",
    data: publicBuildingsData,
    color: theme.palette.secondary,
    legendName: t("executive.monthlyConsumption"),
    loading: loading,
    testId: "publicBuildingsChart",
    error: error
  };

  return (
    <>
      {canREAD && (
            <ChartWidget {...publicBuildingsInfo} />
      )}
    </>
  );
};

export default PublicBuildingsWidget;
