import { Grid, Typography } from '@mui/material';
import React from 'react'

import { valueEmptyTo } from 'utils/utils';

const MapEntiyInfoItem = (props: { label?: string, value?: string, icon?: React.ReactNode }) => {
  const { icon, label, value } = props;

  let mL = 0;
  if (icon && label) { mL = 0.5; }

 return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-top' }}>
      {icon}
      {label && <Typography marginLeft={mL} marginRight={1} fontSize="small" variant="h6">{label}:</Typography>}
      <Typography fontSize="small" variant="caption">
        {valueEmptyTo(value)}
      </Typography>
    </Grid>
  )
}

export default MapEntiyInfoItem
