/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "@alb/live-lib";
import { Typography, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { toLower } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatNumber } from "utils/number";

interface chartData {
  name: string,
  data: number[]
}

const ParkingBarChart = () => {

    const theme = useTheme()
    const { t } = useTranslation();

    const { headers } = useGetModuleHeaders(MODULES_KEYS.PARKING);

    const { data: avgTimeData, loading, error } = useGet(
      ServiceApiUrl.avgParkingTime,
      undefined,
      undefined,
      headers
      )

    const [ categories, setCategories ] = useState<string[] | undefined>();
    const [ seriesData, setSeriesData ] = useState<{data: number[], name:string}[] | undefined>()

    useEffect(() => {
      if(avgTimeData) {
        let keys = Object.keys(avgTimeData[0]);
        let categories = keys.map(k => t(`parking.${toLower(k)}`))
        setCategories(categories);

        let occupiedData: chartData = {
          name: t('parking.occupied'),
          data: []
        };
        let notOccupiedData: chartData = {
          name: t('parking.free'),
          data: []
        };
        Object.entries(avgTimeData[0]).forEach((e: any) => {
          occupiedData.data.push(e[1].avg_time_park_occupied_h)
          notOccupiedData.data.push(e[1].avg_time_park_not_occupied_h)
        })

        setSeriesData([occupiedData, notOccupiedData])

      }

    }, [avgTimeData])

    const option = {
      tooltip: {
        confine: true,
        extraCssText: 'white-space:inherit;',
        trigger: "item",
        formatter: function (value: any) {
          return `<b>${value.seriesName}</b>: ${formatNumber(value.data)}h<br/>`;
        },
      },
      labelLine: {
        show: false,
      },
      textStyle: {
        fontFamily: "Altice",
        fontSize: 13,
      },
      legend: {
        //data: chartdata.categories,
        bottom: 0,
        itemGap: 40,
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: theme.palette.mode === "light" ? "" : theme.palette.common.white
          }
        },
        inverse: true,
        max: categories ? categories.length -1 : 0,
              axisLabel: {
                  formatter: (val:any) => formatNumber(val),

              },
        data: categories,
      },
      grid: {
        containLabel: true,
        bottom: '10%', // Adjust the bottom position of the grid to create space for the xAxis name
      },
      xAxis: {
        name: '(h)',
        nameLocation: 'end',
        nameGap: 35,
        type: 'value',
        max: "dataMax",
        axisLabel: {
          formatter: (val:any) => formatNumber(val)
        },
        axisLine: {
          lineStyle: {
              color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
            }
        },
      },
      series: seriesData ? seriesData?.map((series: chartData, index: number) => {
        return({
          name: series.name,
          type: 'bar',
          data: series.data,
          barGap: 0,
          itemStyle: {
            color: index === 0 ? theme.palette.primary.main : theme.palette.secondary.main
          },
          label: {
            show: true,
            position: 'right',
          },
        })
      }) : undefined,
    };

    return(<>
          <Typography variant="h3" fontSize={'22px'}>
            {t('parking.occupationAVGTime')}
					</Typography>

          {!loading && !avgTimeData && <NoData error={error}/>}
          <Loading show={loading} />

          {!loading && avgTimeData && option &&
          <>
          <Typography color="text.secondary" variant="caption">{t("parking.last24hValues")}</Typography>
          <ReactEcharts style={{ height: "480px"}} option={option}/>
          </>
}
    </>)
}

export default ParkingBarChart;
