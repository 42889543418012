import L from "leaflet";
import { formatNumber } from "./number";

function objectIsEmpty(obj: Object | null | undefined): boolean {
  const length = Object.keys(obj || {}).length;
  return length === 0;
}
function arrayIsEmpty<T>(array: T | null | undefined): boolean {
  return !array || !Array.isArray(array) || !array.length;
}
function stringIsEmpty(string: string | null | undefined): boolean {
  const length = string?.length;
  return length === 0;
}

function valueEmptyTo(value?: string | number, to = "--") {
  const _value = value ? value : to;
  return _value;
}

function stringIsJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

type StatusColorOptions =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
interface IStatusColor {
  invited: string;
  "non-active": string;
  active: string;
  deleted: string;
}
function statusColor(status_value: string) {
  const status: IStatusColor = {
    invited: "warning",
    "non-active": "error",
    active: "success",
    deleted: "error",
  };
  return status[status_value as keyof IStatusColor] as StatusColorOptions;
}
function formattedLocation(position: [number, number]) {
  const _latLng = L.latLng(position);

  return `${_latLng.lat.toFixed(5)}, ${_latLng.lng.toFixed(5)}`;
}

function objectHasEmptyArrays(obj: any | null | undefined): boolean {
  if (obj) {
    return Object.keys(obj).every(function (key) {
      var val = obj[key];
      if (Array.isArray(val) && val.length === 0) {
        return true;
      }
      return false;
    });
  } else {
    return true;
  }
}

function arrayHasEmptyArrays(arr: any[]): boolean {
  let hasEmpty = false;
  arr.forEach((e) => {
    if (arrayIsEmpty(e)) {
      hasEmpty = true;
      return;
    }
  });
  return hasEmpty;
}

function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous;
}

// generate number ranges for MapLegend
function getRangesNumbers([min, max]: [number, number], length: number) {
  return Array.from(
    { length },
    (
      (t, f) => (_, i) =>
        [t, (t = ++i === length ? max : min + f * i)]
    )(min, (max - min) / length)
  );
}

// get id for client with visits
function getIdClientVisits(nameClient: string) {
  switch (nameClient) {
    case "AveiroDemo":
      return "0105";
    case "CMChaves":
      return "1703";
    case "CMBeja":
      return "0205";
  }
}

// Count the number of appearences of a certain char on a string
function countCharAppearences(char: string, str: String) {
  let count = 0;

  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === char) {
      count++;
    }
  }
  return count;
}

function isBoolean(val: any) {
  return "boolean" === typeof val;
}

function formatStream(stream: {
  value: string | number;
  unit: string;
}) {
  const streamFormated = `${formatNumber(stream.value)} ${
    stream.unit
  }`;
  return streamFormated;
}

export type { StatusColorOptions };

export {
  valueEmptyTo,
  objectIsEmpty,
  arrayIsEmpty,
  stringIsEmpty,
  statusColor,
  stringIsJSON,
  formattedLocation,
  objectHasEmptyArrays,
  arrayHasEmptyArrays,
  getPreviousDay,
  getRangesNumbers,
  getIdClientVisits,
  countCharAppearences,
	isBoolean,
  formatStream,
};
