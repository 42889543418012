import { ChipStatus } from "@alb/live-lib";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TOccurrence } from "types/types";

import usePermission from "hooks/usePermission";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";

const OccurrencesTableRow = (props: {
  row: TOccurrence;
  onRowClick: (row: TOccurrence) => void;
  onEdit: (row: TOccurrence) => void;
  onDeleteClick: (row: TOccurrence) => void;
}) => {
  const { row, onRowClick, onEdit, onDeleteClick } = props;
  const { t } = useTranslation();

  const { hasPermission } = usePermission();
  const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);
  const canDelete = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.DELETE);

  /*function statusColor(status_value: string) {
    const status: IStatusColor = {
      invited: "warning",
      "non-active": "error",
      active: "success",
      deleted: "error",
    };
    return status[status_value as keyof IStatusColor] as StatusColorOptions;
  }*/

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer", height: 44 }}
        onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}
      >
        {/* <TableCell>{row.type ? t(`occurrences.${row.type}`) : ''}</TableCell> */}
        <TableCell>{row?.status ? row?.status?.name : "--"}</TableCell>
        <TableCell data-testid={"occurrenceCategoryCell"}>{row?.category?.name}</TableCell>
        <TableCell className="truncate-3" title={row.address}>{row.address}</TableCell>
        {/* <TableCell>
          {row.geolocation.iconPosition
            ? JSON.stringify(row.geolocation.iconPosition)
            : "--"}
        </TableCell> */}
        <TableCell>
          {formatDate(row.start_date, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>{row.assignee ? row.assignee.assignee_name : "--"}</TableCell>
        <TableCell>
          {row.urgency ? <ChipStatus label={row.urgency.name} status="" /> : "--"}
        </TableCell>

        <TableCell>
          {row.resolution_days !==null
            ? row.resolution_days + " " + t("calendar.days")
            : "--"}
        </TableCell>

        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {canEdit && (
            <IconButton
              title={t("common.edit")}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                onEdit(row);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {canDelete && (
            <IconButton
              title={t("common.delete")}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                onDeleteClick(row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default OccurrencesTableRow;
