import _ from "lodash";

export const getIcons = (
  type: string,
  subType?: string,
  status?: boolean,
  park_type?: string,
  park_occupied?: string,
  icon?: string,
) => {
  if (type === "event") {
    return require("assets/images/map/markers/enabled/event.svg").default;
  }

  if (type === "occurrence") {
    const onOff = "enabled"; //NOTA: Para já não vai ser considerado o estado das ocorrências e será para mostrar sempre como enabled

    const simplifiedName =
		icon && _.deburr(icon?.toLowerCase().replaceAll(" ", ""));

    switch (simplifiedName) {
      case "evc":
        return require(`assets/images/map/markers/${onOff}/occurrences/evc.svg`);
      case "cer":
        return require(`assets/images/map/markers/${onOff}/occurrences/cer.svg`);
      case "h2":
        return require(`assets/images/map/markers/${onOff}/occurrences/h2.svg`);
      case "parqueinfantil":
        return require(`assets/images/map/markers/${onOff}/occurrences/parqueinfantil.svg`);
      case "rededeagua":
        return require(`assets/images/map/markers/${onOff}/occurrences/redeagua.svg`);
      case "predionaoconforme":
        return require(`assets/images/map/markers/${onOff}/occurrences/predionaoconforme.svg`);
      case "contadoresdeagua":
        return require(`assets/images/map/markers/${onOff}/occurrences/contadoresagua.svg`);
      case "rededeesgotos":
        return require(`assets/images/map/markers/${onOff}/occurrences/redeesgotos.svg`);
      case "limpezaurbana":
        return require(`assets/images/map/markers/${onOff}/occurrences/limpezaurbana.svg`);
      case "ambiente":
        return require(`assets/images/map/markers/${onOff}/occurrences/ambiente.svg`);
      case "recolhadelixo":
        return require(`assets/images/map/markers/${onOff}/occurrences/recolhalixo.svg`);
      case "transito":
        return require(`assets/images/map/markers/${onOff}/occurrences/transito.svg`);
      case "livrospedidos":
        return require(`assets/images/map/markers/${onOff}/occurrences/livrospedidos.svg`);
      case "materiaisnaviapublica":
        return require(`assets/images/map/markers/${onOff}/occurrences/materiaisviapublica.svg`);
      case "outros":
        return require(`assets/images/map/markers/${onOff}/occurrences/outros.svg`);
      case "muros":
        return require(`assets/images/map/markers/${onOff}/occurrences/muros.svg`);
      case "jardinseespacosverdesurbanos":
        return require(`assets/images/map/markers/${onOff}/occurrences/espacosverdes.svg`);
      case "fiscalizacao":
        return require(`assets/images/map/markers/${onOff}/occurrences/fiscalizacao.svg`);
      case "instalacaodesportiva":
        return require(`assets/images/map/markers/${onOff}/occurrences/instalacaodesportiva.svg`);
      case "pavimentos":
        return require(`assets/images/map/markers/${onOff}/occurrences/pavimentos.svg`);
      default:
    }
    return require(`assets/images/map/markers/${onOff}/occurrence.svg`);
  }

  if (type === "device") {
    const onOff = status ? "enabled" : "disabled";
    if(park_type && park_occupied) {

      // Por vezes recebemos o boleano como string e com um espaço à frente, assim evitamos que isso aconteça
      // const booleanVal = JSON.parse(_.deburr(park_occupied?.toLowerCase().replaceAll(" ", "")))

      const park_occupied_on_off = park_occupied ? "disabled" : "enabled";
      // const simplifiedType = _.deburr(park_type?.toLowerCase().replaceAll(" ", ""));

      switch (park_type.toLowerCase()) {
        case 'p':
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/meter.svg`);
        case 'e':
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/eletric.svg`);
        case 'c':
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/load.svg`);
        default:
      }
    } else{
      switch (subType?.toLowerCase()) {
        case "ptdenergy":
          return require(`assets/images/map/markers/${onOff}/edp/ptdenergy.svg`);
        case "ipenergy":
          return require(`assets/images/map/markers/${onOff}/edp/ipenergy.svg`);
        case "consumptionenergy":
          return require(`assets/images/map/markers/${onOff}/edp/consumptionenergy.svg`);
        case "air":
          return require(`assets/images/map/markers/${onOff}/monitar/air.svg`);
        case "meteo":
          return require(`assets/images/map/markers/${onOff}/monitar/meteo.svg`);
        case "noise":
          return require(`assets/images/map/markers/${onOff}/monitar/noise.svg`);
        case "mobility":
          return require(`assets/images/map/markers/${onOff}/smartparking/mobility.svg`);
        case "traffic":
          return require(`assets/images/map/markers/${onOff}/citycatalyst/traffic.svg`);
        case "charge":
          return require(`assets/images/map/markers/${onOff}/tulaLabs/charge.svg`);
        case "inversor_fotovoltaico":
          return require(`assets/images/map/markers/${onOff}/cer/inversor_fotovoltaico.svg`);
        case "storage":
          return require(`assets/images/map/markers/${onOff}/cer/storage.svg`);
        default:
      }
    }

    return require(`assets/images/map/markers/${onOff}/default.svg`);
  }
};
