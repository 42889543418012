import { BackToButton, LayoutContext, TableHeadOrder } from '@alb/live-lib';
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Loading from 'components/Utils/Loading';
import NoData from 'components/Utils/NoData';
import useGet from 'hooks/fetchData/useGet';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { arrayIsEmpty } from 'utils/conditions';
import { getListParams } from 'utils/listParams';

import CategoriesAdd from './CategoriesAdd';
import CategoriesTable from './CategoriesTable';
import { FiltersSection } from './FiltersSection';
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders';
import { MODULES_KEYS } from 'utils/modules/modulesKeys';

const CategoriesList = () => {
	const { t } = useTranslation();
	const { addHeader, addAction, action } = useContext(LayoutContext);
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");
	const [filterSearch, setFilterSearch] = useState<string>("");

	const header = {
		title: t("events.categories"),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<AddIcon />}
				onClick={() => addAction(true)}
			>
				{t("events.addCategory")}
			</Button>
		),
		backTo: <BackToButton title={t("goBack")} onClick={() => navigate(-1)} />,
	};

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

	const {
		data: categories,
		loading,
		error,
		refetch,
	} = useGet(ServiceApiUrl.eventsCategories, getListParams(page, order, orderBy, filterSearch), undefined, headers);

	let categoriesList = <NoData error={error} />;

	if (loading) {
		categoriesList = <Loading />;
	}

	if (categories && !arrayIsEmpty(categories.data)) {
		categoriesList = (
			<CategoriesTable
				data={categories}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetch}
			/>
		);
	}

	let categoryAdd;
	if (action) {
		categoryAdd = (
			<CategoriesAdd
				open={action}
				onClose={() => addAction(false)}
				onSuccess={() => {
					addAction(false);
					refetch();
				}}
			/>
		);
	}

	return (
		<>
			<Grid item mt={4}>
				<FiltersSection
					setPage={setPage}
					setFilterSearch={setFilterSearch}
				/>
				{categoriesList}
			</Grid>
			{categoryAdd}
		</>
	)
}

export default CategoriesList
