import { INoData as INoDataLib, NoData as NoDataComponent } from '@alb/live-lib';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import useErrorMessage from "hooks/useErrorMessage";

interface INoData {
	message?: string;
	error?: AxiosError<any, any> | Event | null;
}

const NoData = ({ message, error }: INoData) => {
	const { t } = useTranslation();
  const theme = useTheme();
	const { getCodeDescritionError } = useErrorMessage();
	const errorRequest = getCodeDescritionError(error || null);
	const codeNoAccess = errorRequest?.code === 30001;
	const optionsNoData: INoDataLib = {
		icon: codeNoAccess ? (
			<VisibilityOffIcon sx={{ color: theme.palette.text.secondary  }} />
		) : error ? (
			<WarningAmberIcon sx={{ color: theme.palette.text.secondary  }} />
		) : undefined,
		message: errorRequest?.description || message || t('common.noData'),
		type: errorRequest ? 'error' : 'default',
	};

	return (
		<>
			<NoDataComponent icon={optionsNoData.icon} type={optionsNoData.type} message={optionsNoData.message} />
		</>
	);
};

export default NoData;
