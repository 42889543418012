// 2 primeiros numeros são para a funcionalidade/pagina pai
// 3º e 4º numero são para a funcionalidade/pagina filho
// 5º numero é a opção CRUD, 0 = create, 1 = read, 2 = update, 3 = delete
export const PERMISSIONS = {
  MAP: {
    CREATE: 10000,
    READ: 10001,
    UPDATE: 10002,
    DELETE: 10003,
  },
  DASHBOARD: {
    CREATE: 20000,
    READ: 20001,
    UPDATE: 20002,
    DELETE: 20003,
  },
  COMPARATIVE_ANALYSIS: {
    CREATE: 30000,
    READ: 30001,
    UPDATE: 30002,
    DELETE: 30003,
  },
  MANAGEMENT: {
    CREATE: 40000,
    READ: 40001,
    UPDATE: 40002,
    DELETE: 40003,
    CLIENTS: {
      CREATE: 40010,
      READ: 40011,
      UPDATE: 40012,
      DELETE: 40013,
    },
    USERS: {
      CREATE: 40020,
      READ: 40021,
      UPDATE: 40022,
      DELETE: 40023,
    },
    MODULES: {
      CREATE: 40030,
      READ: 40031,
      UPDATE: 40032,
      DELETE: 40033,
    },
    ADAPTERS: {
      CREATE: 40040,
      READ: 40041,
      UPDATE: 40042,
      DELETE: 40043,
    },
    ACCOUNTS: {
      CREATE: 40050,
      READ: 40051,
      UPDATE: 40052,
      DELETE: 40053,
    },
    ALERTS: {
      CREATE: 40060,
      READ: 40061,
      UPDATE: 40062,
      DELETE: 40063,
    },
    GROUPS: {
      CREATE: 40070,
      READ: 40071,
      UPDATE: 40072,
      DELETE: 40073,
    },
  },
  ADMINISTRATION: {
    CREATE: 50000,
    READ: 50001,
    UPDATE: 50002,
    DELETE: 50003,
    CLIENTS: {
      CREATE: 50010,
      READ: 50011,
      UPDATE: 50012,
      DELETE: 50013,
    },
  },
  ABOUT: {
    CREATE: 60000,
    READ: 60001,
    UPDATE: 60002,
    DELETE: 60003,
  },
  MODULES: {
    CREATE: 70000,
    READ: 70001,
    UPDATE: 70002,
    DELETE: 70003,
    EVENTS: {
      CREATE: 70010,
      READ: 70011,
      UPDATE: 70012,
      DELETE: 70013,
    },
    OCCURRENCES: {
      CREATE: 70020,
      READ: 70021,
      UPDATE: 70022,
      DELETE: 70023,
    },
    ENERGYPTD: {
      CREATE: 70030,
      READ: 70031,
      UPDATE: 70032,
      DELETE: 70033,
    },
    EXECUTIVE: {
      CREATE: 70040,
      READ: 70041,
      UPDATE: 70042,
      DELETE: 70043,
    },
    ELECTRICMOBILITY: {
      CREATE: 70050,
      READ: 70051,
      UPDATE: 70052,
      DELETE: 70053,
    },
    HYDROGENSUPPLY: {
      CREATE: 70060,
      READ: 70061,
      UPDATE: 70062,
      DELETE: 70063,
    },
    URBANWASTE: {
      CREATE: 70070,
      READ: 70071,
      UPDATE: 70072,
      DELETE: 70073,
    },
    PEOPLEMOBILITY: {
      CREATE: 70080,
      READ: 70081,
      UPDATE: 70082,
      DELETE: 70083,
    },
    OPENDATA: {
      CREATE: 70090,
      READ: 70091,
      UPDATE: 70092,
      DELETE: 70093,
    },
    CERMANAGEMENT: {
      CREATE: 70100,
      READ: 70101,
      UPDATE: 70102,
      DELETE: 70103,
    },
    PARKING: {
      CREATE: 70111,
      READ: 70112,
      UPDATE: 70113,
      DELETE: 70114,
    },
		NOTIFICATIONS: {
			READ: 70120,
			UPDATE: 70121,
		}
  },
  INDICATORS: {
    CREATE: 80000,
    READ: 80001,
    UPDATE: 80002,
    DELETE: 80003,
    ISO: {
      CREATE: 80010,
      READ: 80011,
      UPDATE: 80012,
      DELETE: 80013,
    },
  },

};
