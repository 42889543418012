/* eslint-disable react-hooks/exhaustive-deps */
import { IMapPoint, InputDateTime, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import EventIcon from "@mui/icons-material/Event";
import { Box, Button, Card, CardContent, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TUser } from "types/types";
import { date, object } from "yup";

import useDidMountEffect from "hooks/useDidMountEffect";
import useMarkers from "hooks/useMarkers";
import usePermission from "hooks/usePermission";
import i18n from "i18n/config";
import { getUser } from "store/slices/authSlice";
import { getSelectedMarkerType, selectFiltersTabOpen, selectMarkers, setFitBounds } from "store/slices/mapSlice";
import { selectOccurrencesFilterParams, setOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import styles from "styles/modules/map/controls.module.scss";
import { clientPublicPortal, IClientPublicPortal } from "utils/clientsPublicPortal";
import { objectIsEmpty } from "utils/conditions";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";

import { MarkersTypeOptions } from "./FilterMapTab/utils/GetOptions";

interface IOccurrencesStartDateControl {
  todayDate: Date;
  publicAPI?: boolean;
  sendNotifications: boolean;
  setSendNotifications: any;
}

export const OccurrencesStartDateControl = ({
  todayDate,
  publicAPI,
  sendNotifications,
  setSendNotifications,
}: IOccurrencesStartDateControl) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const selectedType = useSelector(getSelectedMarkerType);
  const { hasPermission } = usePermission();
  let mapOccurrencesParams = useSelector(selectOccurrencesFilterParams);
  const canReadOccurrences = hasPermission(
    PERMISSIONS.MODULES.OCCURRENCES.READ
  );
  const { getOccurrencesData } = useMarkers();

  const userRedux = useSelector(getUser);
  const user = !publicAPI
    ? userRedux
    : (clientPublicPortal(location.pathname) as TUser | IClientPublicPortal);

  const filterTabOpen = useSelector(selectFiltersTabOpen);
  const { addFeedback } = useFeedback();
  const markers: IMapPoint[] = useSelector(selectMarkers);

  const [open, setOpen] = useState<boolean>(false);
  const [startDateOccurrences, setStartDateOccurrences] = useState(todayDate);
  const [resultsCount, setResultsCount] = useState<IMapPoint[]>([]);

  const success = () => {
    if (sendNotifications) {
      addFeedback({
        message: t("feedback.success.appliedFilters"),
        severity: "success",
        duration: 1500,
      });
    } else {
      setSendNotifications(true);
    }
  };

  const [filtersWereOpened, setFiltersWereOpened] = useState<boolean>(false);

  useEffect(() => {
    //se abriu pela primeira vez, troca o estado
    if (filterTabOpen) setFiltersWereOpened(true);
  }, [filterTabOpen]);
  //vai fazer o pedido das ocorrencias com a start date por esta função
  //até que os filtros sejam abertos
  useDidMountEffect(async () => {
    if (!objectIsEmpty(mapOccurrencesParams) && !filtersWereOpened) {
      try {
        await getOccurrencesData(user, publicAPI);
        success();
      } catch {}
    }
  }, [mapOccurrencesParams["start_date"]]);

  const handleChangeStartDateOccurrences = (startDate: Date) => {
    setTimeout(() => {
      if (!filterErrors.start_date_occurrences) {
        //sempre que a data for alterada, envia fitBounds par afazer fitBounds do lado do mapa nos resultados
        dispatch(setFitBounds(true));
        //se não existir erro, faz set da data
        setStartDateOccurrences(startDate);
      }
    }, 0);
  };

  //quando a data de inicio das ocorrências for alterada, faz dispatch para os parametros do pedido das ocorrencias
  useDidMountEffect(() => {
    dispatch(
      setOccurrencesFilterParams({
        ...mapOccurrencesParams,
        start_date: formatDate(
          startDateOccurrences,
          t("calendar.dateTimeEndpoints")
        ),
      })
    );
  }, [startDateOccurrences]);

  const defaultValues = {
    start_date_occurrences: new Date(startDateOccurrences),
  };

  const getStartDateSchema = () => {
    return object().shape(
      {
        start_date_occurrences: date()
          .nullable()
          .typeError(t("validations.invalidDate", { dateFormat: "yyyy-MM-dd" }))
          .when("start_date_occurrences", (value, schema) => {
            if (mapOccurrencesParams["status_date"]) {
              return schema.max(
                mapOccurrencesParams["status_date"],
                t("occurrences.startDateAfterStatusDateError")
              );
            } else {
              return schema;
            }
          }),
      },
      [["start_date_occurrences", "start_date_occurrences"]]
    );
  };

  const methods = useForm<{ start_date_occurrences: Date }>({
    defaultValues: defaultValues,
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(getStartDateSchema()),
  });

  const {
    control,
    watch,
    formState: { errors: filterErrors },
  } = methods;

  let showCard =
    selectedType === MarkersTypeOptions.all ||
    selectedType === MarkersTypeOptions.occurrences;

  //get occurrences count in results after filter
  useEffect(() => {
    const result = markers.filter((marker) => marker.type === "occurrence");
    setResultsCount(result);
  }, [markers]);

  const handleClick = () => {
    setOpen(!open);
  };

  var today = new Date();

  const selectedDateLabel = () => {
    let selectedDate = watch("start_date_occurrences");

    let label =
      today.toDateString() === selectedDate.toDateString()
        ? t("common.today")
        : formatDate(selectedDate, t("calendar.dateFormat"));

    return label;
  };

  return (
    <>
      {showCard && (canReadOccurrences || publicAPI) && (
        <Box pl={1}>
          {open && (
            <Grid item pb={1} xs={true}>
              <Card elevation={0} className={styles["map-control-shadow"]}>
                <CardContent>
                  <Typography mb={1} variant="h3" fontSize={"16px"}>
                    {t("occurrences.occurrences")}
                  </Typography>
                  <Box mb={1.5} sx={{ display: "flex" }}>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      {resultsCount?.length}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontSize: "12px",
                        paddingLeft: 0.5,
                      }}
                    >
                      {t("map.list.subTitle", { count: resultsCount?.length })}
                    </Typography>
                  </Box>

                  <FormProvider {...methods}>
                    <Typography mr={6} gutterBottom fontSize={"12px"}>
                      {t("occurrences.startDateEqualOrGreater")}
                    </Typography>
                    <InputDateTime
                      name="start_date_occurrences"
                      activeField={watch("start_date_occurrences")}
                      control={control}
                      locale={i18n.language}
                      handleOnChange={handleChangeStartDateOccurrences}
                      labelBtnCancel={t("cancel")}
                      labelBtnConfirm={t("apply")}
                      placeholder={t("calendar.placeholderDate")}
                    />
                  </FormProvider>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Tooltip
            title={t("occurrences.startDateOccurrences")}
            placement="right"
          >
            <Button
              onClick={handleClick}
              className="contrast"
              endIcon={<EventIcon />}
            >
              {selectedDateLabel()}
            </Button>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default OccurrencesStartDateControl;
