import { Loading, arrayIsEmpty } from "@alb/live-lib";
import { useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const OccurrencesWeekChart = (props: {
	selectedRange: {
		startDate: Date;
		endDate: Date;
	  },
	needToRefetch : boolean | undefined}) => {


	const { selectedRange, needToRefetch } = props;
	const { t } = useTranslation();
	const { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const theme = useTheme()


	// const getWeekDate = (date: dateRange) => {
	//   setWeekDate(date);
	// };

	function getParams() {
		var params = undefined;
		if (selectedRange?.startDate || selectedRange?.endDate) {
			params = {
				start_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
			};
		}
		return params;
	}



	//request to get total occurrences by category
	const { data, loading, error, refetch } = useGet(
		ServiceApiUrl.occurrencesWeekday,
		getParams(),
		{
			manual: false
		},
		headersOCurrences
	);

	useEffect(() => {
		if(needToRefetch) {
			refetch();
		}
		// eslint-disable-next-line
	}, [needToRefetch])

	var week = [
		t("occurrences.sunday"),
		t("occurrences.monday"),
		t("occurrences.tuesday"),
		t("occurrences.wednesday"),
		t("occurrences.thursday"),
		t("occurrences.friday"),
		t("occurrences.saturday"),
	];

	var weekDaysOccurrences =
		data &&
		Object.values(data)?.map((day: any) => {
			var key = parseInt(Object.keys(day)[0]);
			return {
				name: week[key - 1],
				value: day[key],
			};
		});


	//chart options
	var option = {
		tooltip: {
			confine: true,
      extraCssText: 'white-space:inherit;',
			trigger: "item",
			formatter: function (value: any) {
				return `<b>${value.name}</b>: ${formatNumber(value.data.value)}<br/>`;
			},
		},
		textStyle: {
			fontFamily: "Altice",
			fontSize: 13,
		},
		xAxis: {
			type: "category",
			data: weekDaysOccurrences?.map(
				(d: { name: string; value: number }) => d.name
			),
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
			},
			axisLabel: {
				interval: 0,
				//  rotate: 30
			},
		},
		grid: {
			containLabel: true,
		},
		color: "#51bfbc",
		yAxis: {
			name: t("occurrences.occurrencesNumber"),
			nameLocation: "center",
			nameTextStyle: {
				padding: [0, 0, 50, 0],
				fontSize: 12,
			},
			type: "value",
			axisLabel: {
				formatter: (val: any) => formatNumber(val)
			},
			axisLine: {
				lineStyle: {
				  color: theme.palette.mode === "light" ? "" : theme.palette.common.white
				}
			  }
		},
		series: [
			{
				name: "occurrencesWeekday",
				data: weekDaysOccurrences && weekDaysOccurrences,
				type: "bar",
				label: {
					show: true,
					position: "top",
				},
			},
		],
	};


	return (
		<>
			<Loading show={loading} />
			{!arrayIsEmpty(data) && !loading && (
				<ReactEcharts style={{ height: "340px" }} option={option} />
			)}
			{arrayIsEmpty(data) && !loading && <NoData error={error} />}
		</>
	);
};

export default OccurrencesWeekChart;
