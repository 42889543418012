import { Calendar, LayoutContext, Loading } from "@alb/live-lib";
import MoreVertIcon from "@mui/icons-material/MoreVert";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ISelectedDeviceAndStreams, ISelectedDevicesAndOptions } from "types/interfaces";
import { TAdapterConfigurator, TStream } from "types/types";

import { IReactECharts, ReactECharts } from "components/charts/ReactECharts";
import AddChart from "components/comparativeAnalysis/add-chart/addChart";
import ChartControl from "components/comparativeAnalysis/chart-control/ChartControl";
import EditChart from "components/comparativeAnalysis/edit-chart/editChart";
import RemoveChart from "components/comparativeAnalysis/remove-chart/RemoveChart";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getComparativeAnalysisCharts, setAdapterSmartParkingFunchal, setComparativeAnalysisCharts } from "store/slices/comparativeAnalysisSlice";
import { GridWhiteContainer } from "styles/css/components";
import { getCookie } from "utils/cookie";
import { I18NEXT } from "utils/keys";
import { PERMISSIONS } from "utils/permissions/permissions";
import { arrayIsEmpty } from "utils/utils";

import DarkEmptyPageImg from "../assets/images/comparativeAnalysis/comparative_analysis_dark.svg";
import EmptyPageImg from "../assets/images/comparativeAnalysis/comparative_analysis.svg";

import "../components/comparativeAnalysis/comparativeAnalysis.scss";

const ComparativeAnalysis = () => {
  const { t } = useTranslation();
  const { addHeader, addAction, action } = useContext(LayoutContext);
  const { hasPermission } = usePermission();
  const canCreate = hasPermission(PERMISSIONS.COMPARATIVE_ANALYSIS.CREATE);
  const savedCharts = useSelector(getComparativeAnalysisCharts);
  const otherOptionsRefs = useRef<any>([]); //para gerar dinamicamente um ref para cada objeto listado
  const dispatch = useDispatch();
  const theme = useTheme();

	const locale = getCookie<string>(I18NEXT);

  const { data: adapters, loading: adaptersLoading, error: errorAdapters } = useGet(
    ServiceApiUrl.adapterConfiguratorsAllDevices,
    null
  );


  //vai guardar temporariamente a info do gráfico adicionado ou editado para enviar para gerar!
  const [submittedChartOption, setSubmittedChartOption] =
    useState<ISelectedDevicesAndOptions>();
  const [chartOptionsArrayLocalS, setChartOptionsArrayLocalS] = useState<
    ISelectedDevicesAndOptions[]
  >([]);
  const [chartOptionsArray, setChartOptionsArray] = useState<
    ISelectedDevicesAndOptions[]
  >([]);
  const [chartToEdit, setChartToEdit] = useState<ISelectedDevicesAndOptions>();
  const [selectedRange, setSelectedRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>();

  const [loadingDevicesData, setLoadingDevicesData] = useState<boolean>(false);

  const [openDialogEditChart, setOpenDialogEditChart] = useState(false);
  const handlerDialogEditChart = () => {
    setOpenDialogEditChart(false);
    dispatch(setAdapterSmartParkingFunchal({}));
  };
  const [openOtherOptions, setOpenOtherOptions] = useState(() =>
    chartOptionsArray.map((i) => false)
  );

  const [openDialogDeleteChart, setOpenDialogDeleteChart] = useState(false);
  const [chartIDToRemove, setChartIDToRemove] = useState<string>("");

  const header = {
    title: t("comparativeAnalysis.comparativeAnalysis"),
    action: canCreate && !arrayIsEmpty(adapters?.data) && (
      <Button
        variant="contained"
        disableElevation
        color="primary"
        onClick={() => handleAction(true)}
        disabled={adaptersLoading || loadingDevicesData || loadingLocalStorageCharts()}
      >
        {t("comparativeAnalysis.compareVariables")}
      </Button>
    ),
  };

  const handleAction = (action: boolean) => {
    addAction(action);
  };

  useEffect(() => {
    addHeader(header);
    handleAction(false);
  }, [loadingDevicesData, adaptersLoading, loadingLocalStorageCharts()]);

  const getSubmittedChartOptions = (
    chartOption: ISelectedDevicesAndOptions
  ) => {
    const chart = {
      ...chartOption,
      addOrEditAction: true,
    }
    setSubmittedChartOption(chart);
  };

  const getLoadingData = (loading: boolean) => {
    setLoadingDevicesData(loading);
  };

  //buscar dados dos gráficos guardados em LS
  useEffect(() => {
    if (savedCharts?.length > 0) {
      setChartOptionsArrayLocalS(savedCharts);
    }
  }, []);

  //verifica se o gráfico enviado pertence ao cliente do user.
  function existOnClient(chart: ISelectedDevicesAndOptions) {
    let firstDeviceId = chart.firstItem.device?.id;
    let secondDeviceId = chart.secondItem.device?.id;

    let exist: boolean = false;
    if (adapters?.data) {
      let allDevices = Array.prototype.concat.apply(
        [],
        adapters?.data.map((g: TAdapterConfigurator) => g.devices)
      );
      if (
        allDevices.some((d) => d.id === firstDeviceId) &&
        allDevices.some((d) => d.id === secondDeviceId)
      ) {
        exist = true;
      }
    }
    return exist;
  }
  //vai retornar se os gráficos já foram reconstruidos ou não
  function loadingLocalStorageCharts() {
    //verifica quais gráficos no array guardado na localstorage pertencem ao cliente
    let savedChartsFromClient = chartOptionsArrayLocalS.filter(
      (chart: ISelectedDevicesAndOptions) => existOnClient(chart)
    );
    //enquanto a quantidade de gráficos guardados deste cliente for > do que o gráfico local, mostra o loading
    return savedChartsFromClient?.length > chartOptionsArray?.length;
  }

  useEffect(() => {
    setOpenOtherOptions(chartOptionsArray.map((i) => false));
  }, [chartOptionsArray]);

  function setChartsOptions(
    id: string,
    chartType: string,
    option: IReactECharts["option"],
    firstItem: ISelectedDeviceAndStreams,
    secondItem: ISelectedDeviceAndStreams,
    range: { startDate: Date; endDate: Date }
  ) {
    //limpa o gráfico que foi submetido porque já foi adicionado
    setSubmittedChartOption(undefined);

    if (chartToEdit !== undefined) {
      let chartIndex = chartOptionsArray.findIndex(
        (x) => x.id === chartToEdit.id
      );

      let editedChart = [...chartOptionsArray];

      editedChart[chartIndex] = {
        ...editedChart[chartIndex],
        chartType: chartType,
        firstItem: firstItem,
        secondItem: secondItem,
        chartOption: option,
        range: range,
      };
      setChartOptionsArray(editedChart);

      if (!selectedRange) {
        //se foi um edit normal, guarda em localstorage
        let chartIndexLS = chartOptionsArrayLocalS.findIndex(
          (x) => x.id === chartToEdit.id
        );
        let editedChartLS = [...chartOptionsArrayLocalS];

        editedChartLS[chartIndexLS] = {
          ...editedChartLS[chartIndexLS],
          chartType: chartType,
          firstItem: firstItem,
          secondItem: secondItem,
        };

        setChartOptionsArrayLocalS(editedChartLS);
        dispatch(setComparativeAnalysisCharts(editedChartLS));
      }
      setChartToEdit(undefined); //reset ao objeto para editar
      setSelectedRange(undefined); //limpa a seleção de range que já foi alterado no gráfico
    } else {
      //se não existir o id, adiciona
      if (!chartOptionsArray.some((e) => e.id === id)) {
        setChartOptionsArray((o) => [
          {
            id: id,
            chartType: chartType,
            firstItem: firstItem,
            secondItem: secondItem,
            chartOption: option,
            range: range,
          },
          ...o,
        ]);
      }
      if (!chartOptionsArrayLocalS.some((e) => e.id === id)) {
        //se já existir o id no array, não adiciona, porque já existe

        let chartOptionsArrayLocalS_ = [...chartOptionsArrayLocalS];

        chartOptionsArrayLocalS_ = [
          ...chartOptionsArrayLocalS_,
          {
            id: id,
            chartType: chartType,
            firstItem: firstItem,
            secondItem: secondItem,
          },
        ];
        setChartOptionsArrayLocalS(chartOptionsArrayLocalS_);
        dispatch(setComparativeAnalysisCharts(chartOptionsArrayLocalS_));
      }
    }
    setLoadingDevicesData(false);
  }

  const handleToggle = (
    index: number,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpenOtherOptions(
      openOtherOptions?.map((prevOpen, i) => (i === index ? !prevOpen : false))
    );
  };

  const handleCloseOtherOptions = (event: Event, index: number) => {
    if (
      otherOptionsRefs.current &&
      otherOptionsRefs.current[index].contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenOtherOptions(
      openOtherOptions?.map((o, i) => (i === index ? (o = false) : false))
    );
  };

  function handlerDialogDeleteChart() {
    setOpenDialogDeleteChart(!openDialogDeleteChart);
  }

  //ao eliminar um gráfico
  const handleRemoveChart = () => {
    let chartOptionsArrayAux = chartOptionsArray.filter(
      (i) => i.id !== chartIDToRemove
    );
    let chartOptionsArrayLocalSAux = chartOptionsArrayLocalS.filter(
      (i) => i.id !== chartIDToRemove
    );
    setChartOptionsArrayLocalS((o) => {
      const val = chartOptionsArrayLocalSAux;
      dispatch(setComparativeAnalysisCharts(val));
      return val;
    });
    setChartOptionsArray(chartOptionsArrayAux);
    handlerDialogDeleteChart();
    setChartIDToRemove("");
  };

  //ao trocar data no calendário, vai guardar o objeto e o id e enviar para o editar
  const handleOnSelectedRange = (
    dateRange: { startDate: Date; endDate: Date },
    chart: ISelectedDevicesAndOptions
  ) => {
    setSelectedRange(dateRange); //define o range selecionado para enviar para alterar o gráfico
    setChartToEdit(chart); //para saber que é para alterar o gráfico
    setSubmittedChartOption(chart); //coloca o gráfico em variável para enviar para alterar
  };

  return (
    <>
      {arrayIsEmpty(adapters?.data) && !adaptersLoading && <NoData error={errorAdapters} />}

      {/* se os valores dos devices ainda estiverem a carregar e nao existir index/objeto/gráfico para editar, mostra a mensagem fora do container */}
      {(adaptersLoading || loadingDevicesData || loadingLocalStorageCharts()) && chartToEdit === undefined && (
        <>
          <Loading show={adaptersLoading || loadingDevicesData || loadingLocalStorageCharts()} />
        </>
      )}

      <Grid mt={6}>
        {chartOptionsArray?.length > 0 &&
          chartOptionsArray.map(
            (o: ISelectedDevicesAndOptions, index: number) => (
              <GridWhiteContainer mb={2} key={o.id} style={{ width: "100%" }}>
                <Grid container>
                  {!(loadingDevicesData && chartToEdit?.id === o.id) && (
                    <>
                      <Grid item xs={12} lg={3.5}>
                        <Typography variant="h6" noWrap>
                          {o?.firstItem?.device?.name}
                        </Typography>
                        {o.firstItem.streams.map(
                          (s: TStream, index: number) => (
                            <Typography key={s.id} variant="body1" noWrap>
                              {t(`streams.${s.name.toLowerCase()}`) +
                                (s.unit ? " (" + s.unit + ")" : "")}
                            </Typography>
                          )
                        )}
                      </Grid>
                      <Grid item xs={12} lg={3.5}>
                        <Typography variant="h6" noWrap>
                          {o?.secondItem?.device?.name}
                        </Typography>
                        {o.secondItem.streams.map(
                          (s: TStream, index: number) => (
                            <Typography key={s.id} variant="body1" noWrap>
                              {t(`streams.${s.name.toLowerCase()}`) +
                                (s.unit ? " (" + s.unit + ")" : "")}
                            </Typography>
                          )
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} lg={5}>
                    {!loadingDevicesData &&
                      o.firstItem.device &&
                      o.secondItem.device &&
                      !loadingLocalStorageCharts() &&
                       (
                        <>
                          <Grid sx={{ textAlign: "right" }} item>
                            {o.range && (
                              <Calendar
                                startDate={new Date(o.range.startDate)}
                                endDate={new Date(o.range.endDate)}
                                labelBtnCancel={t("cancel")}
                                labelBtnConfirm={t("apply")}
                                locale={locale}
                                onSelectedRange={(e: {
                                  startDate: Date;
                                  endDate: Date;
                                }) => {
                                  handleOnSelectedRange(e, o);
                                }}
                                predefinedRanges={{
                                  labelLast30Days: t("calendar.last30Days"),
                                  labelLast7Days: t("calendar.last7Days"),
                                  position: "left",
                                }}
                              />
                            )}

                            <span
                              ref={(ref) =>
                                (otherOptionsRefs.current[index] = ref)
                              }
                            >
                              <IconButton
                                onClick={(
                                  e: React.MouseEvent<
                                    HTMLButtonElement,
                                    MouseEvent
                                  >
                                ) => handleToggle(index, e)}
                                size="small"
                                aria-controls={
                                  openOtherOptions !== undefined &&
                                  openOtherOptions[index]
                                    ? "split-button-menu"
                                    : undefined
                                }
                                aria-expanded={
                                  openOtherOptions !== undefined &&
                                  openOtherOptions[index]
                                    ? "true"
                                    : undefined
                                }
                                aria-haspopup="menu"
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </span>
                            {openOtherOptions !== undefined &&
                              openOtherOptions[index] && (
                                <Popper
                                  sx={{ zIndex: 1 }}
                                  open={
                                    openOtherOptions !== undefined &&
                                    openOtherOptions[index]
                                  }
                                  anchorEl={otherOptionsRefs.current[index]}
                                  role={undefined}
                                  transition
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin:
                                          placement === "bottom"
                                            ? "center top"
                                            : "center bottom",
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener
                                          onClickAway={(e: Event) => {
                                            handleCloseOtherOptions(e, index);
                                          }}
                                        >
                                          <MenuList
                                            id="split-button-menu"
                                            autoFocusItem
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                setChartToEdit(o);
                                                setOpenDialogEditChart(true);
                                              }}
                                            >
                                              {t(
                                                "comparativeAnalysis.editChart"
                                              )}
                                            </MenuItem>

                                            <MenuItem
                                              onClick={(event) => {
                                                handlerDialogDeleteChart();
                                                setChartIDToRemove(o.id);
                                              }}
                                            >
                                              {t(
                                                "comparativeAnalysis.removeChart"
                                              )}
                                            </MenuItem>
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              )}
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
                {loadingDevicesData && (
                  <>
                    {chartToEdit && chartToEdit.id === o.id ? (
                      <>
                        <Loading show={loadingDevicesData} />
                      </>
                    ):
                    // quando algum gráfico estiver a carregar
                    !o.chartOption && 
                    <Typography mt={2} variant="subtitle2"> 
                    {t("comparativeAnalysis.noDataForRangeDate")}
                    </Typography>
                    }
                    {o.chartOption !== undefined &&
                      chartToEdit?.id !== o.id && (
                        <ReactECharts option={o.chartOption} />
                      )}
                  </>
                )}
                {!loadingDevicesData && (
                  <>
                    {o.chartOption !== undefined && (
                      <ReactECharts option={o.chartOption} />
                    )}
                  </>
                )}
                {!loadingDevicesData && !o.chartOption && ( //quando não existem gráficos a carregar e não existem dados
                  <Typography mt={2} variant="subtitle2">
                    {t("comparativeAnalysis.noDataForRangeDate")}
                  </Typography>
                )}
              </GridWhiteContainer>
            )
          )}
      </Grid>

      {chartOptionsArray?.length <= 0 &&
        !loadingDevicesData &&
        !loadingLocalStorageCharts() &&
        adapters?.data?.length > 0 && (
          <>
            <img
              src={theme.palette.mode === "light" ? EmptyPageImg : DarkEmptyPageImg}
              alt={t("footer.RecoverPasswordBackgroundImage")}
              className={"comparativeAnalysisEmptyImage"}
            />

            <Typography variant="h6">
              {t("comparativeAnalysis.noComparisonsYet")}
            </Typography>
            <Typography variant="body1">
              {t("comparativeAnalysis.clickToAddCharts")}
            </Typography>
          </>
        )}

      {canCreate &&
        action &&
        !loadingDevicesData &&
        adapters?.data?.length > 0 && (
          <AddChart
            open={action}
            handlerClose={() => {
              handleAction(false);
              dispatch(setAdapterSmartParkingFunchal({}));
            }}
            adapters={adapters?.data}
            error={errorAdapters}
            getSubmitedData={getSubmittedChartOptions}
          />
        )}

      {submittedChartOption && !loadingLocalStorageCharts() && (
        <>
          <ChartControl
            firstItemSelected={submittedChartOption.firstItem}
            secondItemSelected={submittedChartOption.secondItem}
            chartType={submittedChartOption.chartType}
            setChartsOptions={setChartsOptions}
            getLoadingData={getLoadingData}
            chartID={submittedChartOption.id}
            newRange={
              selectedRange ? selectedRange : submittedChartOption.range
            } //ao editar, se não tiver sido trocado o range, coloca o que estava
            addOrEdit={submittedChartOption.addOrEditAction}
          />
        </>
      )}

      {savedCharts &&
        savedCharts?.length > 0 &&
        loadingLocalStorageCharts() &&
        adapters?.data?.length > 0 &&
        savedCharts.map(
          (e: ISelectedDevicesAndOptions, index: number) =>
            existOnClient(e) && (
              <ChartControl
                key={e.id}
                firstItemSelected={e.firstItem}
                secondItemSelected={e.secondItem}
                chartType={e.chartType}
                setChartsOptions={setChartsOptions}
                chartID={e.id}
                getLoadingData={getLoadingData}
                addOrEdit={submittedChartOption?.addOrEditAction}
              />
            )
        )}

      {!loadingDevicesData && chartToEdit && adapters?.data?.length > 0 && (
        <EditChart
          chartToEditAux={chartToEdit}
          open={openDialogEditChart}
          handlerClose={handlerDialogEditChart}
          setChartToEdit={setChartToEdit}
          adapters={adapters?.data}
          getSubmittedData={getSubmittedChartOptions}
        />
      )}

      {!loadingDevicesData && openDialogDeleteChart && (
        <RemoveChart
          open={openDialogDeleteChart}
          handlerClose={handlerDialogDeleteChart}
          handleRemoveChart={handleRemoveChart}
        />
      )}
    </>
  );
};

export default ComparativeAnalysis;
