/* eslint-disable react-hooks/exhaustive-deps */
import { BackToButton, EntityInfo, EntityInfoItem, IApiResponse, LayoutContext, Loading } from "@alb/live-lib";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { ISelectOption } from "types/interfaces";
import { TOccurrence } from "types/types";

import FilterSelect from "components/Utils/FilterSelect";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import { formatRoutePath } from "utils/routePath";
import { formattedLocation } from "utils/utils";

import useGroupOptions from "./hooks/useGroupOptions";
import useUsersOptions from "./hooks/useUsersOptions";
import OccurrenceDetailMap from "./OccurrenceDetailMap";
import OccurrenceEdit from "./OccurrenceEdit";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const OccurrenceDetail = () => {
	let params = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();
	const { hasPermission } = usePermission();
	const dispatch = useDispatch();
	const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);

	const { addHeader, addAction, action } = useContext(LayoutContext);

	const { usersOptions } = useUsersOptions();
	const { groupOptions } = useGroupOptions();

	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
	const {
		data: occurrence,
		loading,
		error,
		refetch,
	} = useGet(
		`${ServiceApiUrl.occurrences}/${params.occurrenceId}`,
		undefined,
		undefined,
		headers
	);

	const assignedOptions = [...usersOptions, ...groupOptions];

	const assigned = assignedOptions.find((option) => {
		return option?.value === occurrence?.assignee?.assignee_id;
	});

	const handleSelectedOccurrence = (option: ISelectOption | null) => {
		if (!option) return undefined;

		const path = formatRoutePath(location, params, {
			occurrenceId: option.value,
		});
		navigate(path);
	};

	const customOptions = (data: IApiResponse<TOccurrence>) => {
		return data.data?.map((occurrence: TOccurrence) => ({
			label:
				occurrence?.name !== "" ? occurrence?.name : occurrence.category.name,
			value: occurrence.id,
		}));
	};

	const header = {
		title:
			occurrence?.name || occurrence?.category?.name || t("occurrences.detail"),
		backTo: (
			<BackToButton
				title={t("goBack")}
				onClick={() => navigate("/occurrences?view=list")}
			/>
		),
		select: occurrence && (
			<FilterSelect<TOccurrence>
				apiUrl={ServiceApiUrl.occurrences}
				onSelected={handleSelectedOccurrence}
				selectedValue={params?.occurrenceId}
				othersConfig={headers}
				customOptions={customOptions}
			/>
		),
		action: occurrence && canEdit && (
			<Button
				variant="contained"
				disableElevation
				startIcon={<EditRoundedIcon />}
				onClick={() => addAction(true)}
			>
				{t("occurrences.editOccurrence")}
			</Button>
		),
	};

	useEffect(() => {
		addHeader(header);
	}, [occurrence]);

	let streamView = <NoData error={error} />;

	if (loading) {
		streamView = <Loading show={loading} />;
	}

	const resolutionTime =
		occurrence?.resolution_days && occurrence?.resolution_days > 0
			? occurrence?.resolution_days + " " + t("calendar.days")
			: undefined;

	if (occurrence) {
		streamView = (
			<EntityInfo title={t("common.details")}>
				<EntityInfoItem label={t("occurrences.name")} value={occurrence.name} />
				<EntityInfoItem
					label={t("occurrences.status")}
					value={occurrence?.status?.name}
				/>
				<EntityInfoItem
					label={t("occurrences.description")}
					value={occurrence.description}
				/>
				{/* <EntityInfoItem label={t("occurrences.type")} value={occurrence.type} /> */}
				<EntityInfoItem
					label={t("occurrences.category")}
					value={occurrence.category.name}
				/>
				<EntityInfoItem
					label={t("occurrences.startDate")}
					value={
						occurrence.start_date
							? formatDate(
								occurrence.start_date,
								t("calendar.dateTimeFullFormat")
							)
							: "n/a"
					}
				/>
				<EntityInfoItem
					label={t("occurrences.endDate")}
					value={
						occurrence.end_date
							? formatDate(
								occurrence.end_date,
								t("calendar.dateTimeFullFormat")
							)
							: "n/a"
					}
				/>
				<EntityInfoItem
					label={t("occurrences.urgency")}
					value={occurrence?.urgency?.name}
				/>
				<EntityInfoItem
					label={t("occurrences.resolutionTime")}
					value={resolutionTime}
				/>
				<EntityInfoItem
					label={t("occurrences.assigned")}
					value={assigned?.label}
				/>

				<EntityInfoItem
					label={t("occurrences.occurrenceReporter")}
					value={occurrence.reporter}
				/>
				<EntityInfoItem
					label={t("occurrences.channel")}
					value={occurrence.channel}
				/>
				{/* <EntityInfoItem
          fitContent={true}
          label={t("occurrences.subject")}
          value={occurrence.subject}
        /> */}
				<EntityInfoItem
					label={t("common.createdAt")}
					value={formatDate(
						occurrence.created_at,
						t("calendar.dateTimeFullFormat")
					)}
				/>
				<EntityInfoItem
					label={t("common.updatedAt")}
					value={formatDate(
						occurrence.updated_at,
						t("calendar.dateTimeFullFormat")
					)}
				/>
				<EntityInfoItem
					label={t("occurrences.occurrenceURL")}
					value={occurrence.url}
				/>
				<EntityInfoItem
					label={t("occurrences.address")}
					value={occurrence.address}
				/>

				<EntityInfoItem
					label={t('occurrences.location')}
					value={formattedLocation(occurrence.geolocation.iconPosition)}
				/>
			</EntityInfo>
		);
	}

	const handleCloseEdit = () => {
		addAction(false);
		dispatch(setDrawingMap(false));
		dispatch(setDrawnCoords(null));
	};
	const handleSuccessEdit = (o: TOccurrence) => {
		refetch();
	};

	let occurrenceEdit;
	if (action) {
		occurrenceEdit = canEdit && (
			<OccurrenceEdit
				open={action}
				data={occurrence}
				onClose={handleCloseEdit}
				onSuccess={handleSuccessEdit}
			/>
		);
	}

	return (
		<>
			{streamView} {occurrenceEdit}
			<OccurrenceDetailMap occurrence={occurrence} />
		</>
	);
};
export default OccurrenceDetail;
