import { arrayIsEmpty, DynamicForm, IDynamicInputTemplate, IFormDefinition, LayoutContext, ToggleViewButton, TToggle, TypeDynamicInput } from "@alb/live-lib";
import { Add, AutoAwesomeMosaic, FormatListBulleted } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { subDays } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from 'react-hook-form'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { ModuleCards } from "components/modules/ModuleCards";
import { ColXTemplate, IFiltersSection } from "components/occurrences/FiltersSection";
import { OccurrenceAdd } from "components/occurrences/OccurrenceAdd";
import { OccurrencesCardsView } from "components/occurrences/OccurrencesCardsView";
import OccurrencesList from "components/occurrences/OccurrencesList";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { formatDate } from 'utils/date'
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { PERMISSIONS } from "utils/permissions/permissions";

const Occurrences = () => {
	// global component variables
	const { addHeader, addAction, action } = useContext(LayoutContext);
	const { hasPermission } = usePermission();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	// const occurrencesStatus = useSelector(selectOccurrencesStatus);
	const location = useLocation();
	const { headers: headersOCurrencesCards } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const searchParams = new URLSearchParams(location.search);
	const view = searchParams.get("view");

	// local state management
	const [display, setDisplay] = useState("cards");
	const [status, setStatus] = useState<any>(null);
	// const [loading, setLoading] = useState<boolean>(true);
	const [added, setAdded] = useState<boolean>(false);
	const [statusWResolutionTime, setStatusWResolutionTime] = useState<any>([]);
	const [loadingModuleCards, setLoadingModuleCards] = useState<boolean>(true);
	// const [clientHasOccurrences, setClientHasOccurrences] = useState<boolean>(false);

	const canCreate = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.CREATE);

	const [selectedRange, setSelectedRange] = useState<{
		startDate: Date;
		endDate: Date;
	}>({
		startDate: new Date(subDays(new Date(), 6).setHours(0, 0, 0)),
		endDate: new Date(),
	});

	const { data: resolutionTime, loading: loadingRT, error: errorRT, refetch: refetchResolutionTime } = useGet(
		ServiceApiUrl.occurrencesAverageResolutionTime,
		getParams(),
		{
			manual: false,
		},
		headersOCurrencesCards
	);

	// page header configuration
	const header = {
		title: t("occurrences.occurrencesManagement"),
		action: canCreate && (
			<Button
				variant="contained"
				disableElevation
				startIcon={<Add />}
				onClick={() => addAction(true)}
			>
				{t("occurrences.addOccurrence")}
			</Button>
		),
	};

	// component handlers
	const onCloseDialog = () => {
		addAction(false);
		dispatch(setDrawingMap(false));
		dispatch(setDrawnCoords(null));
	};

	// component handlers
	const handleDisplay = (
		event: React.MouseEvent<HTMLElement>,
		newDisplay: string | null
	) => {
		if (newDisplay !== null) {
			setDisplay(newDisplay);
		}
	};

	let occurrenceAdd;
	if (action) {
		occurrenceAdd = canCreate && (
			<OccurrenceAdd
				open={action}
				onClose={onCloseDialog}
				onSuccess={() => {
					setAdded(true);
					// fetchEventStatus()
					onCloseDialog();
				}}
			/>
		);
	}

	// extra components
	const toggleConfig: TToggle[] = [
		{
			value: "cards",
			icon: <AutoAwesomeMosaic fontSize="small" />,
		},
		{
			value: "list",
			icon: <FormatListBulleted fontSize="small" />,
		},
	];

	const { data: occurrencesStatus, error: errorCards, refetch: refetchOccurrencesCards, loading:loadingCards } = useGet(
		ServiceApiUrl.occurrencesTotalStatus,
		getParams(),
		{
			manual: false,
		},
		headersOCurrencesCards
	)

	function getParams() {
		let params = undefined;
		if (selectedRange?.startDate || selectedRange?.endDate) {
			params = {
				start_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
			};
		}
		return params;
	}

	// component effects
	useEffect(() => {
		addHeader(header);
		// dispatch(fetchStatus({ authToken, moduleID }));

		if (view === "list") setDisplay("list");
		// eslint-disable-next-line
	}, []);


	useEffect(() => {
		if (occurrencesStatus && occurrencesStatus.length > 0) {
			let items: any[] = [];
			let totalOccurences = occurrencesStatus[0].value;
			//como altero o objeto value para colocar
			items.push({ ...occurrencesStatus[0], type: "total" });
			items = [
				...items,
				...(occurrencesStatus[1]
					? [
						{
							name: occurrencesStatus[1]?.name,
							value: `${occurrencesStatus[1]?.value}/${totalOccurences}`,
							type: "total",
						},
					]
					: []),
				...(occurrencesStatus[2]
					? [
						{
							name: occurrencesStatus[2]?.name,
							value: `${occurrencesStatus[2]?.value}/${totalOccurences}`,
							type: "total",
						},
					]
					: []),
				...(occurrencesStatus[3]
					? [
						{
							name: occurrencesStatus[3]?.name,
							value: `${occurrencesStatus[3]?.value}/${totalOccurences}`,
							type: "total",
						},
					]
					: []),
			];
			setStatus((prev: any[]) => (prev = items));
		}
	}, [occurrencesStatus]);


	useEffect(() => {
		if (added)
			refetchOccurrencesCards();

		setAdded(false)
		// eslint-disable-next-line
	}, [added])

	useEffect(() => {
		if (status && !loadingRT) {
			// setClientHasOccurrences(status[0]?.value >= 1);
			let statusNoTotal = status?.filter((object: any) => {
				return object.name !== "occurrences";
			});
			if (resolutionTime && !arrayIsEmpty(resolutionTime)) {
				//add resolution days to status
				let resolutionTimeDays = {
					name: "resolution_time_days",
					value: resolutionTime?.[0]?.value, //[0] corresponde aos dias
					type: "avg",
				};
				if (resolutionTimeDays?.value) {
					statusNoTotal.push(resolutionTimeDays);
				}
			}
			setStatusWResolutionTime(statusNoTotal);
		}
		if(!loadingRT && !loadingCards){
			setLoadingModuleCards(false);
		}
		if (loadingRT) {
			setLoadingModuleCards(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, loadingRT, loadingCards]);

	useEffect(() => {
		if (errorCards) {
			setLoadingModuleCards(false);
		}
	}, [errorCards])


	const handleOnSelectedRange = (dateRange: {
		startDate: Date;
		endDate: Date;
	}) => {
		setSelectedRange(dateRange);
		refetchOccurrencesCards()
		refetchResolutionTime()
	};

	const defaultValueFieldDateRange = { startDateRange: undefined };

	const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return (
				<ColXTemplate
					xsCol={true}
					inputLabel={inputLabel}
					children={children}
				/>
			);
		},
		[]
	);

	const predefinedRanges = {
		labelLast30Days: t("calendar.last30Days"),
		labelLast7Days: t("calendar.last7Days"),
		position: "left",
	};

	const dynamicForm = {
		formId: "form-search",
		inputsDefinition: {
			startDateRange: {
				inputType: TypeDynamicInput.daterange,
				label: t("occurrences.startDate"),
				placeholder: t("calendar.placeholderDate"),
				defaultValue: defaultValueFieldDateRange.startDateRange,
				defaultValueField: defaultValueFieldDateRange,
				onSelectedRange: handleOnSelectedRange,
				noOptionsText: t("common.noOptions"),
				template: template,
				locale: i18n.language,
				predefinedRanges: predefinedRanges,
				labelBtnCancel: t("cancel"),
				labelBtnConfirm: t("apply"),
				clearButton: true,
			},
		},
	};
	const defaultValues: IFiltersSection = {
		startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
	};

	const methodsForm = useForm<IFiltersSection>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
		// resolver: yupResolver(getFiltersSchema()),
	});

	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

	const setInitialValue = () => {
		methodsForm.setValue("startDateRange", selectedRange, {
			shouldDirty: true,
		});
	};

	useEffect(() => {
		setInitialValue();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Grid item xs={12} mt={4}>
				<Box sx={{ width: "300px" }}>
					<DynamicForm formDefinition={formDefinition} />
				</Box>

			</Grid>
			<ModuleCards
				module="occurrences"
				items={statusWResolutionTime}
				loading={loadingModuleCards}
				error={errorRT || errorCards}
				sx={{ mt: 5 }}
				dataTestId={"OccurrencesCardsContainer"}
			/>
			<Box sx={{ mt: 4 }}>
				<ToggleViewButton
					display={display}
					toggle={toggleConfig}
					onChange={handleDisplay}
					sx={{
						height: "35px",
						position: "absolute",
					}}
				/>
				{display === "list" && <OccurrencesList refetch={added} setRefetch={setAdded} selectedRange={selectedRange} />}
				{display === "cards" && <OccurrencesCardsView needToRefetch={added} selectedRange={selectedRange} />}
			</Box>
			{occurrenceAdd}
		</>
	);
};

export default Occurrences;
