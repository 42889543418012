/* eslint-disable react-hooks/exhaustive-deps */
import { InputSelect, Loading } from "@alb/live-lib";
import { Box, Card, CardContent, Grid, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { defaultOptions } from "components/charts/defaultOptions";
import { IReactECharts, ReactECharts as SmoothedLineChart } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getNameClient } from "store/slices/authSlice";
import { getFilterDateRange, getGeojsonType, getSelectedAnalysisDetail, getSelectedParishArea } from "store/slices/mapSlice";
import { getVisitsByDateRangeChart, setDataVisitsByDateRangeChart, setPeriodVisitsDateRangeChart } from "store/slices/peopleMobilitySlice";
import styles from "styles/modules/map/list.module.scss";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { getIdClientVisits } from "utils/utils";

export const CardPeriod = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const nameClient = useSelector(getNameClient);
  const idParish = useSelector(getSelectedParishArea).id;
  const dateRange = useSelector(getFilterDateRange);
  const geojsonTypeMap = useSelector(getGeojsonType);
  const analysisDetail = useSelector(getSelectedAnalysisDetail);
  const data = useSelector(getVisitsByDateRangeChart).data;
  const periodValue = useSelector(getVisitsByDateRangeChart).period;

  const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});
  const periodOptions = [
    {
      label: t("map.detail.total"),
      value: "total",
    },
    {
      label: t("map.detail.morning"),
      value: "morning",
    },
    {
      label: t("map.detail.afternoon"),
      value: "afternoon",
    },
    {
      label: t("map.detail.night"),
      value: "night",
    },
  ];

  const paramsRequest = {
    start_date: formatDate(
      dateRange.startDate,
      t("calendar.dateFormatGeoAnalytics")
    ),
    end_date: formatDate(
      dateRange.endDate,
      t("calendar.dateFormatGeoAnalytics")
    ),
    day_part: periodValue !== "total" ? periodValue : undefined,
    city_id: idParish ? idParish : getIdClientVisits(nameClient),
  };
  const {
    data: dataPeriod,
    loading,
    error,
    refetch,
  } = useGet(ServiceApiUrl.visitsByDateRange, null, {
    manual: true,
  });

  const optionsDoughnutChart = (values: any) => {
    let dataXAxis: string[] = [];
    const dataSeries = values.map((val: any) => {
      dataXAxis.push(val.date);
      return val.value;
    });
    return {
      ...defaultOptions,
      tooltip: {
        confine: true,
      	extraCssText: 'white-space:inherit;',
        trigger: "axis",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        valueFormatter: function (value: any) {
          return formatNumber(value) || "--";
        },
      },
      grid: {
        left: "13%",
        top: "15%",
        bottom: "19%",
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: dataXAxis,
          axisLine: {
            lineStyle: {
              color: theme.palette.mode === "light" ? "" : theme.palette.common.white
            }
          }
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: (val: any) => formatNumber(val),
          },
          axisLine: {
            lineStyle: {
              color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
            }
          }
        },
      ],
      series: [
        {
          name: t("map.legend.visitsTitle"),
          type: "line",
          emphasis: {
            focus: "series",
          },
          data: dataSeries,
        },
      ],
      legend: {
        ...defaultOptions.legend,
        ...{
          textStyle: {
            color: theme.palette.text.primary,
            fontFamily: "Altice",
            fontSize: "12px",
          },
        },
      },
    } as IReactECharts["option"];
  };

  const defaultValues = {
    period: periodOptions[0].value || null,
  };

  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { control, setValue } = methods;

  function onChangePeriod(e: SelectChangeEvent<string[]>) {
    const newPeriod = e.target.value;
    dispatch(setPeriodVisitsDateRangeChart(newPeriod));
    // fetch data when click other period
    refetch({
      params: {
        ...paramsRequest,
        day_part: newPeriod !== "total" ? newPeriod : undefined,
      },
    });
  }

  useEffect(() => {
    if (geojsonTypeMap !== "analysis" && !analysisDetail) {
      refetch({ params: paramsRequest });
    } else if (geojsonTypeMap === "analysis" && analysisDetail) {
      // show info of analysis
      setValue("period", periodValue);
      setChartOptions(optionsDoughnutChart(data));
    }
  }, [dateRange, analysisDetail]);

  useEffect(() => {
    if (!loading && dataPeriod) {
      dispatch(setDataVisitsByDateRangeChart(dataPeriod));
      setChartOptions(optionsDoughnutChart(dataPeriod));
    }
  }, [dataPeriod]);

  useEffect(() => {
    if (error) {
      dispatch(setDataVisitsByDateRangeChart(null));
    }
  }, [error]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent className={styles["list-detail__card"]}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="body1">
                {t("map.detail.comparisonPeriod")}
              </Typography>
              <Typography fontSize="14px" mb={"1.5px"} mt="auto">
                &ensp; ({t("average")})
              </Typography>
            </Box>
            <Typography variant="subtitle2" pt={1}>
              {t("map.detail.period")}
            </Typography>
            <InputSelect
              name="period"
              options={periodOptions}
              control={control}
              handleOnChange={onChangePeriod}
            />
            <Loading show={loading} />
            {!arrayIsEmpty(data) && !loading && (
              <>
                <SmoothedLineChart
                  option={chartOptions}
                  style={{ minHeight: "300px" }}
                />
              </>
            )}
            {arrayIsEmpty(data) && !loading && <NoData error={error} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
