import MapIcon from "@mui/icons-material/Map";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import BarChartIcon from "@mui/icons-material/BarChart";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import AppsIcon from '@mui/icons-material/Apps';
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ReplyIcon from "@mui/icons-material/Reply";
import logo from "../assets/images/live-campus/logo.svg";
import smallLogo from "../assets/images/live-campus/small_logo.svg";

import { TFunction } from "i18next";

import { PERMISSIONS } from "utils/permissions/permissions";
import { arrayIsEmpty } from "utils/conditions";

import { TUser } from "types/types";
import { setSidebarIsOpen } from "store/slices/mapSlice";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ISidebarNotification } from "@alb/live-lib/templates/sidebar/components/SidebarNotification";
import { INotification } from "@alb/live-lib/templates/sidebar/components/SidebarNotificationItem";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NoData from "components/Utils/NoData";

export const sidebar = (
	t: TFunction,
	user: TUser | null,
	hasManyPermissions: Function,
	onClickLogout: Function,
	fromLocation: any | null,
	publicPortal: boolean,
	dispatch: Dispatch<AnyAction>,
	notifications?: {notifications: INotification[], count: number}
) => {

	const menuItems = [
		{
			title: t("sidebar.map"),
			tooltip: t("sidebar.map"),
			path: publicPortal ? fromLocation.from.pathname : "/map",
			icon: <MapIcon />,
			permissions: [PERMISSIONS.MAP.READ],
		},
		{
			title: t("sidebar.dashboard"),
			tooltip: t("sidebar.dashboard"),
			icon: <DashboardIcon />,
			path: "/dashboard",
			permissions: [PERMISSIONS.DASHBOARD.READ],
			subItems: [],
		},
		{
			title: t("sidebar.comparativeAnalysis"),
			tooltip: t("sidebar.comparativeAnalysis"),
			path: "/comparativeanalysis",
			icon: <BarChartIcon />,
			permissions: [PERMISSIONS.COMPARATIVE_ANALYSIS.READ],
			subItems: [],
		},
		{
			title: t("sidebar.modules"),
			tooltip: t("sidebar.modules"),
			icon: <AppsIcon />,
			path: "",
			permissions: [PERMISSIONS.MODULES.READ],
			sortedSubItems: true,
			subItems: [
				{
					title: t("sidebar.energyPTD"),
					path: "consumption-bt",
					icon: null,
					permissions: [PERMISSIONS.MODULES.ENERGYPTD.READ],
				},
				{
					title: t("events.eventManagement"),
					path: "events",
					icon: null,
					permissions: [PERMISSIONS.MODULES.EVENTS.READ],
				},
				{
					title: t("sidebar.executive"),
					path: "executive",
					icon: null,
					permissions: [PERMISSIONS.MODULES.EXECUTIVE.READ],
				},
				{
					title: t("sidebar.cerManagement"),
					path: "cermanagement",
					icon: null,
					permissions: [PERMISSIONS.MODULES.CERMANAGEMENT.READ]
				},
				{
					title: t("occurrences.occurrencesManagement"),
					path: "occurrences",
					icon: null,
					permissions: [PERMISSIONS.MODULES.OCCURRENCES.READ],
				},
				{
					title: t("sidebar.electricMobility"),
					path: "electricmobility",
					icon: null,
					permissions: [PERMISSIONS.MODULES.ELECTRICMOBILITY.READ],
				},
				{
					title: t("sidebar.hydrogenSupply"),
					path: "hydrogensupply",
					icon: null,
					permissions: [PERMISSIONS.MODULES.HYDROGENSUPPLY.READ],
				},
				{
					title: t("sidebar.peopleMobility"),
					path: "/map?view=visits",
					icon: null,
					permissions: [PERMISSIONS.MODULES.PEOPLEMOBILITY.READ],
				},
				{
					title: t("sidebar.openData"),
					path: "/opendata",
					icon: null,
					permissions: [PERMISSIONS.MODULES.OPENDATA.READ],
				},
				{
					title: t("sidebar.urbanWaste"),
					path: "urbanwaste",
					icon: null,
					permissions: [PERMISSIONS.MODULES.URBANWASTE.READ],
				},
				{
					title: t("sidebar.parking"),
					path: "parking",
					icon: null,
					permissions: [PERMISSIONS.MODULES.PARKING.READ],
				},
			],
		},
		{
			title: t("sidebar.indicators"),
			tooltip: t("sidebar.indicators"),
			icon: <FlagRoundedIcon />,
			path: "",
			permissions: [PERMISSIONS.INDICATORS.READ],
			sortedSubItems: true,
			subItems: [
				{
					title: t("indicators.iso.iso"),
					path: "iso-indicators",
					icon: null,
					permissions: [PERMISSIONS.INDICATORS.ISO.READ],
				},
			],
		},
		{
			title: t("sidebar.management"),
			tooltip: t("sidebar.management"),
			icon: <SettingsIcon />,
			permissions: [PERMISSIONS.MANAGEMENT.READ],
			sortedSubItems: true,
			subItems: [
				{
					title: t("sidebar.modules"),
					path: "management/modules",
					permissions: [PERMISSIONS.MANAGEMENT.MODULES.READ],
				},
				{
					title: t("sidebar.users"),
					path: "management/users",
					permissions: [PERMISSIONS.MANAGEMENT.USERS.READ],
				},
				// {
				//   title: 'Gateways',
				//   path: '/gateways',
				//   permissions: [],
				// },
				// {
				//   title: 'Utilizadores',
				//   path: '/users',
				//   permissions: [],
				// },
				{
					title: t("sidebar.adapters"),
					path: "management/adapters",
					permissions: [PERMISSIONS.MANAGEMENT.ADAPTERS.READ],
				},
				{
					title: t("sidebar.accounts"),
					path: "management/accounts",
					permissions: [PERMISSIONS.MANAGEMENT.ACCOUNTS.READ],
				},
				{
					title: t("sidebar.groups"),
					path: "management/groups",
					permissions: [PERMISSIONS.MANAGEMENT.GROUPS.READ],
				},
				// {
				//   title: t("sidebar.alerts"),
				//   path: "management/alerts",
				//   permissions: [PERMISSIONS.MANAGEMENT.ALERTS.READ],
				// },
				// {
				//   title: 'Módulos',
				//   path: '/modules',
				//   permissions: [],
				// },
			],
		},
		{
			title: t("sidebar.administration"),
			tooltip: t("sidebar.administration"),
			path: "",
			icon: <ConstructionIcon />,
			permissions: [PERMISSIONS.ADMINISTRATION.READ],
			sortedSubItems: true,
			subItems: [
				{
					title: t("sidebar.clients"),
					path: "/administration/clients",
					permissions: [PERMISSIONS.ADMINISTRATION.CLIENTS.READ],
				},
			],
		},
		{
			title: t("sidebar.about"),
			tooltip: t("sidebar.about"),
			path: "/about",
			icon: <InfoIcon />,
			permissions: [PERMISSIONS.ABOUT.READ],
		}
	];

	const menuUser = [
		{
			title: t("sidebar.profileSettings"),
			path: "/profile-settings",
			fromLocation: fromLocation,
		},
		{
			title: t("sidebar.notifications"),
			path: "/notifications",
		},
		{
			title: t("sidebar.logout"),
			onClick: onClickLogout,
		},
	];

	function filterHasPermissions(items: any) {
		if (!items) return [];
		// check parent item permissions
		const parentItems = items.filter((item: any) =>
			hasManyPermissions(item.permissions)
		);
		// check child item permissions
		const childItems = parentItems.map((item: any) => {
			if (arrayIsEmpty(item.subItems)) {
				return item;
			} else {
				let finalItem = item;
				//devolve apenas os subitems com permissões.
				let subItemsWithPermissions = finalItem.subItems.filter((e: any) =>
					hasManyPermissions(e.permissions)
				);
				//se tiver pelo menos 1 com permissões, devolve o item, mas apenas com os subitems com permissões.
				if (subItemsWithPermissions.length > 0) {
					finalItem.subItems = subItemsWithPermissions;
					return finalItem;
				}
				return null;
			}
		});
		return childItems.filter((item: any) => item);
	}

	function showPublicItems(items: any) {
		return items.filter((item: any) => item.title === t("sidebar.map"));
	}

	function showInfoUser() {
		if (user) {
			const auth = {
				user: {
					firstName: `${user?.first_name} ${user?.last_name}`,
				},
				userRole: {
					name: user?.is_superadmin
						? t("user.superAdmin")
						: user?.is_admin
							? t("user.admin")
							: t("user.user"),
				},
			};
			return auth;
		} else {
			return null;
		}
	}

	const goBack = {
		title: t("sidebar.goBack"),
		tooltip: t("sidebar.goBack"),
		icon: <ReplyIcon />,
		path: "/map",
		fromLocation: fromLocation,
	};


	const notificationsCount=notifications?.count || 0;
	const notification: ISidebarNotification = {
		tooltip: t("sidebar.notifications"),
		count: notificationsCount,
		icon: <NotificationImportantIcon color='primary' />,
		notifications: notifications?.notifications || [],
		seeAllText: t("common.seeAll"),
		seeAllLink: "/notifications",
		noData: notificationsCount > 0 ? undefined : <NoData />
	}

	return {
		logo: logo,
		smallLogo: smallLogo,
		items: publicPortal
			? showPublicItems(menuItems)
			: filterHasPermissions(menuItems),
		auth: showInfoUser(),
		menuUser: user ? menuUser : null,
		extraLogos: [],
		goBack: publicPortal && user ? goBack : null,
		onSidebarOpen: (isOpen: boolean) => dispatch(setSidebarIsOpen(isOpen)),
		notification: notification
	};
};
