/* eslint-disable react-hooks/exhaustive-deps */
import { TableHeadOrder } from "@alb/live-lib";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import { FiltersSection } from "./FiltersSection";
import { OccurrencesTable } from "./OccurrencesTable";

const OccurrencesList = (props: { refetch?: boolean; setRefetch: any, selectedRange: any }) => {
	const { refetch, setRefetch, selectedRange } = props;
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("-created_at");

	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");
	const [filterUrgency, setFilterUrgency] = useState<string>("");

	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
	const { t } = useTranslation();

	const {
		data: occurrences,
		loading,
		error,
		refetch: refetchOccurrences,
	} = useGet(ServiceApiUrl.occurrences, GetParams(), undefined, headers);

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			contains?: string;
			status?: string;
			urgency?: string;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(selectedRange && {
				start_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
			}),
			...(filterSearch !== "" && { contains: filterSearch }),
			...(filterStatus !== "" && { status: filterStatus }),
			...(filterUrgency !== "" && { urgency: filterUrgency }),
		};
		return params;
	}

	useEffect(() => {
		if (refetch) refetchOccurrences();
	}, [refetch]);

	let occurrencesList = <NoData error={error} />;

	if (loading) {
		occurrencesList = <Loading />;
	}

	if (!loading && occurrences && !arrayIsEmpty(occurrences.data)) {
		occurrencesList = (
			<OccurrencesTable
				data={occurrences}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetchOccurrences}
				setRefetch={setRefetch}
			/>
		);
	}

	return (
		<Grid item mt={4}data-testid={"OccurrencesListContainer"}>
			<FiltersSection
				setPage={setPage}
				setFilterSearch={setFilterSearch}
				setFilterStatus={setFilterStatus}
				setFilterUrgency={setFilterUrgency}
			// setFilterDateRange={setFilterDateRange}
			/>
			{occurrencesList}
		</Grid>
	);
};

export default OccurrencesList;
