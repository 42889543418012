import { format, subDays } from "date-fns";
import { enGB, pt } from "date-fns/locale";

import { I18NEXT } from "utils/keys";

import { getCookie } from "./cookie";

export function getLocale() {
	const storedLang = getCookie<string>(I18NEXT) || "en";

  const locales = { en: enGB, pt };
  const locale = locales[storedLang as keyof typeof locales] || enGB;

  return locale;
}
export function formatDate(
  date: Date | number | string | string[] | undefined,
  formatStr = "PP"
) {
  if (!date) return "--";

  const dt =
    date && Array.isArray(date)
      ? new Date(date[0])
      : date
      ? new Date(date)
      : new Date();
  return format(dt, formatStr, {
    locale: getLocale(),
  });
}

export function getLastWeekRangeDates(formatStr = "PP") {
  const startDate = format(subDays(new Date(), 5), formatStr, {
    locale: getLocale(),
  });
  const endDate = format(subDays(new Date(), 1), formatStr, {
    locale: getLocale(),
  });

  return {startDate, endDate};
}

export function dateTimeFormat(date: Date | number | string) {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");
}

export function dateTimeFormatLocal(date: Date | number | string) {
  return format(new Date(date), "yyyy-MM-dd HH:mm");
}
export function nowDateTime(formatStr = "yyyy/MM/dd HH:mm"): string {
  const dt = new Date();
  return format(dt, formatStr, {
    locale: getLocale(),
  });
}

export function getLocaleMonth(month: number) {
  return getLocale().localize?.month(month); // { width: 'abbreviated' }
}
