import { Loading } from "@alb/live-lib";
import { Box, Card, CardContent, Grid, SvgIcon, SxProps, Theme, Typography } from "@mui/material";
import { AxiosError } from "axios";
import React from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import styles from "styles/modules/modules.module.scss";
import { formatNumber } from "utils/number";
import { arrayIsEmpty } from "utils/utils";

import { moduleCardsIcons } from "./cards/moduleCardsIcons";

export interface IModuleCardItem {
  name: string;
  value?: number | string;
  type: string;
  values?: { name: string; value: number }[];
}
interface IModuleCards {
  module:
    | "events"
    | "occurrences"
    | "electricMobility"
    | "hydrogenSupply"
    | "urbanWaste"
    | "cerManagement"
    | "mobility";
  items: IModuleCardItem[] | undefined;
  loading: boolean;
  error: AxiosError<any, any> | null;
  sx?: SxProps<Theme>;
  dataTestId?: string;
}
export const ModuleCards = ({
  module,
  items,
  loading,
  error,
  sx,
  dataTestId,
}: IModuleCards) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {loading ? (
        <Loading show={loading} />
      ) : (
        <Box
          data-testid={dataTestId}
          sx={{
            ...sx,
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {(!items || arrayIsEmpty(items) || error) && (
              <Grid item xs={12}>
                <NoData error={error} />
              </Grid>
            )}
            {items &&
              !arrayIsEmpty(items) &&
              !error &&
              items.map((item: IModuleCardItem, index: number) => {
                if (item.name === undefined)
                  return <React.Fragment key={index}></React.Fragment>;
                return (
                  <Grid item xs={12} sm={12} md={6} xl={3} key={index}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent className={styles["card-content"]}>
                        {moduleCardsIcons[item?.name] && (
                          <SvgIcon
                            titleAccess={
                              i18n.exists(`${module}.state.${item.name}`)
                                ? t(`${module}.state.${item.name}`)
                                : item.name
                            }
                            sx={{ mr: 2, fontSize: "48px" }}
                            color="primary"
                            component={moduleCardsIcons[item?.name]}
                            inheritViewBox
                          />
                        )}
                        <Box>
                          <Typography variant="h3" fontSize={"22px"}>
                            {i18n.exists(`${module}.state.${item.name}`)
                              ? t(`${module}.state.${item.name}`)
                              : item.name}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {t(`common.${item.type}`)}
                          </Typography>
                          <Typography data-testid={item.name} variant="h2" fontSize={"32px"}>
                            {item?.value && formatNumber(item?.value)}
                          </Typography>
                          {item?.values?.map(
                            (
                              subItem: { name: string; value: number },
                              index: number
                            ) => (
                              <Typography
                                display={"inline-block"}
                                mr={subItem.name === "minutes" ? 0 : 2}
                                variant="h3"
                                fontSize={"20px"}
                                key={index}
                                fontWeight={"normal"}
                              >
                                {subItem.name === "minutes" ||
                                subItem.name === "seconds" ? (
                                  <>
                                    {subItem.name === "seconds" && ":"}
                                    <b>{formatNumber(subItem?.value)}</b>
                                  </>
                                ) : (
                                  <>
                                    {i18n.exists(
                                      `${module}.state.${subItem.name}`
                                    )
                                      ? t(`${module}.state.${subItem.name}`)
                                      : subItem.name}
                                    : <b>{formatNumber(subItem?.value)}</b>
                                  </>
                                )}
                              </Typography>
                            )
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}
    </>
  );
};
