/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { DrawOption, IActionControl, IDrawCoordinates, IMap, Map, MapMode, TileType, useFeedback } from "@alb/live-lib";
import { SubmitHandler } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import useCreate from "hooks/fetchData/useCreate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import {
  selectCoords,
  selectDrawingMap,
  setDrawingMap,
  setDrawnCoords,
} from "store/slices/mapSlice";
import { LatLngExpression } from "leaflet";
import OccurrenceForm from "./OccurrenceForm";
import { IOccurrenceForm } from "types/interfaces";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

interface IOccurrenceAdd {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const OccurrenceAdd = ({ open, onClose, onSuccess }: IOccurrenceAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const addFormId = "add-occurrences-form";
  const user = useSelector(getUser);
  const drawingMap = useSelector(selectDrawingMap);
  const dispatch = useDispatch<any>();
  const darkMode = user?.extra_params?.dark_mode
  const drawCoords = useSelector(selectCoords);

  let mapCenter: LatLngExpression | undefined;
  if (user && user.client.center_point) {
    mapCenter = {
      lat: user.client.center_point[0],
      lng: user.client.center_point[1],
    };
  }

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  const [formIsValid, setFormIsValid] = useState(false);

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  //pedido para criar a ocorrência
  const { loading, refetch: createOccurrence } = useCreate(
    ServiceApiUrl.occurrences,
    undefined,
    headers
  );

  const handlerSuccess = () => {
    onClose(); //fecha modal
    addFeedback({
      message: t("occurrences.occurrenceAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  //ao submeter o formulário
  const onSubmit: SubmitHandler<IOccurrenceForm> = async (
    payLoad: IOccurrenceForm
  ) => {
    payLoad.geolocation = drawCoords;

    if(payLoad.assignee?.assignee_id && payLoad.assignee?.assignee_type) {
      payLoad.assignee_id = payLoad.assignee?.assignee_id;
      payLoad.assignee_type= payLoad.assignee?.assignee_type;
    }


    try {
      payLoad.geolocation = payLoad.geolocation.coordinates;
      payLoad.channel = payLoad?.channel?.value;

      await createOccurrence({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  // drawcoords de backup para caso se cancele o q se fez no mapa
  const drawCoordsBKRef = useRef<IDrawCoordinates | null>();

  const coordsRef = useRef<IDrawCoordinates | null>();

  const handleDrawCoordinate = (coordinates: IDrawCoordinates | null) => {
    // dispatch(setDrawnCoords(coordinates));

    if (coordinates) {
      let auxCoordinates: IDrawCoordinates = {
        coordinates: coordinates?.coordinates,
        type: coordinates.type,
        iconPosition: coordinates?.coordinates,
      };
      coordsRef.current = auxCoordinates;
      return;
    }

    coordsRef.current = coordinates;
  };

  const [mapAction, setMapAction] = useState<
    { action: "cancel" | "confirm" } | undefined
  >(undefined);

  const actionCancel = () => {
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(drawCoordsBKRef.current));
    setTimeout(() => {
      setMapAction({ action: "cancel" });
    }, 0);
  };
  const actionConfirm = () => {
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(coordsRef.current));
    setTimeout(() => {
      setMapAction({ action: "confirm" });
    }, 0);
  };

  const actionsCtrl: IActionControl[] = [
    {
      action: actionCancel,
      icon: <CloseIcon />,
      title: t("map.buttons.cancel"),
    },
    {
      action: actionConfirm,
      icon: <CheckIcon />,
      title: t("map.buttons.confirm"),
    },
  ];

  //ao abrir o mapa, guarda sempre os valores anteriores
  useEffect(() => {
    if (drawingMap) {
      drawCoordsBKRef.current = drawCoords;
    }
  }, [drawingMap]);

  const mapTile = user.extra_params?.map_tile_layer || TileType.default;

  const mapArgs: IMap = {
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: {
      buttons: {
        layers: t("map.buttons.layers"),
        selectAreas: t("map.buttons.selectAreas"),
        edit: t("map.buttons.edit"),
        remove: t("map.buttons.remove"),
        marker: t("map.buttons.marker"),
        circle: t("map.buttons.circle"),
        rectangle: t("map.buttons.rectangle"),
        polygon: t("map.buttons.polygon"),
        polyline: t("map.buttons.polyline"),
        recenter: t("map.buttons.recenter"),
        delete: t("map.buttons.remove"),
        cancel: t("map.buttons.cancel"),
        close: t("close"),
      },
      date: {
        dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
      },
      leafletDraw: {
        removeShapeConfirmation: t("leafletDraw.removeShapeConfirmation"),
        removeShape: t("leafletDraw.removeShape"),
        dragToEdit: t("leafletDraw.dragToEdit"),
        clickCancelToUndo: t("leafletDraw.clickCancelToUndo"),
        clickToRemove: t("leafletDraw.clickToRemove"),
        howToDrawCircle: t("leafletDraw.howToDrawCircle"),
        howToDrawCircleMarker: t("leafletDraw.howToDrawCircleMarker"),
        howToDrawMarker: t("leafletDraw.howToDrawMarker"),
        howToDrawPolygon: t("leafletDraw.howToDrawPolygon"),
        howToDrawRectangle: t("leafletDraw.howToDrawRectangle"),
      },
      common: {
        noData: t("common.noData"),
      },
    },
    mapCenterPoint: mapCenter,
    initialMapCenter: mapCenter,
    mapZoom: 13,
    initialZoom: 13,
    mapTileType: mapTile,
    mapVariant: "dialog",
    points: [],
    mapModule: false,
    showZoom: true,
    mapMode: MapMode.edit,
    drawCoordinates: drawCoords,
    onDrawCoordinatesChange: handleDrawCoordinate,
    drawOptions: [DrawOption.marker],
    actionsControls: actionsCtrl,
    appDarkThemeMode:darkMode,
  };

  return (
    <>
      <Dialog
        maxWidth="xl"
        fullWidth
        sx={{
          ".MuiPaper-root": {
            padding: 0,
            display: "flex",
            flexDirection: "row",
          },
        }}
        open={open}
      >
        <Grid container sx={{ overflow: "auto" }}>
          <Grid item xs={true} sx={{ padding: "0 10px 0 20px" }}>
            <DialogTitle>
              <Typography
                component={"span"}
                gutterBottom={true}
                variant="h5"
                noWrap
              >
                {t("occurrences.addOccurrence")}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={onClose}
                size="small"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  zIndex: 500,
                }}
                title={t("close")}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent
              style={{
                paddingTop: "0",
                paddingRight: "10px",
              }}
            >
              <OccurrenceForm
                formId={addFormId}
                onFormSubmit={onSubmit}
                setFormIsValidStatus={getFormIsValidStatus}
                mapAction={mapAction}
              />
            </DialogContent>
            <DialogActions
              style={{
                paddingRight: "10px",
              }}
            >
              <Button variant="outlined" color="primary" onClick={onClose}>
                {t("cancel")}
              </Button>

              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                type={"submit"}
                disableElevation
                disabled={!formIsValid}
                form={addFormId}
              >
                {t("common.add")}
              </LoadingButton>
            </DialogActions>
          </Grid>
        </Grid>
        {drawingMap && (
          <Grid container sx={{ overflow: "hidden" }}>
            <Box className="map-component">
              <Map {...mapArgs} />
            </Box>
          </Grid>
        )}
      </Dialog>
    </>
  );
};
