import { Loading } from "@alb/live-lib";
import { Box, PaletteColor, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
/* eslint-disable react-hooks/exhaustive-deps */
import ReactEcharts from "echarts-for-react";

import NoData from "components/Utils/NoData";
import { formatNumber } from "utils/number";
import { arrayIsEmpty } from "utils/utils";

interface IChartData {
  name: string;
  value: string | number;
}
export interface IChartInfo {
  title: string;
  description?: string;
  chartType?: "bar" | "line";
  data: IChartData[];
  unit?: string;
  color?: PaletteColor;
  hideXAxisLabel?: boolean;
  legendName?: string;
  loading: boolean;
  testId?:string;
  error: AxiosError<any, any> | null;
}

const ChartWidget = ({
  title,
  description,
  chartType = "bar",
  data,
  unit = "",
  color,
  hideXAxisLabel,
  legendName = "",
  loading,
  testId,
  error
}: IChartInfo) => {
  const theme = useTheme();

  if (!color) color =theme.palette.primary
  
  //chart options
  var option = {
    tooltip: {
      confine: true,
      extraCssText: 'white-space:inherit;',
      trigger: "item",
      formatter: function (value: any) {
        return `
        ${value.name}  <br/>
        <b> ${value.seriesName}: </b> ${formatNumber(value.value)} ${unit} <br/>`;
      },
    },
    textStyle: {
      fontFamily: "Altice",
      fontSize: 13,
    },
    xAxis: {
      type: "category",
      data: data?.map((d: { name: string; value: string | number }) => d.name),
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: !hideXAxisLabel,
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
        }
      }
    },
    grid: {
      left: "2%",
      right: "5%",
      containLabel: true,
    },
    color: color["main"],
    yAxis: {
      type: "value",
			axisLabel: {
				formatter: (val:any) => formatNumber(val)
			},
      name: unit,
      nameTextStyle: {
        padding: [0, 30, 0, 0],
      },
      axisLine: {
        lineStyle: {
          color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
        }
      }
    },
    legend: {
      show: true,
      orient: "horizontal",
      bottom: 0,
      textStyle: {
        color: theme.palette.text.primary,
        fontFamily: "Altice",
        fontSize: 13,
      },
      itemHeight: 16,
      itemWidth: 16,
      icon: "circle",
      itemGap: 25,
      left: 0,
    },
    series: [
      {
        itemStyle: {
          borderRadius: [5, 5, 5, 5],
        },
        emphasis: {
          itemStyle: {
            color: color["dark"],
          },
        },
        name: legendName + (unit && ` (${unit})`),
        data: data,
        type: chartType,
      },
    ],
  };

  return (
    <Box data-testid={testId}>
      <Typography variant="h3" fontSize={"22px"}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>

      <Loading show={loading} />
      {!arrayIsEmpty(data) && !loading && (
        <ReactEcharts style={{ height: "308px" }} option={option} />
      )}
      {arrayIsEmpty(data) && !loading && <NoData error={error}/>}
    </Box>
  );
};

export default ChartWidget;
