/* eslint-disable react-hooks/exhaustive-deps */
import {
  InputAutocomplete,
  InputDateTime,
  InputSelect,
  InputText,
  Loading,
  RequiredField,
} from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOccurrenceForm } from "types/interfaces";
import {
  selectCoords,
  selectDrawingMap,
  setDrawingMap,
  setDrawnCoords,
} from "store/slices/mapSlice";
import { arrayIsEmpty } from "utils/conditions";

import { RequiredFields } from "components/Utils/RequiredField";
import { useStatusOptions } from "./hooks/useStatusOptions";
import { useCategoriesOptions } from "./hooks/useCategoriesOptions";
import { formDefaultValues } from "./formDefaultValues";
import { getOccurrenceFormSchema } from "./validations";
import useUsersOptions from "./hooks/useUsersOptions";
import useUrgencyOptions from "./hooks/useUrgencyOptions";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import useGroupOptions from "./hooks/useGroupOptions";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { getSelectedModule } from "utils/modules/modules";
import { getUser } from "store/slices/authSlice";


const DoubleSelect = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
  alignItems: "baseline",
  border: "1px solid",
  borderColor: theme.palette.greyColor.light,
  "&:hover": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  "&.focused": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
}));

const OccurrenceForm = (props: {
  formId: string;
  data?: IOccurrenceForm;
  onFormSubmit: (payLoad: IOccurrenceForm, location: any) => void;
  setFormIsValidStatus?: (status: boolean) => void;
  mapAction: { action: "cancel" | "confirm" } | undefined;
}) => {
  const { formId, data, onFormSubmit, setFormIsValidStatus, mapAction } = props;

  const { t, i18n } = useTranslation();
  const coords = useSelector(selectCoords);
  const defaultValues = formDefaultValues(data);
  const dispatch = useDispatch();
  const drawingMap = useSelector(selectDrawingMap);
  const theme = useTheme();

  const methodsForm = useForm<IOccurrenceForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getOccurrenceFormSchema(data)),
  });
  const {
    control,
    handleSubmit,
    resetField,
    getValues,
    trigger,
    watch,
    formState: { errors: filterErrors, dirtyFields },
  } = methodsForm;

  const [selectedDates, setSelectedDates] = useState({
    start_date: undefined,
    end_date: undefined,
  });

  const { statusOptions, loading: loadingStatusOptions } = useStatusOptions();
  //const { typeOptions } = useTypeOptions();
  const user = useSelector(getUser)
  const moduleID = getSelectedModule(user, MODULES_KEYS.OCCURRENCES)
  
  const { categoriesOptions, loading: loadingCategoriesOptions } = useCategoriesOptions();
  const { usersOptions, loading: loadingUserOptions } = useUsersOptions(moduleID);
  const { groupOptions, loading: loadingGroupOptions } = useGroupOptions();
  const { urgencyOptions, loading: loadingUrgencyOptions } = useUrgencyOptions();

  const [assigneeType, setAssigneeType] = useState<string>();
  const [assigneeOptions, setAssigneeOptions] = useState<TOption[]>([]);

  const channelOptions = [
    { label: t("occurrences.channelTypes.phone"), value: "Telefone" },
    { label: t("occurrences.channelTypes.email"), value: "Email" },
    { label: t("occurrences.channelTypes.online"), value: "Online" },
  ];
  

  //guarda o valor final da localização
  const [location, setLocation] = useState<string | any>([]);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IOccurrenceForm> = (
    payLoad: IOccurrenceForm
  ) => {
    onFormSubmit(payLoad, location);
  };

  //para enviar as coordenadas iniciais para serem desenhadas, se for um edit
  useEffect(() => {
    if (data) {
      setLocation(data.geolocation);
      dispatch(setDrawnCoords(data.geolocation));
      if (data?.assignee?.assignee_type)
        setAssigneeType(data?.assignee?.assignee_type);
    } else {
      resetField("assignee.assignee_type", {
        defaultValue: AssigneeTypeOptions[0].value,
      });
      setAssigneeType(AssigneeTypeOptions[0].value);
    }
  }, []);

  useEffect(() => {
    if (mapAction) {
      trigger("geolocation");
    }
  }, [mapAction]);

  useEffect(() => {
    let _location = location.iconPosition
      ? JSON.stringify(location.iconPosition)
      : "";
    resetField("geolocation", {
      defaultValue: _location,
    });
  }, [location]);

  //sempre que for criada uma shape, deve colocar o valor no input, pelo menos temporariamente, até dar check
  useEffect(() => {
    if (coords) {
      setLocation(coords || []);
    } else {
      // if (drawingMap) {
      setLocation([]);
      // }
    }
  }, [coords]);

  const handleDateOnChange = (date: Date | null, positionDate: string) => {
    setSelectedDates({
      ...selectedDates,
      [positionDate]: date,
    });
  };

  const watchStartDate = watch("start_date");
  const watchEndDate = watch("end_date");
  useEffect(() => {
    // o touchedfield não está a funcionar com o input date time :(...
    // if (!touchedFields.start_date) return;
    if (dirtyFields.end_date) {
      trigger("start_date"); // re validate field
      trigger("end_date"); // re validate field
    }
    // trigger('end_date'); // re validate field
    setTimeout(() => {
      if (!filterErrors.start_date && !filterErrors.end_date) {
        handleDateOnChange(watchStartDate, "start_date");
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartDate]);
  useEffect(() => {
    if (dirtyFields.start_date) {
      trigger("start_date"); // re validate field
      trigger("end_date");
    }
    // trigger('start_date'); // re validate field
    setTimeout(() => {
      if (!filterErrors.end_date) {
        handleDateOnChange(watchEndDate, "end_date");
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndDate]);

  //Sempre que os methodsForm.valid alterar, envia os dados
  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid]);

  // const prevDrawingMapRef = useRef<boolean>(false);
  // useEffect(() => {
  // 	prevDrawingMapRef.current = drawingMap;

  // 	return () => {
  // 		if (prevDrawingMapRef.current) {
  // 			// console.log("trigger geolocation...")
  // 			trigger("geolocation");
  // 		}
  // 		prevDrawingMapRef.current = false;
  // 	}
  // }, [drawingMap])

  //ao selecionar para abrir, troca o mapa
  function changeMap() {
    const inputLocation = getValues("geolocation");
    if (coords && inputLocation) {
      const newCoords = { ...coords, coordinates: JSON.parse(inputLocation) };
      dispatch(setDrawnCoords(newCoords));
    } else {
      dispatch(setDrawnCoords(null));
    }
    // ****************
    dispatch(setDrawingMap(!drawingMap)); //troca o mapa
  }

  const locationInputProps = {
    classes: { inputMultiline: "pointer" },
    readOnly: true,
    onFocus: changeMap,
  };

  const AssigneeTypeOptions: TOption[] = [
    {
      label: t("user.user"),
      value: "user",
    },

    {
      label: t("occurrences.team"),
      value: "usergroup",
    },
  ];

  const handleAssigneTypeChange = (e: any) => {
    setAssigneeType(e.target.value);
  };

  useEffect(() => {
    //se for a criar ocorrências ou a editar mas não tem assignee, coloca o tipo "user" por default
    if (!data || (data && !data?.assignee?.assignee_type)) {
      resetField("assignee.assignee_type", {
        defaultValue: AssigneeTypeOptions[0].value,
      });
      setAssigneeType(AssigneeTypeOptions[0].value);
    }
  }, [loadingUserOptions]);

  useEffect(() => {
    if (assigneeType === "user") {
      setAssigneeOptions(usersOptions);
    }
    if (assigneeType === "usergroup") setAssigneeOptions(groupOptions);
  }, [assigneeType, usersOptions]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <FormGroup>
              <Grid container spacing={2}>
                {/* {data && (
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.name")}
                    </Typography>

                    <InputText
                      placeholder={t("occurrences.namePlaceholder")}
                      name="name"
                      type="text"
                      control={control}
                    />
                  </Grid>
                )} */}
                {/* <Grid item xs={6}>
                <Typography gutterBottom variant="h6">
                  {t("occurrences.type")}
                </Typography>
                {typeOptions && !arrayIsEmpty(typeOptions) && (
                  <InputSelect
                    noOptionsText={t("common.noOptions")}
                    placeholder={t("occurrences.typePlaceholder")}
                    name="type"
                    control={control}
                    options={typeOptions}
                  />
                )}
              </Grid> */}
                <Grid item xs={6}>
                  <RequiredField title={t("occurrences.category")} />
                  {categoriesOptions && !arrayIsEmpty(categoriesOptions) && (
                    <InputSelect
                      noOptionsText={t("common.noOptions")}
                      placeholder={t("occurrences.categoryPlaceholder")}
                      name="category"
                      control={control}
                      options={categoriesOptions}
                    />
                  )}
                  {loadingCategoriesOptions && <Loading show={true} />}
                </Grid>
                <Grid item xs={6}>
                  <RequiredField title={t("occurrences.startDate")} />
                  <InputDateTime
                    name="start_date"
                    control={control}
                    maxDate={
                      selectedDates?.end_date
                        ? new Date(selectedDates.end_date)
                        : undefined
                    }
                    handleOnChange={(date: Date | null) => {
                      handleDateOnChange(date, "start_date");
                    }}
                    locale={i18n.language}
                    labelTextTime={t("calendar.time")}
                    showTimeInput
                    labelBtnCancel={t("cancel")}
                    labelBtnConfirm={t("apply")}
                    placeholder={t("calendar.placeholderDateTime")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.expectedResolutionDate")}
                  </Typography>
                  <InputDateTime
                    name="end_date"
                    control={control}
                    minDate={
                      selectedDates?.start_date
                        ? new Date(selectedDates.start_date)
                        : undefined
                    }
                    handleOnChange={(date: Date | null) => {
                      handleDateOnChange(date, "end_date");
                    }}
                    locale={i18n.language}
                    labelTextTime={t("calendar.time")}
                    showTimeInput
                    labelBtnCancel={t("cancel")}
                    labelBtnConfirm={t("apply")}
                    placeholder={t("calendar.placeholderDateTime")}
                    clearButton
                  />
                </Grid>

                <Grid item xs={6}>
                  <RequiredField
                    title={t("occurrences.coordinates")}
                    sx={{
                      verticalAlign: "middle",
                      display: "inline-flex",
                      gap: 0.5,
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={t("occurrences.chooseToSelectOnMap")}
                    >
                      <HelpOutlineIcon sx={{ fontSize: 20 }} color="primary" />
                    </Tooltip>
                  </RequiredField>
                  <InputText
                    multiline
                    minRows={2}
                    maxRows={5}
                    disabled={drawingMap}
                    placeholder={t("occurrences.locationPlaceholder")}
                    name="geolocation"
                    type="text"
                    //disabled={true}
                    control={control}
                    {...(!drawingMap && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t("occurrences.selectLocationOnMap")}>
                            <IconButton onClick={changeMap} sx={{ padding: 0 }}>
                              <LocationSearchingIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    })}
                    InputProps={locationInputProps}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.address")}
                  </Typography>
                  <InputText
                    placeholder={t("occurrences.addressPlaceholder")}
                    name="address"
                    type="text"
                    control={control}
                  />
                </Grid>

                
                {data && (
                  <Grid item xs={6}>
                    <RequiredField title={t("occurrences.status")} />
                    {statusOptions && !arrayIsEmpty(statusOptions) && (
                      <InputSelect
                        noOptionsText={t("common.noOptions")}
                        placeholder={t("occurrences.statusPlaceholder")}
                        name="status"
                        control={control}
                        options={statusOptions}
                      />
                    )}
                    {(loadingStatusOptions || loadingGroupOptions) && <Loading show={true} />}
                  </Grid>
                )}
        

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.description")}
                  </Typography>
                  <InputText
                    placeholder={t("occurrences.descriptionPlaceholder")}
                    name="description"
                    type="text"
                    multiline
                    rows={2}
                    control={control}
                  />
                </Grid>

                {/* <Grid item xs={6}>
                <Typography gutterBottom variant="h6">
                  {t("occurrences.subject")}
                </Typography>

                <InputText
                  placeholder={t("occurrences.subjectPlaceholder")}
                  name="subject"
                  type="text"
                  control={control}
                />
              </Grid> */}

                {/* {data && (
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.statusDate")}
                    </Typography>
                    <InputDateTime
                      name="status_date"
                      control={control}
                      locale={i18n.language}
                      labelTextTime={t("calendar.time")}
                      showTimeInput
                      labelBtnCancel={t("cancel")}
                      labelBtnConfirm={t("apply")}
                      placeholder={t("calendar.placeholderDateTime")}
                      clearButton
                    />
                  </Grid>
                )} */}

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.occurrenceURL")}
                  </Typography>
                  <InputText
                    placeholder={t("occurrences.occurrenceURLPlaceholder")}
                    name="url"
                    type="text"
                    control={control}
                  />
                </Grid>


                <Grid item xs={6}>
                <Typography gutterBottom variant="h6">
                  {t("occurrences.urgency")}
                </Typography>
                {!loadingUrgencyOptions && (
                    <InputSelect
                    noOptionsText={t("common.noOptions")}
                    placeholder={t("occurrences.urgencyPlaceholder")}
                    name="urgency"
                    control={control}
                    options={urgencyOptions}
                  />
                )}
                {loadingUrgencyOptions && <Loading show={true} />}
              </Grid>
              
              {/* {data && ( */}
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.assignUser")}
                    </Typography>
                    {/*<InputSelect
                    noOptionsText={t("common.noOptions")}
                    placeholder={t("occurrences.statusPlaceholder")}
                    name="assignuser"
                    control={control}
                    options={usersOptions}
                />*/}
                {(!loadingUserOptions && !loadingGroupOptions) &&
                    <DoubleSelect className="fullWidth">
                      <Grid item xs={4}>
                        <InputSelect
                        noOptionsText={t("common.noOptions")}
                          options={AssigneeTypeOptions}
                          name={"assignee.assignee_type"}
                          control={control}
                          sx={{
                            "& .MuiSelect-select": {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: '10px',
                              color: theme.palette.common.white,
                              margin: "3px",
                              padding: "6px 11px",
                              "&:focus": {
                                borderRadius: '10px',
                              },
                            },
                            "& .MuiSelect-icon": {
                              color: theme.palette.common.white,
                            },
                          }}
                          handleOnChange={handleAssigneTypeChange}
                        />
                      </Grid>
                      <Grid item xs={true} sx={{overflow:'hidden'}}>
                        <InputSelect
                        noOptionsText={t("common.noOptions")}
                        name="assignee.assignee_id"
                        placeholder={t("occurrences.assignPlaceholder")}
                        options={assigneeOptions}
                        control={control}
                        />
                      </Grid>
                    </DoubleSelect>
                    }
                    {loadingUserOptions && <Loading show={true} />}
                  </Grid>
              {/* )} */}
    

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.reporter")}
                  </Typography>
                  <InputText
                    placeholder={t("occurrences.reporterPlaceholder")}
                    name="reporter"
                    type="text"
                    control={control}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.channel")}
                  </Typography>
                  {channelOptions && !arrayIsEmpty(channelOptions) && (
                    <InputAutocomplete
                      noAllowedWrite
                      placeholder={t("occurrences.channelPlaceholder")}
                      name="channel"
                      control={control}
                      options={channelOptions}
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.notes")}
                  </Typography>
                  <InputText
                  placeholder={t("occurrences.notesPlaceholder")}
                    name="notes"
                    type="text"
                    control={control}
                    multiline
                    rows={2}
                  />
                </Grid>
              </Grid>
          </FormGroup>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};

export default OccurrenceForm;
