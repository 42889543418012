/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "@alb/live-lib";
import { Box, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { defaultOptions } from "components/charts/defaultOptions";
import { ReactECharts as DoughnutChart, IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getNameClient } from "store/slices/authSlice";
import { getFilterDateRange, getGeojsonType, getSelectedAnalysisDetail, getSelectedParishArea } from "store/slices/mapSlice";
import { getPresenceChart, setPresenceChart } from "store/slices/peopleMobilitySlice";
import styles from "styles/modules/map/list.module.scss";
import { arrayIsEmpty } from "utils/conditions";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { getIdClientVisits } from "utils/utils";

export const CardPresence = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const nameClient = useSelector(getNameClient);
  const idParish = useSelector(getSelectedParishArea).id;
  const dateRange = useSelector(getFilterDateRange);
  const geojsonTypeMap = useSelector(getGeojsonType);
  const analysisDetail = useSelector(getSelectedAnalysisDetail);
  const data = useSelector(getPresenceChart);

  const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});
  const unitChart = t("map.filter.types.visits").toLowerCase();

  const {
    data: dataPresence,
    loading,
    error,
    refetch,
  } = useGet(
    ServiceApiUrl.presenceTourism,
    {
      start_date: formatDate(
        dateRange.startDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      end_date: formatDate(
        dateRange.endDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      city_id: idParish ? idParish : getIdClientVisits(nameClient),
    },
    {
      manual: true,
    }
  );

  const optionsDoughnutChart = (values: any) => {
    const dataChart = values.map((value: any) => {
      return {
        ...value,
        name: t("map.detail." + value.name) + " (" + unitChart + ")",
      };
    });
    return {
      color: defaultOptions.color,
      legend: {
        ...defaultOptions.legend,
        ...{
          textStyle: {
            color: theme.palette.text.primary,
            fontFamily: "Altice",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        confine: true,
      	extraCssText: 'white-space:inherit;',
        trigger: "item",
        valueFormatter: function (value: any) {
          return value ? formatNumber(value) + " " + unitChart : "--";
        },
      },
      series: [
        {
          name: t("map.detail.presence"),
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: theme.palette.background.paper,
            borderWidth: 2,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
          data: dataChart,
        },
      ],
    } as IReactECharts["option"];
  };

  useEffect(() => {
    if (geojsonTypeMap !== "analysis" && !analysisDetail) {
      refetch();
    } else if (geojsonTypeMap === "analysis" && analysisDetail) {
      setChartOptions(optionsDoughnutChart(data));
    }
  }, [dateRange, analysisDetail]);

  useEffect(() => {
    if (!loading && dataPresence) {
      dispatch(setPresenceChart(dataPresence));
      setChartOptions(optionsDoughnutChart(dataPresence));
    }
  }, [dataPresence]);

  useEffect(() => {
    if (error) {
      dispatch(setPresenceChart(null));
    }
  }, [error]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent className={styles["list-detail__card"]}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="body1">
                {t("map.detail.presence")}
              </Typography>
              <Typography fontSize="14px" mb={"1.5px"} mt="auto">
                &ensp; ({t("sum")})
              </Typography>
            </Box>
            <Loading show={loading} />
            {!arrayIsEmpty(data) && !loading && (
              <DoughnutChart
                option={chartOptions}
                style={{ minHeight: "300px" }}
              />
            )}
            {arrayIsEmpty(data) && !loading && <NoData error={error} />}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
