/* eslint-disable react-hooks/exhaustive-deps */
import { colorsMarkers, DrawOption, getBoundsVisibleArea, HeatmapOptions, IActionControl, IMap, IMapPoint, LayoutContext, Map as MainMap, MapType, TileType, useFeedback } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import UploadIcon from "@mui/icons-material/Upload";
import { Badge, useTheme } from "@mui/material";
import { format } from "date-fns";
import subDays from "date-fns/subDays";
import { LatLngExpression } from "leaflet";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IGeojsonFeatures, IGeojsonFeaturesCollection, ISelectedParishArea } from "types/interfaces";
import { TMarker } from "types/types";
import { v4 as uuid } from "uuid";

import chaves from "assets/cmchaves.json";
import { EventAdd } from "components/events/EventAdd";
import MarkerInfoComponent from "components/map-entity-info/MarkerInfoComponent";
import { AnalysisTab } from "components/map/controls/analysisTab/AnalysisTab";
import { FileUpload } from "components/map/controls/FileUpload";
import { FilterTab } from "components/map/controls/FilterMapTab/FilterTab";
import useGetFiltersCount from "components/map/controls/FilterMapTab/hooks/useGetFiltersCount";
import { MapLegend } from "components/map/controls/MapLegend";
import { ListUploadFiles } from "components/map/list/ListUploadFiles";
import { MapListContainer } from "components/map/list/MapListContainer";
import NotificationFeed, { IDeviceNotification } from "components/map/notifications/NotificationFeed";
import { convertCoordsArray, convertCoordsToGeojson, dateRangeDownload, downloadFile, IParamsDownload } from "components/map/utils/DownloadGeojson";
import { OccurrenceAdd } from "components/occurrences/OccurrenceAdd";
import useGet from "hooks/fetchData/useGet";
import useUpdate from "hooks/fetchData/useUpdate";
import useDidMountEffect from "hooks/useDidMountEffect";
import useFetchMarkers from "hooks/useFetchMarkers";
import useMarkers from "hooks/useMarkers";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectDeviceRealTime, selectDevices, setDeviceRealTime, setDevices, setOneDeviceMap } from "store/slices/adapterConfiguratorsSlice";
import { setDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { getNameClient, getUser, setUser } from "store/slices/authSlice";
import { getTextSearchBar, setTextSearchBar } from "store/slices/dashboardSlice";
import { getEvents, setEvents } from "store/slices/eventsSlice";
import { setEventsFilterParams } from "store/slices/eventsSlice";
import { getFilterMenuMarkers, getFitBounds, getGeojsonFilesUpload, getGeojsonType, getMapBounds, getMarkerDetails, getSelectedAnalysis, getSelectedAnalysisDetail, getSelectedMapType, getSelectedParishArea, getShapeBounds, getSidebarIsOpen, IFilterDateRangeMap, selectDrawingMap, selectFiltersTabOpen, selectMarkers, selectOpenList, setBounds, setDrawingMap, setDrawnCoords, setFilterDateRange, setFilterMenuMarkers, setFiltersTabOpen, setFitBounds, setGeojsonFilesUpload, setGeojsonType, setHighlightedListMarker, setSelectedAnalysis, setSelectedMapType, setSelectedParishArea, setShapeBounds, toggleList, TypeMarkerInfo } from "store/slices/mapSlice";
import { setSelectedMarkerType } from "store/slices/mapSlice";
import { getFilterDateRange } from "store/slices/mapSlice";
import { getOccurrences, selectOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import { setOccurrences, setOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import * as variables from "styles/css/variables";
import { arrayIsEmpty } from "utils/conditions";
import { DeviceRealTime, updateDeviceLastRead } from "utils/data-real-time";
import { formatDate, getLastWeekRangeDates } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import { getIdClientVisits, getRangesNumbers } from "utils/utils";

import OccurrencesStartDateControl from "../components/map/controls/OccurrencesStartDateControl";

export const IconFilter = (props: { count: number }) => {
  const { count } = props;
  const theme = useTheme();

  return (
    <Badge
      badgeContent={count}
      sx={{
        ".MuiBadge-badge": {
          backgroundColor: theme.palette.primary.dark,
          color:theme.palette.common.white,
        },
      }}
    >
      <FilterListRoundedIcon />
    </Badge>
  );
};
//to heatmap
interface IHeatmapColor {
  color: string;
  value: number; //between 0 and 1
}
//colors to heatmap
export const legendHeatmapColors: IHeatmapColor[] = [
  { color: "blue", value: 0 },
  { color: "blue", value: 0.25 },
  { color: "cyan", value: 0.5 },
  { color: "green", value: 0.6 },
  { color: "yellow", value: 0.75 },
  { color: "red", value: 1 },
];

export const Map = () => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();
  const filterMenuAllMarkers = useSelector(getFilterMenuMarkers);
  const canCreateEvent = hasPermission(PERMISSIONS.MODULES.EVENTS.CREATE);
  const canCreateOccurrence = hasPermission(
    PERMISSIONS.MODULES.OCCURRENCES.CREATE
  );
  const canReadOccurrences = hasPermission(
    PERMISSIONS.MODULES.OCCURRENCES.READ
  );
  const filterTabOpen = useSelector(selectFiltersTabOpen);
  const filtersCount = useGetFiltersCount();
  const filterDateRange = useSelector(getFilterDateRange);
  const [maxValueVisits, setMaxValueVisits] = useState<number>(0);
  const textSearch = useSelector(getTextSearchBar);

  const selectedViewMapType = useSelector(getSelectedMapType);

  const { addHeader } = useContext(LayoutContext);
  const user = useSelector(getUser);
  const mapTile = user.extra_params?.map_tile_layer || TileType.default;
  let mapCenter: LatLngExpression | undefined;
  if (user && !arrayIsEmpty(user.client.center_point))
    mapCenter = {
      lat: user.client.center_point[0],
      lng: user.client.center_point[1],
    };
  const openList = useSelector(selectOpenList);
  const nameClient = useSelector(getNameClient);
  const darkMode = useSelector(getUser)?.extra_params?.dark_mode;
  const fitBounds = useSelector(getFitBounds);
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get("view");
  const [idMap, setIdMap] = useState<string>("");
  const drawingEditMap = useSelector(selectDrawingMap);
  const selectedAnalysis = useSelector(getSelectedAnalysis);
  const selectedAnalysisDetail = useSelector(getSelectedAnalysisDetail);

  const [sendNotifications, setSendNotifications] = useState<boolean>(true);

  const getTodayFirstDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let stringDate = formatDate(date, t("calendar.dateTimeEndpoints"));
    return stringDate;
  };
  const occurrencesFilterParamsDefault = {
    start_date: getTodayFirstDate(),
  };
  const dateOccFilter = useSelector(selectOccurrencesFilterParams);

  const [geojsonCounty, setGeojsonCounty] =
    useState<GeoJSON.FeatureCollection | null>(null); // Parishes of map
  const [keyGeojson, setKeyGeojson] = useState(uuid());
  const geojsonType = useSelector(getGeojsonType);
  const multiFilesUpload = useSelector(getGeojsonFilesUpload);
  const [colorIndex, setColorIndex] = useState<number>(0);

  const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(false);
  const [loadingAnalysisDetail, setLoadingAnalysisDetail] = useState<boolean>(false);

  // // retirar horas pois às vezes nao sao iguais mas para o endpoint nao faz diferença porque nao ha horas
  // const iDateDefaulta = format(
  //   subDays(new Date(), 8),
  //   t("calendar.dateFormatGeoAnalytics")
  // );
  // const fDateDefaulta = format(
  //   subDays(new Date(), 1),
  //   t("calendar.dateFormatGeoAnalytics")
  // );

  const { startDate: iDateDefault, endDate: fDateDefault } =
    getLastWeekRangeDates(t("calendar.dateFormatGeoAnalytics"));

  const dateCurrent = (dateC: Date | string) => {
    return format(new Date(dateC), t("calendar.dateFormatGeoAnalytics"));
  };
  const onChangeFilterDateRange = (iDate: Date, fDate: Date) => {
    dispatch(
      setFilterDateRange({
        startDate: iDate,
        endDate: fDate,
      })
    );
  };
  const cleanFilterDateRange = (iDate: Date, fDate: Date) => {
    const iDateCurrent = dateCurrent(iDate);
    const fDateCurrent = dateCurrent(fDate);
    if (iDateCurrent !== iDateDefault || fDateCurrent !== fDateDefault) {
      onChangeFilterDateRange(new Date(iDateDefault), new Date(fDateDefault));
    }
  };

  const selectedParishArea = useSelector(getSelectedParishArea);

  const cleanSelectedParish = () => {
    if (selectedParishArea.id) {
      dispatch(setSelectedParishArea({ name: "", id: "" }));
    }
  };

  const onChangeVisibilityFiles = (
    array: IGeojsonFeatures[],
    indexFile: number,
    filtersFile: boolean
  ) => {
    if (filtersFile) {
      return array.map((file, index) => {
        return {
          ...file,
          visible: index !== indexFile && file.filters ? false : file.visible,
        };
      });
    } else {
      return array;
    }
  };

  const onChangeDateRangeUpload = (dateRange: string[]) => {
    // update date range
    if (!arrayIsEmpty(dateRange)) {
      dispatch(
        setFilterDateRange({
          startDate: new Date(dateRange[0]),
          endDate: new Date(dateRange[1]),
        })
      );
    }
  };

  const onChangeFileUpload = async (file: any, nameFile: string) => {
    const county = file;
    //cria objeto novo
    let updatedFileArray: IGeojsonFeatures[] = [
      {
        features: { ...(county.features as GeoJSON.Feature[]) },
        id: keyGeojson,
        visible: true,
        name: nameFile,
      },
    ];
    // adiciona markers ou filters ao objecto novo
    if (county.markers) {
      updatedFileArray[0].markers = county.markers;
    }
    if (county.filters) {
      updatedFileArray[0].filters = county.filters;
    }

    const conditionChoroplethMap =
      selectedViewMapType === MapType.choropleth &&
      county.features[0].geometry.type.toLowerCase() === "Point".toLowerCase();
    if (selectedViewMapType === MapType.heat || conditionChoroplethMap) {
      // Heat Map - adicionar a cor a cada marker para mostrar no mapa
      const colorFeatures = county.markers || county.features;
      colorFeatures.forEach((f: GeoJSON.Feature) => {
        f.properties && (f.properties.color = colorsMarkers[colorIndex]);
      });
      setColorIndex((prev: number) => prev + 1);

      // Heat map - agrupar todos os ficheiros carregados
      if (selectedViewMapType === MapType.heat && multiFilesUpload && updatedFileArray) {
        updatedFileArray = [
          ...(multiFilesUpload as IGeojsonFeatures[]),
          ...updatedFileArray,
        ];
      }
    }

    // check if it is a custom geojson
    const customGeojson = file.filters?.dateRange;
    //se for custom
    if (customGeojson) {
      dispatch(setGeojsonType("custom_upload"));
      const filters = file.filters;
      // update date range
      onChangeDateRangeUpload(filters.dateRange);
      // update selected parish
      if (filters.selectedParish) {
        dispatch(setSelectedParishArea(filters.selectedParish));
      } else if (selectedParishArea.id) {
        cleanSelectedParish();
      }
      if (selectedViewMapType === MapType.choropleth) {
        // update options of legend
        onChangeOptionsLegend(file);
        // update geojson of choropleth map
        setGeojsonCounty(file as GeoJSON.FeatureCollection);
      } else {
        // hide data from custom files
        const indexFile = updatedFileArray.length - 1;
        updatedFileArray = onChangeVisibilityFiles(
          updatedFileArray,
          indexFile,
          updatedFileArray[indexFile].hasOwnProperty("filters")
        );
        // update heat map
        updateMarkersFeatures(updatedFileArray);
      }
      //se for simple
    } else {
      dispatch(setGeojsonType("simple_upload"));
      const iDateCurrent = dateCurrent(filterDateRange.startDate);
      const fDateCurrent = dateCurrent(filterDateRange.endDate);
      // if (iDateCurrent !== iDateDefault || fDateCurrent !== fDateDefault) {
      //   onChangeFilterDateRange(new Date(iDateDefault), new Date(fDateDefault));
      // }
      if (selectedViewMapType === MapType.choropleth) {
        let geojsonMapView;
        if (iDateCurrent !== iDateDefault || fDateCurrent !== fDateDefault) {
          const defaultRange = {
            startDate: new Date(iDateDefault),
            endDate: new Date(fDateDefault),
          };
          geojsonMapView = await getGeojsonClient(
            nameClient,
            "visits",
            defaultRange
          );
        } else {
          geojsonMapView = await getGeojsonClient(nameClient, "visits");
        }
        const areasGeojson = [
          {
            features: { ...(geojsonMapView?.features as GeoJSON.Feature[]) },
            id: uuid(),
            visible: true,
            name: "geojsonMapView",
          },
          ...updatedFileArray,
        ];
        // update geojson of parishes
        updateMarkersFeatures(areasGeojson);
        // update options of legend
        onChangeOptionsLegend(geojsonMapView as GeoJSON.FeatureCollection);
      } else {
        updateMarkersFeatures(updatedFileArray);
      }
    }

    // atualiza os ficheiros carregados no redux
    dispatch(setGeojsonFilesUpload(updatedFileArray));
  };

  // faz merge das features/markers
  const getGeojsonUpdate = (
    features: IGeojsonFeatures[],
    analysis: boolean = false
  ) => {
    let finalFeatureCollection: any;
    // mostrar apenas as features visiveis
    const visibleFeatures = features.filter((f) => f.visible);
    if (visibleFeatures) {
      const existsCustomUpload =
        visibleFeatures.filter((feature) => {
          return (
            feature.hasOwnProperty("markers") ||
            feature.hasOwnProperty("filters")
          );
        }).length > 0;
      // se existirem ficheiros carregados personalizados
      if (existsCustomUpload) {
        const valuesMap = visibleFeatures
          .filter((f) => f.filters)
          .map((feature) => Object.values(feature.features));
        const allMarkers = visibleFeatures
          .filter((f) => !(f.filters && !f.markers))
          .map((file) => file.markers || Object.values(file.features))
          .flat();
        finalFeatureCollection = {
          type: "FeatureCollection",
          features: valuesMap[0],
          markers: allMarkers,
        };
      } else {
        if (selectedViewMapType === MapType.choropleth) {
          if (analysis) {
            finalFeatureCollection = {
              type: "FeatureCollection",
              features: geojsonCounty?.features,
            };
          } else {
            const allFeatures = visibleFeatures
              .map((file) => Object.values(file.features))
              .flat();
            finalFeatureCollection = {
              type: "FeatureCollection",
              features: allFeatures,
            };
          }
        } else {
          const heatMap = convertCoordsToGeojson(heatmapData.points, null);
          const allMarkers = visibleFeatures
            .map((file) => Object.values(file.features))
            .flat();
          finalFeatureCollection = {
            type: "FeatureCollection",
            features: heatMap.features,
            markers: allMarkers,
          };
        }
      }
    }
    return finalFeatureCollection;
  };

  // mostrar as novas features/markers no mapa
  const updateMarkersFeatures = (features: IGeojsonFeatures[]) => {
    const finalFeatureCollection = getGeojsonUpdate(features);
    if (finalFeatureCollection?.markers) {
      updateHeatMapAndMarkers(
        finalFeatureCollection as IGeojsonFeaturesCollection
      );
    } else {
      // update geojson of map
      setGeojsonCounty(finalFeatureCollection as GeoJSON.FeatureCollection);
    }
    // update geojson key
    setKeyGeojson(uuid());
  };

  const showSelectedAnalysis = (geojson: IGeojsonFeaturesCollection) => {
    //se tiver ficheiros carregados, retira-os
    if (!arrayIsEmpty(multiFilesUpload)) {
      dispatch(setGeojsonFilesUpload(null)); //limpa os ficheiros carregados
    }
    // cancel request
    if (loadingVisits) {
      cancelVisits();
      setLoadingVisits(false);
    } else if (loadingHeatMapData) {
      cancelVisitsHeatMap();
      setLoadingHeatMapData(false);
    }
    geojson.filters && onChangeDateRangeUpload(geojson.filters?.dateRange); //troca a data
    if (selectedViewMapType === MapType.heat) {
      updateHeatMapAndMarkers(geojson); //update map data
    } else {
      // update options of legend and map
      onChangeOptionsLegend(geojson);
      setGeojsonCounty(geojson as GeoJSON.FeatureCollection);
    }
    setKeyGeojson(uuid()); // update geojson key
  };

  const updateHeatMapAndMarkers = (
    finalFeatureCollection: IGeojsonFeaturesCollection
  ) => {
    // update data of heat map
    const newValues = convertCoordsArray(finalFeatureCollection);
    setCoordinates({ points: newValues as [number, number, number][] });
    // show markers in heat map
    setGeojsonCounty(
      finalFeatureCollection?.markers as unknown as GeoJSON.FeatureCollection
    );
  };

  const getGeojsonClient = async (
    client: string,
    typeData: string,
    dateRange?: IFilterDateRangeMap
  ) => {
    const parameters = {
      params: {
        start_date: formatDate(
          dateRange?.startDate || filterDateRange.startDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
        end_date: formatDate(
          dateRange?.endDate || filterDateRange.endDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
        city_id: getIdClientVisits(client),
      },
    };
    switch (client) {
      case "CMChaves":
        return chaves as GeoJSON.FeatureCollection;
      case "AveiroDemo":
      case "CMBeja":
        if (typeData === "visits") {
          let county = null;
          await refetchVisits(parameters)
            .then((res) => {
              county = res.data;
            })
            .catch((error) => {
              county = null;
            });
          return county as GeoJSON.FeatureCollection | null;
        }
    }
  };

  useEffect(() => {
    if (filterDateRange.startDate && filterMenuAllMarkers === "visits") {
      if (!geojsonType.includes("upload") && !selectedAnalysis) {
        if (selectedViewMapType === MapType.choropleth) {
          onChangeClientGeojson(nameClient);
        } else {
          refetchHeatMapData();
        }
      }
    }
  }, [filterDateRange]);

  const onChangeClientGeojson = async (
    client: string,
    typeData: string = "visits"
  ) => {
    // update geojson
    const county = await getGeojsonClient(client, typeData);
    if (county) {
      setGeojsonCounty(county);
    }

    // update geojson key
    setKeyGeojson(uuid());
    if (county) {
      onChangeOptionsLegend(county);
    }
  };

  const onChangeOptionsLegend = (county: GeoJSON.FeatureCollection | null) => {
    if (county) {
      const values = county.features.map((feature: GeoJSON.Feature) => {
        return feature?.properties?.visits || 0;
      });
      setMaxValueVisits(!arrayIsEmpty(values) ? Math.max(...values) : 0);
    } else {
      setMaxValueVisits(0);
    }
  };

  useEffect(() => {
    addHeader();
    //reset redux states
    dispatch(setSelectedMarkerType("all"));
    //por defeito, as datas das ocorrencias serão as do dia de hoje a partir da 00:00, depois pode ser alterado no calendário
    if (canReadOccurrences) {
      dispatch(setOccurrencesFilterParams(occurrencesFilterParamsDefault));
    }
    dispatch(setFiltersTabOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // --- SSE
    // --- Smart Parking Funchal Devices
    if (nameClient === "CMFunchal") {
      const deviceEventSource = new EventSource(
        `${ServiceApiUrl.baseApiUrl_SSE}/${ServiceApiUrl.devicesSmartParking}`
      );
      deviceEventSource.onmessage = (e) => {
        const value = JSON.parse(e.data);
        if (value[0] && value[0].external_id) {
          dispatch(setDeviceRealTime(value[0]));
          addDeviceNotification(value[0]);
        }
      };
      deviceEventSource.onerror = (e) => {
        getErrorEventSource(e);
      }
      return () => {
        deviceEventSource.close();
      };
    }

    if (nameClient === "CMChaves") {
      // TODO estas datas aqui são temporarias até termos os dados todos de chaves
      const iDate = new Date(
        subDays(new Date("2022-11-13"), 4).setHours(0, 0, 0, 0)
      );
      const fDate = new Date("2022-11-13");
      dispatch(
        setFilterDateRange({
          startDate: iDate,
          endDate: fDate,
        })
      );
    }

    return () => {
      dispatch(setFiltersTabOpen(false));

      // remove params when left the map
      dispatch(setDevicesFilterParams({}));
      dispatch(setEventsFilterParams({}));
      dispatch(setOccurrencesFilterParams(occurrencesFilterParamsDefault));
      dispatch(setTextSearchBar(""));
      dispatch(setFitBounds(false));
      // remove filterMenuMarkers option when left the map
      dispatch(setFilterMenuMarkers(""));
      // remove selectedViewMapType when left the map
      dispatch(setSelectedMapType(null));
      // remove upload when go to others pages
      handleRemoveUploadClick();
      if (showHeatMap) {
        setCoordinates(null);
      }
      // clear fields for visits
      cleanSelectedParish();
      cleanFilterDateRange(filterDateRange.startDate, filterDateRange.endDate);
      if (selectedAnalysis?.length > 0) dispatch(setSelectedAnalysis(""));

      dispatch(setDevices([]));
      dispatch(setOccurrences([]));
      dispatch(setEvents([]));
    };
  }, []);

  const [errorEventSource, setErrorEventSource] = useState<Event | null>(null);
  const getErrorEventSource = (error: Event) => {
    setErrorEventSource(error);
  }

  const [notificationFeedList, setNotificationFeedList] = useState<any[]>([]);

  const addDeviceNotification = (n: any) => {
    if (n?.external_id) {
      const notification: IDeviceNotification = {
        ...n,
        unread: true,
        seen: false,
      };
      let auxList = notificationFeedList;
      auxList.push(notification);
      setNotificationFeedList(auxList);
    }
  };

  const deviceRealTime: DeviceRealTime = useSelector(selectDeviceRealTime);
  useEffect(() => {
    if (!arrayIsEmpty(devices)) {
      showValuesRealTime(deviceRealTime);
    }
  }, [deviceRealTime]);

  function showValuesRealTime(data: DeviceRealTime) {
    // check if the received device belongs to the visible devices
    const deviceMap = devices.find((device: TMarker) => {
      return device.external_id === data.external_id;
    });
    if (deviceMap) {
      // update devices with data in real time
      const newValues = updateDeviceLastRead(deviceMap, data);
      dispatch(setOneDeviceMap(newValues));
    }
  }

  // ------------ Markers of map
  const mapBounds = useSelector(getMapBounds);
  const { getAllMarkersMap } = useMarkers();
  const { fetchAllMarkers } = useFetchMarkers();

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [markerTypeDetails, setMarkerTypeDetails] = useState<string>("");
  const [openAddEvent, setAddEvent] = useState<boolean>(false);
  const [openAddOccurrence, setAddOccurrence] = useState<boolean>(false);
  const [filterCoordinates, setFilterCoordinates] = useState<
    [LatLngExpression] | []
  >([]);
  const [highlightedPoint, setHighlightedPoint] = useState<IMapPoint | null>(
    null
  );
  const [openFilters, setOpenFilters] = useState(false);

  const initialZoom = filterMenuAllMarkers === "visits" ? 11 : 15;
  const [zoomTest, setzoomTest] = useState<number>(initialZoom);
  const [centerMap, setcenterMap] = useState<LatLngExpression | undefined>(
    mapCenter
  );

  const devices: TMarker[] = useSelector(selectDevices);
  const events: TMarker[] = useSelector(getEvents);
  const occurrences: TMarker[] = useSelector(getOccurrences);
  const markers: IMapPoint[] = useSelector(selectMarkers);
  const shapeBounds = useSelector(getShapeBounds);


  const options = {
    manual: true,
  };

  // TODO useState criado devido ao bug do axios que ao cancelar o pedido o loading nao atualiza
  const [loadingVisits, setLoadingVisits] = useState<boolean>(false);
  const {
    loading: loadingV,
    refetch: refetchVisits,
    error: errorVisits,
    cancelRequest: cancelVisits,
  } = useGet(ServiceApiUrl.mapView, null, options);

  useEffect(() => {
    setLoadingVisits(loadingV);
  }, [loadingV])

  useEffect(() => {
    if (shapeBounds?.length > 0) {
      dispatch(setShapeBounds([]));
    }
  }, [location]);

  async function getMarkersMap() {
    await getAllMarkersMap();
  }

  useEffect(() => {
    if (!drawingEditMap) {
      setIdMap(uuid());
    }
  }, [drawingEditMap]);

  useDidMountEffect(() => {
    if (
      !arrayIsEmpty(mapBounds) &&
      // filter is array or object
      ((Array.isArray(filterCoordinates) && arrayIsEmpty(filterCoordinates)) ||
        Object.keys(filterCoordinates).length === 0) &&
      // && !openAddEvent
      viewType !== "visits"
    ) {
      fetchAllMarkers();
    }
  }, [mapBounds]);

  useEffect(() => {
    getMarkersMap();
  }, [devices, events, occurrences]);

  const handlePointClick = (point: IMapPoint) => {
    dispatch(getMarkerDetails(point.id, point.type as TypeMarkerInfo, user));
    setMarkerTypeDetails(point.type);
    setOpenDetails(true);
  };

  //faz o pedido apenas quando o shapeBounds  alterar
  useDidMountEffect(() => {
    if(!arrayIsEmpty(shapeBounds) || !arrayIsEmpty(mapBounds))
      fetchAllMarkers();
  }, [shapeBounds]);

  const handleDrawFilter = (filters: [LatLngExpression] | []) => {
    setFilterCoordinates(filters);
    if (
      !arrayIsEmpty(mapBounds) &&
      ((Array.isArray(filters) && arrayIsEmpty(filters)) ||
        Object.keys(filters).length === 0)
    ) {
      dispatch(setShapeBounds([]));
      // fetchAllMarkers(); //faz quando receber o novo valor do shapeBounds

      // nao existe poligono - pedidos com o mapBounds
    } else {
      const bounds = getBoundsVisibleArea(filters as any);
      dispatch(setShapeBounds(bounds));
    }
  };

  const handleViewMapType = (type: MapType) => {
    // update selectedViewMapType
    dispatch(setSelectedMapType(type));
    if (selectedViewMapType && type !== selectedViewMapType) {
      // clear analysis
      dispatch(setSelectedAnalysis(""));
      // change data of view map
      switch (type) {
        case MapType.choropleth:
          // hide values of heat map
          setShowHeatMap(false);
          if (loadingHeatMapData) {
            cancelVisitsHeatMap();
            setLoadingHeatMapData(false);
          } else {
            setCoordinates(null);
          }
          // show values of visits/parishes
          onChangeClientGeojson(nameClient);
          break;
        case MapType.heat:
          // hide parishes
          if (loadingVisits) {
            cancelVisits();
            setLoadingVisits(false);
          }
          setMaxValueVisits(0);
          // clean selected parish
          cleanSelectedParish();
          // show heat map with values
          setShowHeatMap(true);
          refetchHeatMapData();
          break;
      }
      resetUploads();
      onChangeZoom(initialZoom);
    }
  };
  //limpa uploaded files e dá reset
  const resetUploads = () => {
    dispatch(setGeojsonFilesUpload(null));
    setColorIndex(0); //dá reset às cores
    dispatch(setGeojsonType("parishes"));
    setGeojsonCounty(null);
    //reset datas
    cleanFilterDateRange(filterDateRange.startDate, filterDateRange.endDate);
  };

  const handleBoundsChange = (bounds: number[][]) => {
    dispatch(setBounds(bounds));
  };

  //obter zoom do mapa
  const handleZoom = (zoom: number) => {
    setzoomTest(zoom);
  };

  //obter centro do mapa
  const handleCenter = (coord: LatLngExpression) => {
    setcenterMap(coord);
  };

  //ref auxiliar para guardar o valor mais atualizado, para colocar no actionsCtrl
  //se utilizar o estado diretamente, não atualiza o valor
  const filterTabOpenRef = useRef<boolean>();
  filterTabOpenRef.current = filterTabOpen;
  const filtersCountRef = useRef<number>(0);
  filtersCountRef.current = filtersCount;

  function returnIcon() {
    return <IconFilter count={filtersCountRef.current} />;
  }

  let actionsCtrl: IActionControl[] = [];
  if (filterMenuAllMarkers !== "visits") {
    actionsCtrl.push({
      action: () => {
        dispatch(toggleList(false));
        dispatch(setFiltersTabOpen(!filterTabOpenRef.current));
      },
      icon: returnIcon, //é enviado em formato função porque não estava a atualizar o valor do count!
      title: t("map.buttons.filters"),
    });
  }

  if (canCreateEvent) {
    actionsCtrl.push({
      action: () => {
        setAddEvent(true);
        dispatch(setFiltersTabOpen(false));
      },
      icon: <AddIcon />,
      title: t("map.buttons.addEvent"),
    });
  }

  //TODO: A permissão de adicionar ocorrências deveria passar a ser atribuída diretamente apenas ao AveiroDemo nas permissões?
  if (
    canCreateOccurrence &&
    (nameClient === "AveiroDemo" || nameClient === "CMBeja") &&
    filterMenuAllMarkers !== "visits"
  ) {
    actionsCtrl.push({
      action: () => {
        setAddOccurrence(true);
        dispatch(setFiltersTabOpen(false));
      },
      icon: <AddIcon />,
      title: t("map.buttons.addOccurrence"),
    });
  }

  function downloadFileMap() {
    addFeedback({
      message: t("feedback.warning.loadDownload"),
      severity: "warning",
    });

    if (geojsonType === "analysis" && selectedAnalysisDetail) {
      const analysisName = selectedAnalysisDetail.name
        .replace(/\s/g, "")
        .split(" (")[0];
      downloadCustomFile(
        selectedAnalysisDetail.geojson,
        selectedViewMapType,
        analysisName
      );
    } else {
      // TODO os valores a null sao temporarios pois sao da tab do filtro
      const filters: IParamsDownload = {
        search: textSearch?.length > 0 ? textSearch : null,
        typeVisitor: null,
        geographicArea: null,
        time: null,
        dateRange: dateRangeDownload(filterDateRange),
        selectedParish: !!selectedParishArea.id ? selectedParishArea : null,
      };
      switch (selectedViewMapType) {
        case MapType.choropleth:
          downloadVisits(filters);
          break;
        case MapType.heat:
          downloadInfoHeatMap(filters);
          break;
      }
    }
  }

  function downloadVisits(params: IParamsDownload) {
    let geojsonMap = {
      ...geojsonCounty,
    } as IGeojsonFeaturesCollection;

    // update filters
    Object.assign(geojsonMap, { filters: params });

    downloadCustomFile(geojsonMap, selectedViewMapType, null);
  }

  function downloadInfoHeatMap(params: IParamsDownload) {
    let geojsonMap;
    if (multiFilesUpload && !arrayIsEmpty(multiFilesUpload)) {
      // get geojson for show in the map
      geojsonMap = getGeojsonUpdate(multiFilesUpload);
      // get filters for geojson
      Object.assign(geojsonMap, { filters: params });
    } else {
      geojsonMap = convertCoordsToGeojson(coordinates?.points, params);
    }

    downloadCustomFile(geojsonMap, selectedViewMapType, null);
  }

  function downloadCustomFile(
    geojsonMap: IGeojsonFeaturesCollection,
    viewMapType: MapType | null,
    analysis: string | null
  ) {
    const iDate = dateRangeDownload(filterDateRange)[0];
    const fDate = dateRangeDownload(filterDateRange)[1];
    const typeFile = viewMapType === MapType.heat ? "HeatMap" : "Tourism";
    const fileName = !!analysis
      ? `${nameClient}${typeFile}_${analysis}_${iDate}_${fDate}.geojson`
      : `${nameClient}${typeFile}_${iDate}_${fDate}.geojson`;
    downloadFile(geojsonMap, fileName);
    addFeedback({
      message: t("feedback.success.downloadFile"),
      severity: "success",
    });
  }

  useEffect(() => {
    // set upload button to active or disabled
    if (
      filterMenuAllMarkers === "visits" &&
      selectedViewMapType === MapType.choropleth
    ) {
      const objIndex = actionsCtrl.findIndex(
        (obj) => obj.title === t("map.buttons.upload")
      );
      actionsCtrl[objIndex].disabled = loadingVisits;

      // hide geojson
      if (loadingVisits) {
        setGeojsonCounty(null);
      }
    }
  }, [loadingVisits]);

  const [showHeatMap, setShowHeatMap] = useState(false);

  const sidebarIsOpen: boolean = useSelector(getSidebarIsOpen);

  // ---- Menu of filter markers and parishes
  // const iconDevices = (checked: boolean) => {
  //   return (
  //     <ImgDevices
  //       width="2.188rem"
  //       height="2.188rem"
  //       opacity={checked ? "" : "0.2"}
  //     />
  //   );
  // };
  // const iconVisits = (checked: boolean) => {
  //   return (
  //     <ImgVisits
  //       width="2.188rem"
  //       height="2.188rem"
  //       opacity={checked ? "" : "0.2"}
  //     />
  //   );
  // };
  // const listOptionsLeftTopContent = [
  //   {
  //     value: "markers",
  //     titleTooltip:
  //       (nameClient === "AveiroDemo" || nameClient === "CMBeja")
  //         ? t("map.filter.devicesOccurrences")
  //         : t("map.filter.types.devices"),
  //     icon: iconDevices(false),
  //     checkedIcon: iconDevices(true),
  //   },
  //   {
  //     value: "visits",
  //     titleTooltip: t("map.filter.types.visits"),
  //     icon: iconVisits(false),
  //     checkedIcon: iconVisits(true),
  //     default: true,
  //   },
  // ];

  // function handleFilterMapMarkers(view: string) {
  //   dispatch(setFilterMenuMarkers(view));
  // }

  // function showLeftTopContentMap() {
  //   if (
  //     nameClient === "CMChaves" ||
  //     nameClient === "AveiroDemo" ||
  //     nameClient === "CMBeja"
  //   ) {
  //     return (
  //       <FilterMapMarkers
  //         listOptions={listOptionsLeftTopContent}
  //         onChange={handleFilterMapMarkers}
  //       />
  //     );
  //   }
  //   return null;
  // }

  const onChangeZoom = (zoom: number) => {
    setzoomTest(zoom);
    setIdMap(uuid());
  };

  useEffect(() => {
    switch (filterMenuAllMarkers) {
      case "visits":
        dispatch(setSelectedMapType(MapType.choropleth));
        setShowHeatMap(false);
        onChangeZoom(initialZoom);
        showSelectedFilterVisits();
        break;
      case "markers":
        dispatch(setSelectedMapType(null));
        onChangeZoom(initialZoom);
        showSelectedFilterMarkers();
        break;
      default:
        break;
    }
    //ao trocar de vista no mapa, dá reset ao centro do mapa
    setcenterMap(mapCenter);
  }, [filterMenuAllMarkers]);

  function showSelectedFilterVisits() {
    // options for filter map
    dispatch(setSelectedMarkerType("visits"));
    // show parishes
    onChangeClientGeojson(nameClient);
    // hide markers
    // -- devices
    dispatch(setDevices([]));
    dispatch(setDevicesFilterParams({}));
    // -- occurrences
    if (canReadOccurrences) {
      dispatch(setOccurrencesFilterParams({}));
      dispatch(setOccurrences([]));
    }
  }

  function showSelectedFilterMarkers() {
    // options for filter map
    dispatch(setSelectedMarkerType("all"));
    // add params for occurrences filter
    if (canReadOccurrences) {
      dispatch(setOccurrencesFilterParams(occurrencesFilterParamsDefault));
    }
    // hide parishes
    setGeojsonCounty(null);
    setMaxValueVisits(0);
    // clear selected parish
    cleanSelectedParish();
    cleanFilterDateRange(filterDateRange.startDate, filterDateRange.endDate);
  }

  useEffect(() => {
    dispatch(setFiltersTabOpen(false));
    if (selectedAnalysis?.length > 0) dispatch(setSelectedAnalysis(""));
    resetUploads();

    switch (viewType) {
      case "visits":
        dispatch(setDevicesFilterParams({}));
        dispatch(setEventsFilterParams({}));
        dispatch(setOccurrencesFilterParams({}));
        dispatch(setFilterMenuMarkers(viewType));
        addHeader({ title: t("sidebar.peopleMobility") });
        break;
      default:
        // remove upload when going to map page
        handleRemoveUploadClick("map");
        if (showHeatMap) {
          setCoordinates(null);
        }
        // cancel the request of visits
        if (loadingVisits) {
          cancelVisits();
          setLoadingVisits(false);
        }
        dispatch(setFilterMenuMarkers("markers"));
        dispatch(toggleList(false));
        addHeader();
    }
  }, [viewType]);

  function showLeftBottomContentMap() {
    if (filterMenuAllMarkers === "visits") {
      return (
        <MapLegend
          options={
            maxValueVisits !== 0
              ? getRangesNumbers([0, maxValueVisits], 5)
              : undefined
          }
          loading={loadingVisits || loadingHeatMapData || loadingAnalysisDetail }
          error={errorVisits || errorHeatMap}
          disabledCalendar={
            Boolean(geojsonType.includes("upload")) || Boolean(selectedAnalysis)
          }
          heatmap={showHeatMap}
          heatmapData={coordinates as any}
        />
      );
    } else if (canReadOccurrences && filterMenuAllMarkers !== "visits" && dateOccFilter.start_date)
      return (
        <OccurrencesStartDateControl
          todayDate={dateOccFilter.start_date}
          sendNotifications={sendNotifications}
          setSendNotifications={setSendNotifications}
        />
      );
    return null;
  }

  const handleReadNotifications = () => {
    notificationFeedList.forEach((notification: IDeviceNotification) => {
      if (notification.unread) notification.unread = false;
    });
  };

  const showRightTopContentMap = () => {
    if (nameClient === "CMFunchal") {
      return (
        <NotificationFeed
          feed={notificationFeedList}
          onPointClick={handlePointClick}
          readNotifications={handleReadNotifications}
          errorRequest={errorEventSource}
        />
      );
    }
  };

  function getNumValue(values: number[][], index0: number, index1: number) {
    return Number(values[index0][index1].toFixed(0));
  }
  function getColorGeojson(value: number) {
    const values = getRangesNumbers([0, maxValueVisits], 5);
    return Number(value) > getNumValue(values, 4, 0)
      ? variables.fifthColor
      : Number(value) > getNumValue(values, 3, 0)
      ? variables.fourthColor
      : Number(value) > getNumValue(values, 2, 0)
      ? variables.thirdColor
      : Number(value) > getNumValue(values, 1, 0)
      ? variables.secondColor
      : Number(value) > 0
      ? variables.firstColor
      : variables.disabledColor;
  }

  function onChangeSelectedParish(parish: ISelectedParishArea) {
    dispatch(setSelectedParishArea(parish));
  }

  // TODO useState criado devido ao bug do axios que ao cancelar o pedido o loading nao atualiza
  const [loadingHeatMapData, setLoadingHeatMapData] = useState<boolean>(false);
  const {
    data: heatmapData,
    refetch: refetchHeatMapData,
    loading: loadingHMap,
    error: errorHeatMap,
    cancelRequest: cancelVisitsHeatMap,
  } = useGet(
    ServiceApiUrl.heatmap,
    {
      // start_date: filterDateRange.startDate.toISOString().split("T")[0],
      // end_date: filterDateRange.endDate.toISOString().split("T")[0],
      start_date: formatDate(
        filterDateRange.startDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      end_date: formatDate(
        filterDateRange.endDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      city_id: getIdClientVisits(nameClient),
    },
    options
  );

  useEffect(() => {
    setLoadingHeatMapData(loadingHMap);
  }, [loadingHMap])

  const [coordinates, setCoordinates] = useState<HeatmapOptions<number> | null>(
    null
  );

  useEffect(() => {
    setCoordinates(heatmapData);
  }, [heatmapData]);

  useEffect(() => {
    // hide information of heatMap
    if (filterMenuAllMarkers === "visits" && loadingHeatMapData) {
      setCoordinates(null);
    }
  }, [loadingHeatMapData]);

  const uploadFile = (newFile: JSON, nameFile: string) => {
    // open tab of detail
    if (!openList) {
      dispatch(toggleList(true));
    }
    // clear analysis
    if (selectedAnalysis) {
      dispatch(setSelectedAnalysis(""));
      cleanFilterDateRange(filterDateRange.startDate, filterDateRange.endDate);
    }
    // change info map
    onChangeFileUpload(newFile, nameFile);
    setKeyGeojson(uuid());
  };

  // download and upload json/geojson
  if (filterMenuAllMarkers === "visits") {
    // if (selectedViewMapType === MapType.heat) {
    //   actionsCtrl.push({
    //     action: () => {
    //       dispatch(toggleList(false));
    //     },
    //     icon: <DescriptionIcon />,
    //     title: t("map.buttons.analysis"),
    //   });
    // }
    actionsCtrl.push({
      action: () => {
        downloadFileMap();
      },
      icon: <DownloadIcon />,
      title: t("map.buttons.download"),
      disabled: loadingVisits || loadingHeatMapData || loadingAnalysis || loadingAnalysisDetail,
    });
    actionsCtrl.push({
      action: () => {},
      icon: <UploadIcon />,
      title: t("map.buttons.upload"),
      component: "label",
      disabled: showHeatMap ? loadingHeatMapData : loadingVisits,
      children: <FileUpload onChangeFile={uploadFile} />,
    });
  }

  const onCloseFilterTab = () => {
    dispatch(setFiltersTabOpen(false));
  };

  const handleOnHoverItemList = (item: IMapPoint | null) => {
    setHighlightedPoint((prev) => (prev = item?.selected ? item : null));
    dispatch(setHighlightedListMarker(item));
  };

  //da primeira vez que a tab dos filtros for aberta, mantem o componente disponível
  useEffect(() => {
    if (!openFilters && filterTabOpen) {
      setOpenFilters(true);
    }
  }, [filterTabOpen]);

  const onChangeDateRange = (startDate: Date, endDate: Date, page: string) => {
    const iDateCurrent = dateCurrent(startDate);
    const fDateCurrent = dateCurrent(endDate);

    if (iDateCurrent !== iDateDefault || fDateCurrent !== fDateDefault) {
      onChangeFilterDateRange(new Date(iDateDefault), new Date(fDateDefault));
    } else if (page === "module") {
      if (!showHeatMap) {
        // when dates are not changed show geojson
        onChangeClientGeojson(nameClient);
      } else {
        // when dates are not changed show data of heat map
        refetchHeatMapData();
      }
    }
  };

  const handleCleanFilters = (startDate: Date, endDate: Date, page: string) => {
    // clean selected parish
    cleanSelectedParish();
    // clean selected date range and show new values
    onChangeDateRange(startDate, endDate, page);
  };

  const handleCleanUpload = (id: string) => {
    // remove geojson uploaded
    if (multiFilesUpload) {
      const updatedFiles = multiFilesUpload?.filter(
        (file: IGeojsonFeatures) => file.id !== id
      );
      //dá dispatch com os valores novos
      dispatch(setGeojsonFilesUpload(updatedFiles));

      if (updatedFiles.length === 0) {
        dispatch(setGeojsonType("parishes"));
        if (selectedViewMapType === MapType.choropleth) {
          // show choropleth map with values of visits
          onChangeClientGeojson(nameClient);
        } else {
          // show heat map with values
          refetchHeatMapData();
          setGeojsonCounty(null);
        }
      } else {
        updateMarkersFeatures(updatedFiles);
      }
    }
  };

  const handleRemoveUploadClick = (page: string = "module", id?: string) => {
    if (geojsonType.includes("upload")) {
      if (geojsonType === "custom_upload") {
        handleCleanFilters(
          filterDateRange.startDate,
          filterDateRange.endDate,
          page
        );
      }
      id && handleCleanUpload(id);
      // close detail when go to map page
      if (page === "map" && openList) {
        dispatch(toggleList(false));
      }
    }
  };

  const onClearUpload = (id: string) => {
    handleRemoveUploadClick(undefined, id);
  };

  const onChangeVisibilityUpload = (id: string) => {
    //copia array
    let files = [...(multiFilesUpload as IGeojsonFeatures[])];
    //encontra o index do file alterado
    const objIndex = files.findIndex(
      (file: IGeojsonFeatures) => file.id === id
    );
    // atualizar campo visible do ficheiro selecionado
    files[objIndex] = {
      ...files[objIndex],
      visible: !files[objIndex].visible,
    };
    // verificar se no heatMap existem mais ficheiros personalizados
    const customUploadHeatMap =
      selectedViewMapType === MapType.heat &&
      files.filter((file) => {
        return file.hasOwnProperty("markers") || file.hasOwnProperty("filters");
      }).length > 0;
    if (customUploadHeatMap) {
      // change visibility of others custom files
      files = onChangeVisibilityFiles(
        files,
        objIndex,
        files[objIndex].hasOwnProperty("filters")
      );
      // update filters
      const currentFile = files[objIndex];
      if (currentFile.filters) {
        onChangeDateRangeUpload(currentFile.filters.dateRange);
      }
      // if custom files are all hidden
      const customFilesHidden =
        files.filter((file) => file.filters && file.visible).length > 0;
      if (!customFilesHidden) {
        onChangeDateRangeUpload([iDateDefault, fDateDefault]);
      }
    }
    //dá dispatch com os valores novos
    dispatch(setGeojsonFilesUpload(files));
    // mostrar markers no heat map
    updateMarkersFeatures(files);
  };

  const updateMarkers = () => {
    if (canReadOccurrences) {
      setSendNotifications(false);
    }
    fetchAllMarkers();
    setIdMap(uuid());
  };

  //pedido para alterar dados user
  const { refetch: updateUser } = useUpdate(ServiceApiUrl.user, user?.id);
  const extraParamsUSer = user?.extra_params;
  //guarda novo tile nos extra params do user
  const handleChangeTileType = (tile: string) => {
    if (
      user &&
      tile !== user?.extra_params?.map_tile_layer &&
      filterMenuAllMarkers !== "visits"
    ) {
      const defaultValues = {
        extra_params: {
          ...extraParamsUSer,
          map_tile_layer: tile,
        },
      };
      updateUser({ data: defaultValues }).then((res) => {
        const response = res.data;
        dispatch(setUser(response));
      });

      handlerSuccess();
    }
  };

  const handlerSuccess = () => {
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
  };

  const handleChangeFitBounds = (fitBoundsValue: boolean) => {
    fitBoundsValue !== fitBounds && dispatch(setFitBounds(fitBoundsValue));
  };

  const maxZoomHeatMap = {
    maxZoom: 16,
  };

  let args: IMap = {
    idMap: idMap,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: {
      buttons: {
        layers: t("map.buttons.layers"),
        selectAreas: t("map.buttons.selectAreas"),
        edit: t("map.buttons.edit"),
        remove: t("map.buttons.remove"),
        marker: t("map.buttons.marker"),
        circle: t("map.buttons.circle"),
        rectangle: t("map.buttons.rectangle"),
        polygon: t("map.buttons.polygon"),
        polyline: t("map.buttons.polyline"),
        recenter: t("map.buttons.recenter"),
        delete: t("map.buttons.remove"),
        cancel: t("map.buttons.cancel"),
        close: t("close"),
      },
      popups: {
        lastRead: t("map.marker.lastReading"),
        showDetail: t("common.showDetail"),
        visits: "Visitas",
        title: "Secção de",
        accommodations: "Alojamentos",
        citizens: "Cidadãos",
        men: "Homem",
        women: "Mulher",
        age_0_14: "0 - 14 anos",
        age_15_24: "15 - 24 anos",
        age_25_64: "25 - 64 anos",
        age_65_more: "65 ou + anos",
      },
      date: {
        dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
      },
      leafletDraw: {
        removeShapeConfirmation: t("leafletDraw.removeShapeConfirmation"),
        removeShape: t("leafletDraw.removeShape"),
        dragToEdit: t("leafletDraw.dragToEdit"),
        clickCancelToUndo: t("leafletDraw.clickCancelToUndo"),
        clickToRemove: t("leafletDraw.clickToRemove"),
        howToDrawCircle: t("leafletDraw.howToDrawCircle"),
        howToDrawCircleMarker: t("leafletDraw.howToDrawCircleMarker"),
        howToDrawMarker: t("leafletDraw.howToDrawMarker"),
        howToDrawPolygon: t("leafletDraw.howToDrawPolygon"),
        howToDrawRectangle: t("leafletDraw.howToDrawRectangle"),
      },
      markers: {
        active: t("common.active"),
        inactive: t("common.inactive"),
      },
      common: {
        noData: t("common.noData"),
      },
    },
    mapVariant: "page",
    searchBarText: textSearch,
    actionsControls: actionsCtrl,
    mapCenterPoint: centerMap,
    initialMapCenter: mapCenter
      ? (Object.values(mapCenter) as LatLngExpression)
      : undefined,
    mapZoom: zoomTest,
    initialZoom: initialZoom,
    ...(selectedViewMapType === MapType.heat && {...maxZoomHeatMap}),
    mapTileType: mapTile,
    points: markers,
    mapModule: viewType === "visits" ? true : false,
    showZoom: true,
    onZoom: handleZoom,
    onCenter: handleCenter,
    appDarkThemeMode: darkMode,
    onBounds: handleBoundsChange,
    width:
      openList || filterTabOpen
        ? viewType === "visits"
          ? "24rem"
          : "28.125rem"
        : 0,
    onPointClick: handlePointClick,
    highlightPoint: highlightedPoint,
    sidebarIsOpen: sidebarIsOpen,
    onChangeTileType: handleChangeTileType,

    // controls clients
    leftTopContentMap: null, // leftTopContentMap: showLeftTopContentMap()  --> quando se usava o menu dos markers e visits
    leftBottomContentMap: showLeftBottomContentMap(),
    rightTopContentMap: showRightTopContentMap(),
    // geojson with parishes
    geojson: {
      parishes: geojsonCounty,
      colors: getColorGeojson,
      key: keyGeojson,
      selectedParish: onChangeSelectedParish,
      type: geojsonType,
    },
    heatmap: {
      coords:
        showHeatMap && filterMenuAllMarkers === "visits" ? coordinates : null,
      gradientColor: legendHeatmapColors,
    },
    /// drawOptions -->
    onDrawFilter: handleDrawFilter,
    drawOptions:
      filterMenuAllMarkers !== "visits"
        ? [DrawOption.rectangle, DrawOption.circle, DrawOption.polygon]
        : [],
    // show different map types
    viewMapType: selectedViewMapType,
    changeViewMapType: handleViewMapType,
    viewMapTypesList:
      nameClient === "CMBeja" && filterMenuAllMarkers === "visits"
        ? [MapType.choropleth, MapType.heat]
        : null,
    mapFitBounds: fitBounds,
    onChangeFitBounds: handleChangeFitBounds,
  };

  return (
    <>
      <MapListContainer
        items={markers}
        itemDetails={handlePointClick}
        onHover={handleOnHoverItemList}
        hideInfoBtn={Boolean(geojsonType.includes("upload")) || nameClient === "CMBeja"}
        typeUpload={geojsonType.includes("upload") ? geojsonType : ""}
        children={
          Boolean(geojsonType.includes("upload")) ? (
          <ListUploadFiles
            onClearFile={onClearUpload}
            onChangeVisibility={onChangeVisibilityUpload}
            getGeojsonUpdate={getGeojsonUpdate}
          />
          ) : (
            <AnalysisTab
              showSelectedAnalysis={showSelectedAnalysis}
              handleCleanFilters={handleCleanFilters}
              setGeojsonCounty={setGeojsonCounty}
              setLoadingAnalysis={setLoadingAnalysis}
              setLoadingAnalysisDetail={setLoadingAnalysisDetail}
            />
          )
        }
      />

      {openFilters && (
        <FilterTab open={filterTabOpen} onClose={onCloseFilterTab} />
      )}

      {mapCenter && args && <MainMap {...args} />}

      {openDetails && markerTypeDetails && (
        <MarkerInfoComponent
          type={markerTypeDetails}
          dialogProps={{
            open: openDetails,
            onClose: () => setOpenDetails(false),
          }}
          onSuccess={updateMarkers}
        />
      )}

      {canCreateEvent && openAddEvent && (
        <EventAdd
          open={openAddEvent}
          onClose={() => {
            setAddEvent(false);
            dispatch(setDrawingMap(false));
            dispatch(setDrawnCoords(null));
          }}
          onSuccess={updateMarkers}
        />
      )}

      {canCreateOccurrence && openAddOccurrence && (
        <OccurrenceAdd
          open={openAddOccurrence}
          onClose={() => {
            setAddOccurrence(false);
            dispatch(setDrawingMap(false));
            dispatch(setDrawnCoords(null));
          }}
          onSuccess={updateMarkers}
        />
      )}
    </>
  );
};

export default Map;
