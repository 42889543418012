import {
  DynamicForm,
  FilterSection,
  IDynamicInputTemplate,
  IFormDefinition,
  IRange,
  TypeDynamicInput,
} from "@alb/live-lib";
import { Button, Grid, Typography } from "@mui/material";
import { subDays } from "date-fns";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import i18n from "i18n/config";
import { stringIsEmpty } from "utils/utils";

interface IDefaultValues {
  name: string | null;
  startDateRange: IRange | undefined;
}
interface IColXTemplate extends IDynamicInputTemplate {
  xsCol: "auto" | number | boolean;
}
const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {
  return (
    <Grid item xs={xsCol}>
      {inputLabel && (
        <Typography gutterBottom variant="h6">
          {inputLabel}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

export const FiltersSection = (props: {
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
}) => {
  const { t } = useTranslation();
  const { setFilterName, setFilterDateRange } = props;

  const defaultValueFieldDateRange = { startDateRange: undefined };
  const sevenDays = {
    startDateRange: { startDate: subDays(new Date(), 6), endDate: new Date() },
  };

  const [name, setName] = useState<string>("");
  const [selectedRange, setSelectedRange] = useState<IRange | undefined>();

  const calculateFiltersCount = useCallback(() => {
    let count = 0;
    if (!stringIsEmpty(name)) count++;
    if (selectedRange) count++;

    return count;
  }, [name, selectedRange]);

  const nameOptionsList = [
    //TODO: change hardcoded data -  temporary
    {
      label: "Wallbox 1",
      value: "Wallbox 1",
    },
    {
      label: "Wallbox 2",
      value: "Wallbox 2",
    },
  ];

  const handleChangeName = (
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) => {
    // setPage(1);
    if (value && value.value) setName(value.value);
    else setName("");
  };

  const template = useCallback(
    ({ inputLabel, children }: IDynamicInputTemplate) => {
      return (
        <ColXTemplate xsCol={6} inputLabel={inputLabel} children={children} />
      );
    },
    []
  );

  const predefinedRanges = {
    labelLast30Days: t("calendar.last30Days"),
    labelLast7Days: t("calendar.last7Days"),
    position: "left",
  };

  const selectedRangeDates = (date: IRange | undefined) => {
    setSelectedRange(date);
  };

  const dynamicForm = {
    formId: "form-search",
    inputsDefinition: {
      name: {
        inputType: TypeDynamicInput.autocomplete,
        label: t("electricMobility.charger"),
        placeholder: t("electricMobility.chargerPlaceholder"),
        options: nameOptionsList,
        defaultValue: null,
        handleOnChange: handleChangeName,
        template: template,
      },
      startDateRange: {
        typefilter:true,
        inputType: TypeDynamicInput.daterange,
        label: t("occurrences.startDate"),
        placeholder: t("calendar.placeholderDate"),
        defaultValue: defaultValueFieldDateRange.startDateRange,
        defaultValueField: defaultValueFieldDateRange,
        onSelectedRange: selectedRangeDates,
        noOptionsText: t("common.noOptions"),
        template: template,
        locale: i18n.language,
        predefinedRanges: predefinedRanges,
        labelBtnCancel: t("cancel"),
        labelBtnConfirm: t("apply"),
        clearButton: true,
      },
    },
  };

  const defaultValues: IDefaultValues = {
    name: dynamicForm.inputsDefinition.name.defaultValue,
    startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
  };
  const methodsForm = useForm<IDefaultValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  useEffect(() => {
    setFilterName(name);
  }, [name, setFilterName]);

  //define o valor que é para preencher no input ao abrir a página
  const setInitialValue = () => {
    methodsForm.setValue("startDateRange", sevenDays.startDateRange, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    setInitialValue();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilterDateRange(selectedRange);
  }, [selectedRange, setFilterDateRange]);

  const cleanFilters = () => {
    methodsForm.reset();
    setName("");
    setSelectedRange(undefined);
  };

  const showClearFilters = () => {
    return calculateFiltersCount() > 1;
  };

  return (
    <>
      <FilterSection
        activeFiltersCount={calculateFiltersCount()}
        filterTitle={t("common.filter")}
        opened
      >
        <Grid container>
          <Grid item xs={showClearFilters() ? 9 : 12}>
            <DynamicForm formDefinition={formDefinition} />
          </Grid>
          {showClearFilters() && (
            <Grid
              mb={1}
              item
              xs={true}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button variant="outlined" onClick={cleanFilters}>
                {t("common.cleanFilters")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FilterSection>
    </>
  );
};
