import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText, RequiredField } from "@alb/live-lib";
import { ReactComponent as Logo } from "../../assets/images/live-campus/logo_horizontal_CAMPUS.svg";
import { getInviteUSer } from "./validations";
import { formDefaultValues } from "./formDefaultValues";
import {  RequiredFields } from "components/Utils/RequiredField";
interface IInviteForm {
  first_name: string;
  password: string;
  confirmPassword: string;
}
const InviteForm = (props: {
  formId: string;
  data?: any;
  onFormSubmit: (payLoad: IInviteForm) => void;
}) => {
  const { formId, data, onFormSubmit } = props;

  const { t } = useTranslation();
  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IInviteForm>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getInviteUSer(t("invite.passwordMustMatch"))),
  });

  const { control, handleSubmit } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IInviteForm> = (
    payLoad: IInviteForm
  ) => {
    onFormSubmit(payLoad);
  };
  return (
    <Box maxWidth="md">
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Typography variant="h4">{t("invite.joinTeam")}</Typography>

          <Typography mt={2} variant="subtitle1">
            {t("invite.messageWelcomeUser")}
            <Logo width={"6rem"} />
          </Typography>

          <Grid container mt={5} maxWidth={"sm"}>
            <Grid item xs={12}>
              <Typography gutterBottom color="text.black" variant="subtitle2">
                {t("invite.nameLabel")}
              </Typography>
              <InputText
                disabled
                control={control}
                name="first_name"
                placeholder={t("invite.namePlaceholder")}
              />
            </Grid>

            <Grid container columnSpacing={2} sm={6}>
              <Grid item pt={3} xs={12}>
                <RequiredField title={t("invite.passwordLabel")} />
                <InputText
                  type={"password"}
                  control={control}
                  name="password"
                  placeholder={t("invite.passwordPlaceholder")}
                />
              </Grid>
              <Grid item pt={3} xs={12}>
                <RequiredField title={t("invite.passwordConfirmationLabel")} />
                <InputText
                  type={"password"}
                  control={control}
                  name="confirmPassword"
                  placeholder={t("invite.passwordConfirmationLabelPlaceholder")}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </Box>
  );
};

export default InviteForm;
