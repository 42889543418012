/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar, InputSelect, Loading } from "@alb/live-lib";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ReactECharts as BarChart } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import useDidMountEffect from "hooks/useDidMountEffect";
import useErrorMessage from "hooks/useErrorMessage";
import i18n from "i18n/config";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils/conditions";
import { dateTimeFormat, formatDate } from "utils/date";
import { formatNumber } from "utils/number";

import "./ChartParishesAveiro.scss";

const ChartConsumptionBT = ({
  errorRequest,
  handleChangeSecondItem,
  valuesTotalConsumption,
  consumptionBT
}: any) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getCodeDescritionError } = useErrorMessage();

  const [chartBarOptions, setChartOptions] = useState<any>({});
  const [seriesArray, setSeriesArray] = useState<any[]>([]);
  const [dataAxis, setdataAxis] = useState<any[]>([]);

  const { data: valuesMunicipality, refetch, loading: loadingMunicipality, error } = useGet(
    ServiceApiUrl.parishConsumption,
    null,
    {
      manual: true,
    }
  );

  const { data: valuesParish, refetch: refetchParish, error: errorParish } = useGet(
    ServiceApiUrl.parishConsumption,
    null,
    {
      manual: true,
    }
  );

  const [parishes, setParishes] = useState<string[]>([]);
  const [nameDataSeries, setNameDataSeries] = useState<string[]>([]);
  const [selectedRange, setSelectedRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(subDays(new Date(), 7).setHours(0, 0, 0)),
    endDate: new Date()
  });


  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      chartConsumptionParishes: []
    },
  });

  const sendRequestStreamsChartParish = async (params: any) => {
    await refetchParish({
      params: { ...params },
    });
  };

  useEffect(() => {
    if (nameDataSeries && !arrayIsEmpty(valuesMunicipality)) {
      const dataSeries = getSumActiveEnergy(valuesMunicipality)
      const dataAxis = getAggregatedCollectDate(valuesMunicipality)
      let seriesArray123: any[] = [
        {
          name: t('energyPTD.county'),
          dates: dataAxis,
          data: dataSeries,
          type: "bar",
        }
      ]
      setSeriesArray(seriesArray123)
      setdataAxis(dataAxis)

      if( nameDataSeries.length > 0) {
       const params = {
        parishes: nameDataSeries.join(';'),
        from: dateTimeFormat(selectedRange.startDate),
        to: dateTimeFormat(selectedRange.endDate),
      };
       sendRequestStreamsChartParish(params);
      }
    }
    if (arrayIsEmpty(nameDataSeries) || arrayIsEmpty(valuesMunicipality))valuesTotalConsumption([])
  }, [nameDataSeries, valuesMunicipality]);

  useEffect(() => {
    if (valuesParish) {
      let seriesArray222: any = []

      valuesParish?.forEach((a: any) => {
        if(!arrayIsEmpty(a)) {
           seriesArray222.push({
            name: a[0]?.freguesia,
            dates: a?.map((e: any) =>
              formatDate(e?.aggregated_collect_date, t("calendar.dateFullFormatOf"))
            ),
            data: a?.map((e: any) => Number(e?.sum_active_energy / 1000).toFixed(2)),
            type: "bar",
          });
        }
      });
      const test = seriesArray.concat(seriesArray222);
      setSeriesArray(test)
      const maxValue = Math.max(...valuesParish.map((v: any) => Array.isArray(v) && v?.map((e: any) => Number(e?.sum_active_energy / 1000).toFixed(2))), 0)
      const valuesMapTotalConsumption: any = []
      valuesParish.forEach((it: any) => {
        if(!arrayIsEmpty(it)) {
          valuesMapTotalConsumption.push( {
            name: it[0]?.freguesia,
            value: it?.map((e: any) => Number(e?.sum_active_energy / 1000).toFixed(2)),
            maxValue
          })
        }
      })
     valuesTotalConsumption(valuesMapTotalConsumption)
    } else {
    }
  }, [valuesParish]);

  

  function getSumActiveEnergy(consumption: any) {
    return consumption.map((city: any) => {
			return typeof (city.sum_active_energy) !== 'undefined' ? Number(city.sum_active_energy / 1000 ).toFixed(2): null
    })
  }
  function getAggregatedCollectDate(consumption: any) {
    return consumption.map((city: any) => {
      return formatDate(city?.aggregated_collect_date, t("calendar.dateFullFormatOf"))
    })
  }

  useEffect(() => {
    if (dataAxis) {
      const option = {
        tooltip: {
					confine: true,
      		extraCssText: 'white-space:inherit;',
          trigger: "axis",
					valueFormatter: function (value: any) {
						return formatNumber(value);
					},
        },
        textStyle: {
          fontFamily: "Altice",
          fontSize: 13,
        },
        legend: {
          width: "80%",
          type: "scroll",
          bottom: 0,
          icon: "circle",
          itemGap: 70,
          textStyle: {
            color: theme.palette.text.primary,
            fontFamily: "Altice",
            fontSize: 13,
          },
        },
        color: [
          "#6c848c",
          "#2c446c",
          "#9cc4c4",
          "#716a73",
          "#a4b4bc",
          "#4c9cac",
          "#5c6474",
          "#54ac8c",
          "#94a4b4",
          "#a4c4cc",
          "#dcec7c",
        ],
        yAxis: {
          nameLocation: "center",
          nameTextStyle: {
            padding: [0, 0, 50, 0],
            fontSize: 12,
          },
          type: "value",
					axisLabel: {
						formatter: (val:any) => formatNumber(val)
					},
          axisLine: {
            lineStyle: {
              color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
            }
          }
        },
        xAxis: {
            type: "category",
            axisTick: { show: false },
            data: dataAxis,
            axisLine: {
              lineStyle: {
                color: theme.palette.mode === "light" ? "" :  theme.palette.common.white
              }
            }
          },
        series: seriesArray,
      };
      setChartOptions(option);

    }
  }, [dataAxis, seriesArray]);

  const sendRequestStreamsChart = async (params: any) => {
    await refetch({
      params: { ...params },
    });
  };
  useDidMountEffect(() => {
    if (selectedRange) {
      const params = {
        from: dateTimeFormat(selectedRange.startDate),
        to: dateTimeFormat(selectedRange.endDate),
      };
      sendRequestStreamsChart(params);
    }
  }, [selectedRange]);

  let options: any[] = [];
  if (consumptionBT) {
    Object.entries(consumptionBT).filter((e) => { return e[0] !== 'Aveiro' }).map((it: any) => {
      return options.push({
        label: it[0],
        value: it[0]
      });
    });
  }

  const { control } = methods;

  const handleOnSelectedRange = (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => {
    setSelectedRange(dateRange);
  };

  const handleOnChangeParishes = (e: any) => {
    setParishes(e.target.value);
  };

  const applyInformation = (e: any) => {
    setNameDataSeries(parishes)
    handleChangeSecondItem(parishes)
  };
  const isEqual = (a: any, b: any) => {
    // if length is not equal
    if (a.length !== b.length) {
      return false
    } else {
      // comparing each element of array
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false
        } else {
          return true
        }
      }
    }
  }

  /*TODO: quando existirem os pedidos:
  -Colocar download (?)
   */

  useEffect(() => {
    const codeError = getCodeDescritionError(error || errorParish);
    errorRequest(codeError?.code);
  }, [error, errorParish])

  return (
    <>
      {!error && !errorParish && (
        <Grid
          mt={2}
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid
            item
            xs={4}
            sx={{
              display: "inline-flex",
              alignItems: "baseline",
              gap: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ padding: "8px 0px" }}
            >
              {t("energyPTD.parishes")}:
            </Typography>

            {/* <FormControl
              className="live__multipleSelect" //se for para retirar a borda
            > */}
            {options && (
              <InputSelect
                multiple
                options={options}
                name={"chartConsumptionParishes"}
                control={control}
                handleOnChange={handleOnChangeParishes}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={applyInformation}
              disabled={(parishes.length === 0 && nameDataSeries.length ===0) || isEqual(parishes, nameDataSeries)}
            >
              {t('energyPTD.apply')}
            </Button>
            {/* </FormControl> */}
          </Grid>

          {/* <Download
            selectedDeviceInfo={data}
            startDate={new Date(selectedRange?.startDate)}
            endDate={new Date(selectedRange?.endDate)}
          /> */}
          <Grid item>
            <Calendar
              disabled={loadingMunicipality}
              startDate={new Date(selectedRange?.startDate)}
              endDate={new Date(selectedRange?.endDate)}
              labelBtnCancel={t("cancel")}
              labelBtnConfirm={t("apply")}
              locale={i18n.language}
              onSelectedRange={(e: { startDate: Date; endDate: Date }) => {
                handleOnSelectedRange(e);
              }}
              predefinedRanges={{
                labelLast30Days: t("calendar.last30Days"),
                labelLast7Days: t("calendar.last7Days"),
                position: "left",
              }}
            />
          </Grid>
        </Grid>
      )}
      <Box data-testid="consumptionBT">
      {!arrayIsEmpty(seriesArray) && !arrayIsEmpty(valuesMunicipality) && !loadingMunicipality && !error && (
        <BarChart  option={chartBarOptions} />
      )}
      <Loading show={loadingMunicipality} />
      {(error || errorParish || arrayIsEmpty(valuesMunicipality)) && !loadingMunicipality && <NoData error={error || errorParish}/>}
      </Box>
    </>
  );
};

export default ChartConsumptionBT;

